// this is the logger for the browser
import { getConfig } from "config";
import type { Level, LogEvent, LoggerOptions } from "pino";
import pino from "pino";
import { BROWSER_LOG_ROUTE } from "./constants";

const config = getConfig();
const logLevel = config.LOG_LEVEL;

export type ClientLog = {
	msg: string;
	level: Level;
};

const pinoConfig: LoggerOptions = {
	browser: {
		asObject: true,
		transmit: {
			level: logLevel,
			send: () => { return; },
		},
	},
	level: logLevel,
};

if (pinoConfig.browser) {
	pinoConfig.browser.transmit = {
		level: logLevel,
		send: (level: Level, logEvent: LogEvent) => {
			const msg = logEvent.messages[0];

			const headers = {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
				"type": "application/json",
			};

			const payload: ClientLog = {
				msg,
				level,
			};

			const blob = new Blob([JSON.stringify(payload)], headers);
			navigator.sendBeacon(BROWSER_LOG_ROUTE, blob);
		},
	};
}

const logger = pino(pinoConfig);

export const log = (msg: string) => logger.info(msg);
export default logger;
