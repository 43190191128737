import Button from "@components/Button";
import { Box } from "@components/core/Box";
import { TextInput } from "@components/core/Form";
import { useTranslation } from "next-i18next";

interface BpmInputsProps {
	bpmValues: {
		min: number;
		max: number;
	};
	onSetBpm: (bpmValues: { min: number; max: number }) => void;
}

interface BpmButtonsProps {
	bpmValues: {
		min: number;
		max: number;
	};
	onReset: (bpmValues: { min: number; max: number }) => void;
	onApply: (data: Record<string, string>) => void;
}

const BpmInputs = ({
	bpmValues = { min: 0, max: 0 },
	onSetBpm = () => {},
}: BpmInputsProps) => (
	<Box p="0" direction="row">
		<Box p="4" width="50">
			<TextInput
				id="bpm_min"
				name="bpm_min"
				type="number"
				placeholder="Lowest"
				value={isNaN(bpmValues.min) ? "" : bpmValues.min}
				onChange={(e: any) => {
					const value = parseInt(e.target.value, 10);
					onSetBpm({
						min: Math.max(value, 0),
						max: bpmValues.max,
					});
				}}
			/>
		</Box>
		<Box p="4" width="50">
			<TextInput
				id="bpm_max"
				name="bpm_max"
				type="number"
				placeholder="Highest"
				value={isNaN(bpmValues.max) ? "" : bpmValues.max}
				onChange={(e: any) => {
					const value = parseInt(e.target.value, 10);
					onSetBpm({
						max: Math.max(value, 0),
						min: bpmValues.min,
					});
				}}
			/>
		</Box>
	</Box>
);

const BpmButtons = ({
	bpmValues,
	onReset = () => {},
	onApply = () => {},
}: BpmButtonsProps) => {
	const { t } = useTranslation("translation");

	const applyBpm = () => {
		const data = {} as Record<string, string>;
		const bpmMin = (bpmValues.min < 1 || isNaN(bpmValues.min)) ? 1 : bpmValues.min;
		const bpmMax = (bpmValues.max < 1 || isNaN(bpmValues.max)) ? 999 : bpmValues.max;
		data["bpm"] = `${bpmMin}:${bpmMax}`;
		onApply(data);
	};

	return (
		<>
			<Button
				type="link"
				onClick={() => {
					onReset({ max: 0, min: 0 });
				}}
			>
				{t("Reset")}
			</Button>
			<Button
				type="primary"
				onClick={applyBpm}
				disabled={!bpmValues.min && !bpmValues.max}
			>
				{t("Apply")}
			</Button>
		</>
	);
};

const BPMFilter = {
	Inputs: BpmInputs,
	Buttons: BpmButtons,
};

export default BPMFilter;
