"use client";

// Array.at
if (!Array.prototype.at) {
	Array.prototype.at = function (pos) {
		return this.slice(pos, pos + 1)[0];
	};
}

export {};
