export function cls(...names: (string | undefined)[]): string {
	return names.filter((name) => !!name).join(" ");
}

export const SIDEBAR_DESKTOP_MIN_WIDTH = "171px";
export const SIDEBAR_DESKTOP_WIDTH_NUMBER = 208;
export const SIDEBAR_DESKTOP_WIDTH = `${SIDEBAR_DESKTOP_WIDTH_NUMBER}px`;
export const SIDEBAR_DESKTOP_MAX_WIDTH = "320px";
export const HEADER_HEIGHT = "60px";
export const MOBILE_HEADER_HEIGHT = "32px";
export const ELEMENT_SPACING = "1.75rem";
