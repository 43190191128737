/**
 * Error thrown when a download fails. Specifically to use with triggerSingleFileDownload
 * @class DownloadError
 * @extends {Error}
 * @public
 *
 */
export class DownloadError extends Error {
	/**
	 * Creates an instance of DownloadError.
	 * @param {string} [message] - The error message in case it needs to be customized
	 * @memberof DownloadError
	 */
	constructor(message?: string) {
		super(message);
		this.name = "DownloadError";
	}
}
