const NO_INDEX_PAGE_URLS = [
	"/track/i-love-skrillex/3515199",
	"/track/sexx/3078721",
	"/track/sexx-druxx/613651",
	"/track/sexx/10302593",
	"/track/sexx/10302593",
	"/track/tao/5166353",
	"/artist/adolescent-sexx/212890",
	"/track/another-day/14784148",
	"/release/another-day/3255525",
	"/release/another-day/1999329",
	"/artist/patrick-sexx/390133",
	"/track/sexx-druxx/613651",
	"/artist/adolescent-sexx/212890/charts",
	"/track/sexx-druxx/613651",
	"/track/another-day/14784148",
	"/release/sexxx-feat-sr-de-la-noche/2215726",
	"/track/not-gonna-give-my-love-daniele-sexxx-remix/16861893",
	"/release/essential-guide-house-vol-15/1636318",
	"/track/not-gonna-give-my-love-daniele-sexxx-remix/16861893",
	"/artist/sexxx-funk/206792",
	"/track/sexxx/3791620",
	"/track/rockstar-4-the-night/6999129",
	"/track/rockstar-4-the-night/6999129",
	"/release/sexxx/1762718",
	"/track/lift-me-up-feat-paula-pcay/6722138",
	"/track/lift-me-up-feat-paula-pcay/7027633",
	"/track/lift-me-up-feat-paula-pcay/5838031",
	"/track/lift-me-up-feat-paula-pcay/7027633",
	"/track/sexxx/5498324",
	"/track/lift-me-up-feat-paula-pcay/7027633",
	"/track/rockstar-4-the-night/4587562",
	"/track/sexxx/7342365",
	"/track/not-gonna-give-my-love-daniele-sexxx-remix/16861893",
	"/release/xgxx-xhxx-ep/1551495",
	"/release/xgxx-xhxx-ep/1551495",
	"/release/xgxx-xhxx-ep/1551495",
	"/release/xgxx-xhxx-ep/1551495",
	"/release/xgxx-xhxx-ep/1551495",
	"/release/xgxx-xhxx-ep/1551495",
	"/chart/dradji-july-chart-2015/357688",
	"/release/xgxx-xhxx-ep/1551495",
	"/track/p0rn0/14398219",
	"/track/p0rn0/14398219",
	"/track/p0rn0/14398219",
	"/track/p0rn0/14398219",
	"/track/p0rn0/14398219",
	"/track/p0rn0/14967675",
	"/track/p0rn0/14967675",
	"/track/p0rn0/14967675",
	"/track/p0rn0/14398219",
	"/track/p0rn0/14967675",
	"/track/p0rn0/14398219",
	"/track/p0rn0/14967675",
	"/track/p0rn0/14967675",
	"/track/xxx23/3923447",
	"/track/xxx23/3923447",
	"/track/xxx23/3923447",
	"/track/xxx23/3923447",
	"/track/xxx23/3923447",
	"/track/xxx23/3923447",
	"/track/xxx23/3923447",
	"/track/spankbank/7565209",
	"/track/spankbank/7565209",
	"/release/spank-bank/150716",
	"/track/spankbank/7565209",
	"/track/spankbank/7565209",
	"/track/spankbank/7565209",
	"/track/spankbank/7565209",
	"/track/spankbank/7565209",
	"/track/saxxx-killa/12303773",
	"/track/saxxx-killa/12303773",
	"/release/saxxx/1296451",
	"/release/saxxx-killa/2655362",
	"/track/saxxx-killa/12303773",
	"/release/saxxx-killa/2655362",
	"/chart/-absolut-groovers-saxxx-killa-chart-/580097",
	"/track/saxxx-killa/12303773",
	"/track/triple-saxxx/16132438",
	"/release/saxxx/1296451",
	"/track/saxxx-killa/12303773",
	"/release/saxxx/1296451",
	"/release/saxxx/1296451",
	"/track/saxxx/6493266",
	"/track/xzxzxz/13618977",
	"/track/xzxzxz/13618977",
	"/track/xzxzxz/13618977",
	"/track/xzxzxz/13618977",
	"/track/xzxzxz/13618977",
	"/track/xzxzxz/13618977",
	"/track/xxxa/13241862",
	"/track/xxxa/13241862",
	"/track/xxxa/13241862",
	"/track/xxxa/13241862",
	"/track/xxxa/13241862",
	"/track/xxxa/13241862",
	"/track/xxxa/13241862",
	"/track/x-voice/16150099",
	"/chart/xxxxi/704806",
	"/chart/xxxxi/704806",
	"/chart/xxxxi/704806",
	"/chart/xxxxi/704806",
	"/chart/xxxxi/704806",
	"/chart/xxxxi/704806",
];

export default NO_INDEX_PAGE_URLS;
