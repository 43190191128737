import debounce from "lodash/debounce";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";

const windowContextInitialState = {
	width: 0,
};

const WindowContext = createContext(windowContextInitialState);

interface WindowProviderProps {
	children: ReactNode;
}

const WindowProvider: React.FC<WindowProviderProps> = ({ children }) => {
	const isClient = typeof window === "object";
	const [width, setWidth] = useState(isClient ? window.innerWidth : 0);

	useEffect(() => {
		const handleResize = debounce(() => setWidth(window.innerWidth), 150);

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<WindowContext.Provider value={{ width }}>
			{children}
		</WindowContext.Provider>
	);
};

const useWindowContext = () => {
	const context = useContext(WindowContext);

	if (!context) {
		throw new Error("useWindowContext must be wrapped in WindowProvider");
	}

	return context;
};

export { WindowProvider, useWindowContext };
