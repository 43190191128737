import styled from "styled-components";

export const SimplePageSelectWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const PageSelectButtonWrapper = styled.button<{ disabled?: boolean }>`
  background-color: ${({ theme }) =>
		theme.colors.neutrals.secondary.gray.divider2};
  opacity: ${({ disabled }) => (disabled ? "50%" : "100%")};
  border-radius: 0.125rem;
  padding: 0.469rem;
`;
