import React, { createContext, useReducer } from "react";
import { TwitchLiveStreamActionsContext, setAllStages, setCurrentStage, setCurrentStream, setIsExpanded } from "./actions";
import { twitchLiveStreamReducer } from "./reducer";
import { LiveStream, Stage, TwitchLiveStreamState } from "./types";

const twitchLiveStreamInitialState: TwitchLiveStreamState = {
	currentStage: {},
	allStages: [],
	isExpanded: true,
	currentStream: {},
};

const TwitchLiveStreamStateContext = createContext<{ state: TwitchLiveStreamState }>({
	state: twitchLiveStreamInitialState,
});

// Define the provider
const TwitchLiveStreamProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [state, dispatch] = useReducer(
		twitchLiveStreamReducer,
		twitchLiveStreamInitialState,
	);

	const actions = {
		setCurrentStage: (stage: Stage) => {
			dispatch(setCurrentStage(stage));
		},
		setAllStages: (stages: Stage[]) => {
			dispatch(setAllStages(stages));
		},
		setIsExpanded: (isExpanded: boolean) => {
			dispatch(setIsExpanded(isExpanded));
		},
		setCurrentStream: (currentStream: LiveStream) => {
			dispatch(setCurrentStream(currentStream));
		},
	};

	return (
		<TwitchLiveStreamStateContext.Provider value={{ state }}>
			<TwitchLiveStreamActionsContext.Provider value={{ actions }}>
				{children}
			</TwitchLiveStreamActionsContext.Provider>
		</TwitchLiveStreamStateContext.Provider>
	);
};

export {
	TwitchLiveStreamActionsContext,
	TwitchLiveStreamProvider,
	TwitchLiveStreamStateContext,
	twitchLiveStreamInitialState,
};
