import { useSessionContext } from "@lib/context/session";
import {
	deleteMyChartTracksRequest,
	getMyChartTracksQuery,
	postMyChartTracksRequest,
} from "@lib/network/charts";
import { ChartTracksResponse } from "@models/track";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import toast from "react-hot-toast/headless";

export const useChartTracks = (chartId: number) => {
	const { getAccessToken } = useSessionContext();
	const accessToken = getAccessToken();
	const { t } = useTranslation("translation");
	const [addTrackLoading, setAddTrackLoading] = useState<number[]>([]);
	const [successfullyAddedTracks, setSuccessfullyAddedTracks] = useState<
		number[]
	>([]);

	const queryClient = useQueryClient();
	const { data: chartTracksResponse } = useQuery<ChartTracksResponse>(
		getMyChartTracksQuery({ id: chartId, accessToken }),
	);

	const postMyChartTracks = async (trackId: any) => {
		const postResponse = await postMyChartTracksRequest(
			{ chartId, trackId, accessToken },
		);
		return postResponse;
	};
	const deleteMyChartTracks = async (trackId: any) => {
		const deleteResponse = await deleteMyChartTracksRequest(
			{ chartId, trackId, accessToken },
		);
		return deleteResponse;
	};

	const { mutate } = useMutation(postMyChartTracks, {
		onMutate: function () {
			const chartTracksResult = chartTracksResponse?.results || [];
			if (chartTracksResult.length >= 100) {
				throw {
					data: new Error(t("Charts.MyDjCharts.Toast.MaxLength")),
				};
			}
		},
		onSuccess: function (response) {
			if (response.status === 400) {
				throw {
					data: new Error(response.data.non_field_errors),
				};
			}
			setSuccessfullyAddedTracks([
				...successfullyAddedTracks,
				response.data.track.id,
			]);
			toast.success(t("Charts.MyDjCharts.Toast.Success"), {
				duration: 2000,
			});
			queryClient.invalidateQueries([`my-chart-${chartId}-tracks`]);
			queryClient.invalidateQueries([`my-chart-${chartId}`]);
		},
		onError: function (error: ApiResponse) {
			toast.error(error.data.message || t("Charts.MyDjCharts.Toast.Error"), {
				duration: 2000,
			});
		},
	});

	const { mutate: deleteMutation } = useMutation(deleteMyChartTracks, {
		onSuccess: function () {
			queryClient.invalidateQueries([`my-chart-${chartId}-tracks`]);
		},
		onError: function (error: ApiResponse) {
			toast.error(error.data.message || t("Charts.MyDjCharts.Toast.Error"), {
				duration: 2000,
			});
		},
	});

	const addTrack = async (trackId: number) => {
		setAddTrackLoading([...addTrackLoading, trackId]);
		mutate(trackId);
		setAddTrackLoading(addTrackLoading.filter((item) => item === trackId));
	};

	const deleteTrack = async (trackId: number) => {
		deleteMutation(trackId);
	};

	return {
		addTrack,
		deleteTrack,
		addTrackLoading,
		successfullyAddedTracks,
	};
};
