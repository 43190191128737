import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { useCart } from "@lib/context/cart";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { Cart } from "@models/Cart";
import { device } from "@styles/theme";
import React, { useEffect, useState } from "react";
import {
	CartItem,
	Content,
	Header,
	Overlay,
	Selector,
	Wrapper,
} from "./CartSelector.style";

interface Props {
	x: number;
	y: number;
	carts: Cart[];
	selectedCarts: number[];
	onLeave: () => void;
	onCartSelected: (cartId: number, isDelete: boolean) => void;
	alignBottom?: boolean;
}
const CartSelector: React.FC<Props> = ({
	x,
	y,
	onLeave,
	carts,
	selectedCarts,
	onCartSelected,
	alignBottom = false,
}) => {
	const [selections, setSelections] = useState(selectedCarts);
	const { isCartSelectorDisabled } = useCart();
	const isMd = useMediaQuery({ query: device.md });
	const selectorWrapperRef = React.useRef<HTMLDivElement>(null);

	const updateSelections = (cartId: number) => {
		const index = selections.indexOf(cartId);
		if (index > -1) {
			selections.splice(index, 1);
		} else {
			selections.push(cartId);
		}
		setSelections([...selections]);
	};

	const sortedCarts = carts.sort((a, b) => {
		if (a.name === "cart") {
			return -1;
		} else if (b.name === "cart") {
			return 1;
		} else if (a.name === "hold-bin") {
			return -1;
		} else if (b.name === "hold-bin") {
			return 1;
		} else {
			if (a.name > b.name) return 1;
			if (a.name < b.name) return -1;
		}
		return 0;
	});

	const renderCartName = (cart: Cart) => {
		switch (cart.name) {
			case "hold-bin":
				return (
					<>
						<span className="name">
							<SpriteIcon id="hold-bin" />
							{" "}
							<span className="title">Hold Bin</span>
						</span>
						<span className="action">
							{selections.includes(cart.id) ?
									(
										<SpriteIcon id="checkbox-selected" width="20" height="20" />
									) :
									(
										<SpriteIcon id="checkbox" width="20" height="20" />
									)}
						</span>
					</>
				);
			case "cart":
				return (
					<>
						<span className="name">
							{cart.default ?
									(
										<SpriteIcon id="cart-main" />
									) :
									(
										<SpriteIcon id="cart" />
									)}
							<span className="title"> Main Cart</span>
						</span>
						<span className="action">
							{selections.includes(cart.id) ?
									(
										<SpriteIcon id="checkbox-selected" width="20" height="20" />
									) :
									(
										<SpriteIcon id="checkbox" width="20" height="20" />
									)}
						</span>
					</>
				);
			default:
				return (
					<>
						<span className="name">
							{cart.default ?
									(
										<SpriteIcon id="cart-main" />
									) :
									(
										<SpriteIcon id="cart" />
									)}
							<span className="title"> {cart.name}</span>
						</span>
						<span className="action">
							{selections.includes(cart.id) ?
									(
										<SpriteIcon id="checkbox-selected" width="20" height="20" />
									) :
									(
										<SpriteIcon id="checkbox" width="20" height="20" />
									)}
						</span>
					</>
				);
		}
	};

	const [top, setTop] = useState(y);

	useEffect(() => {
		if (alignBottom && selectorWrapperRef.current) {
			setTop(y - selectorWrapperRef.current?.clientHeight);
		}
	}, [alignBottom, y]);

	return (
		<Wrapper>
			<Overlay
				onClick={() => {
					onLeave();
				}}
			>
				<Selector
					ref={selectorWrapperRef}
					onMouseLeave={() => {
						onLeave();
					}}
					top={top}
					left={x}
				>
					{!isMd && (
						<Header>
							<span className="title">Select Cart </span>
							<span className="action" onClick={() => onLeave()}>
								<SpriteIcon id="close-x" width="18" height="19" />
							</span>
						</Header>
					)}
					<Content>
						{sortedCarts.map((cart) => (
							<CartItem key={`cart-${cart.id}`}>
								<button
									title={`Add to ${cart.name}`}
									disabled={isCartSelectorDisabled}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										onCartSelected(cart.id, selections.includes(cart.id));
										updateSelections(cart.id);
									}}
								>
									{renderCartName(cart)}
								</button>
							</CartItem>
						))}
					</Content>
				</Selector>
			</Overlay>
		</Wrapper>
	);
};

export default CartSelector;
