import Marquee from "@components/Marquee";
import { MyBeatportLabel } from "@models/my-beatport";
import { Label } from "@models/release";
import { pushProductLabelEvent } from "@utils/dataLayer";
import Link from "next/link";
import { Fragment } from "react";

const logLabelClickEvent = (label: Label | MyBeatportLabel, location: string | null = null) => {
	pushProductLabelEvent({
		id: label?.id.toString(),
		name: label?.name,
		list: location,
		imageUrl: label?.image?.uri,
	});
};

interface Props {
	label: Label | MyBeatportLabel;
	children?: React.ReactNode | undefined;
	onClick?: (e?: any) => void;
	prefetch?: boolean;
	location?: string;
	withMarquee?: boolean;
	shouldMarqueeWrap?: boolean;
	[extra: string]: any;
}

const LabelLink: React.FC<Props> = ({
	label,
	children,
	onClick = () => {},
	prefetch = false,
	location = "",
	withMarquee = false,
	shouldMarqueeWrap = true,
	...extra
}) => {
	const MarqueeWrapper = withMarquee ? Marquee : Fragment;
	const marqueeWrapperProps = withMarquee ? { shouldWrap: shouldMarqueeWrap } : {};

	return (
		<Link
			href={`/label/${label?.slug}/${label?.id}`}
			title={label?.name}
			prefetch={prefetch}
			onClick={() => {
				logLabelClickEvent(label, location);
				onClick();
			}}
			{...extra}
		>
			<MarqueeWrapper {...marqueeWrapperProps}>
				{children ? children : label?.name}
			</MarqueeWrapper>
		</Link>
	);
};

export default LabelLink;
