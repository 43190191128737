import Button from "@components/Button";
import { Modal } from "@components/core/Modal";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { ItemLoader } from "@components/loaders";
import { PurchaseItem } from "@models/Cart";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import {
	ButtonWrapper,
	LoaderWrapper,
	Message,
	Wrapper,
} from "./RePurchaseTracksModal.style";

interface Props {
	loading: boolean;
	show?: boolean;
	items: PurchaseItem[];
	onClose: () => void;
	onRePurchaseItems: (tracks: PurchaseItem[]) => void;
}

const RePurchaseTracksModal: React.FC<Props> = ({
	loading,
	show = false,
	items,
	onClose,
	onRePurchaseItems,
}) => {
	const [selectedItems, setSelectedItems] = useState<string[]>([]);
	const { t } = useTranslation("translation");

	// filter unique items
	const uniqueItems = items.filter((el, index) => {
		return items.findIndex((item) => item.id === el.id) === index;
	});

	const closeModal = () => {
		onClose();
	};

	const isSelected = (id: string) => {
		return selectedItems.indexOf(id) > -1;
	};

	const selectItem = (id: string) => {
		if (isSelected(id)) {
			const items = selectedItems.filter((item) => item !== id);
			setSelectedItems(items);
		} else {
			const items = [...selectedItems, id];
			setSelectedItems(items);
		}
	};

	const selectAll = () => {
		if (selectedItems.length === uniqueItems.length) {
			setSelectedItems([]);
		} else {
			setSelectedItems(uniqueItems.map((i) => i.id));
		}
	};

	const addToCart = () => {
		let selected = uniqueItems.filter((item) => isSelected(item.id));
		if (items.length === 1) {
			selected = items;
		}

		onRePurchaseItems(selected);
	};

	const getMessage = () => {
		if (uniqueItems.length === 1) {
			return `${t("Purchase.PreviouslyPurchased")} ${
				items[0].type === "2" ? t("Release") : t("Track")
			} "${items[0].name}". ${t("Purchase.ConfirmRepurchase")}`;
		}

		return t("Purchase.Repurchase");
	};

	return (
		<Modal
			show={show}
			onClose={closeModal}
			header={t("Purchase.PreviouslyPurchasedItems")}
			footer={(
				<ButtonWrapper>
					<Button type="outline-secondary" onClick={closeModal}>
						{t("Actions.Cancel")}
					</Button>
					<Button type="primary" onClick={addToCart} disabled={loading}>
						<>
							{t("Actions.AddToCart")}
							<>
								{loading && (
									<LoaderWrapper>
										<ItemLoader />
									</LoaderWrapper>
								)}
							</>
						</>
					</Button>
				</ButtonWrapper>
			)}
			showClose
		>
			<Wrapper>
				<Message>{getMessage()}</Message>
				<ul>
					{uniqueItems.length > 1 && (
						<li>
							<Button onClick={() => selectAll()}>
								<span>
									{selectedItems.length === uniqueItems.length ?
											(
												<SpriteIcon id="checkbox-selected" width="20" height="20" />
											) :
											(
												<SpriteIcon id="checkbox" width="20" height="20" />
											)}
									{t("Actions.SelectAll")}
								</span>
							</Button>
						</li>
					)}
					{uniqueItems.length > 1 &&
						uniqueItems.map((item) => (
							<li key={`repurchase-item-${item.id}`}>
								<Button onClick={() => selectItem(item.id)}>
									<span>
										{isSelected(item.id) ?
												(
													<SpriteIcon
														id="checkbox-selected"
														width="20"
														height="20"
													/>
												) :
												(
													<SpriteIcon id="checkbox" width="20" height="20" />
												)}
										{item.name}
										-
										{item.mix_name}
									</span>
								</Button>
							</li>
						))}
				</ul>
			</Wrapper>
		</Modal>
	);
};

export default RePurchaseTracksModal;
