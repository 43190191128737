import { API_BASES } from "@lib/constants";
import { getRequest, postRequest } from "@lib/network/request";
import { LocationParams } from "@models/facets";

interface LibraryQueryParams {
	params: LibraryParams;
	accessToken: string;
	location?: LocationParams;
}

const urlPayload = (params: any) => new URLSearchParams(params);

const filterQueryParams = (
	searchParams: LibraryQueryParams,
): LibraryQueryParams => {
	const params = searchParams.params;
	return {
		params: Object.fromEntries(
			Object.entries(params).filter(([, v]) => v !== undefined),
		),
		accessToken: searchParams.accessToken,
		location: searchParams.location || {},
	} as LibraryQueryParams;
};

export const getLibraryCollectionsRequest = async (
	{ params, accessToken, location }: LibraryQueryParams,
	withErrorHandling?: boolean,
) => {
	const url = `/my/downloads/?${urlPayload(params)}`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getLibraryCollectionsQuery = (
	searchParams: LibraryQueryParams,
) => {
	const filteredParams = filterQueryParams(searchParams);
	return {
		queryKey: ["library-collection", searchParams.accessToken],
		queryFn: async () => {
			const { data } = await getLibraryCollectionsRequest(
				filteredParams,
				false,
			);
			return data;
		},
	};
};

export const getLastOrderTracksRequest = async (
	accessToken: string,
	withErrorHandling?: boolean,
) => {
	const url = "/my/orders/last-order/";
	return getRequest(
		{
			url,
			accessToken,
			apiBase: API_BASES.api4,
		},
		withErrorHandling,
	);
};

export const getLastOrderQuery = (accessToken: string) => {
	return {
		queryKey: ["last-order"],
		queryFn: async () => {
			const { data } = await getLastOrderTracksRequest(
				accessToken || "",
				false,
			);
			return data;
		},
	};
};

interface EncodeStatusParams {
	per_page: number;
	track_ids: string; // comma delimited
	audio_format: string;
}

interface ResetDonwloadParams {
	order_item_download_id?: number | any;
	accessToken: string;
}

interface EncodeStatusQueryParams {
	params: EncodeStatusParams;
	accessToken: string;
}

interface OnDemandQueryParams {
	data: any;
	accessToken: string;
}

export const getEncodeStatusRequest = async (
	{ params, accessToken }: EncodeStatusQueryParams,
	withErrorHandling?: boolean,
) => {
	const url = `/my/downloads/encode-status/?${urlPayload(params)}`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken },
		withErrorHandling,
	);
};

export const getEncodeStatusQueryKey = (
	searchParams: EncodeStatusQueryParams,
) => {
	return ["encode-status", searchParams.params.audio_format];
};

export const getEncodeStatusQuery = (searchParams: EncodeStatusQueryParams) => {
	return {
		queryKey: getEncodeStatusQueryKey(searchParams),
		queryFn: async () => {
			const { data } = await getEncodeStatusRequest(searchParams, false);
			return data;
		},
	};
};

export const postOnDemandEncodeRequest = async ({
	data,
	accessToken,
}: OnDemandQueryParams) => {
	const url = "/on-demand/encode/";
	return postRequest({ url, apiBase: API_BASES.api4, data, accessToken });
};

export const getResetDownload = async ({
	order_item_download_id,
	accessToken,
}: ResetDonwloadParams) => {
	const url = `/catalog/tracks/reset-download/?order_item_download_id=${order_item_download_id}`;
	return getRequest({ url, apiBase: API_BASES.api4, accessToken });
};
