import { SidebarIconKey, SidebarLinkProps } from "@bptypes/sidebar";
import { signOut } from "next-auth/react";
import { FooterLinkCategory } from "../models/constants";
import { ImageProps } from "next/image";
import { LinkProps } from "next/link";

const CURRENT_YEAR = new Date().getFullYear();

export const DEFAULT_LOCALE = "en";

export const AFFILIATE_TRACKING = {
	src: "https://loopmasters.postaffiliatepro.com/",
	accountId: "default1",
};

export const TOC_LINK =
	"https://support.beatport.com/hc/en-us/articles/4414997837716";
export const PRIVACY_LINK =
	"https://support.beatport.com/hc/en-us/articles/4412316093588";

export const ACCOUNT_MENU_ITEMS = [
	{
		order: 1,
		label: "Account.Settings",
		href: "/account/profile",
		cypressTag: "header-subnav-link-accountsettings",
	},
	{
		order: 2,
		label: "MyCharts",
		href: "/dj/charts",
		cypressTag: "header-subnav-link-mycharts",
	},
	{
		order: 3,
		label: "Subscriptions.MySubscriptions",
		href: "/my-subscriptions",
		cypressTag: "header-subnav-link-mysubscriptions",
	},
	{
		order: 4,
		label: "Actions.Logout",
		route: "/",
		isSignOut: true,
		action: signOut,
		cypressTag: "header-subnav-link-logout",
	},
];

export const AFEM_LOGO = {
	title: "AFEM",
	href: "https://www.associationforelectronicmusic.org/afem-approved-dj-download-sites/",
	image: {
		path: "/images/afem.png",
		width: 128,
		height: 84,
	},
	cypressTag: "footer-link-afemlogo",
};

export const API_BASES = {
	api4: "api4",
	search: "search",
};

export const ARTIST_PLACEHOLDER = {
	title: "Artist",
	href: "https://geo-media.beatport.com/image_size/590x404/0dc61986-bccf-49d4-8fad-6b147ea8f327.jpg",
	image: {
		path: "/artist-placeholder.jpg",
		width: 219,
		height: 152,
	},
	cypressTag: "search-artistplaceholder",
};

export const AUDIO_FORMATS = {
	aiff: "AIFF",
	mp3: "MP3",
	wav: "WAV",
};

export const AUDIO_FORMAT_IDS = [
	{ id: 1, name: AUDIO_FORMATS.mp3 },
	{ id: 3, name: AUDIO_FORMATS.wav },
	{ id: 14, name: AUDIO_FORMATS.aiff },
];

export const BEATPORT_LOGO = {
	title: "Beatport",
	href: "",
	image: {
		path: "/images/beatport-logo.svg",
		width: 166,
		height: 49,
	},
	copyright: `© 2004-${CURRENT_YEAR} Beatport, LLC`,
	cypressTag: "footer-link-bplogo",
};

export const BROWSER_LOG_ROUTE = "/api/logs";

export const ONE_DAY_SECONDS = 24 * 3600 * 1000;

export const CHART_PLACEHOLDER = {
	title: "Chart",
	href: "https://geo-media.beatport.com/image_size/300x300/ab2d1d04-233d-4b08-8234-9782b34dcab8.jpg",
	image: {
		path: "/chart-placeholder.jpg",
		width: 262,
		height: 262,
	},
	cypressTag: "search-chartplaceholder",
};

export const convertToSlug = (string = "") =>
	string
		.replace(/[^\w ]+/g, "")
		.replace(/ +/g, "-")
		.toLowerCase();

export const DATE_FILTER_FORMAT = "yyyy-MM-dd";

export const DEFAULT_AUDIO_FORMAT = { id: 1, name: "MP3" };

export const DOWNLOAD_SUPPORT_URL =
	"https://support.beatport.com/hc/en-us/articles/4412980413716";

export const ENCODING_FORMATS = {
	mp3: "mp3",
	aiff: "aiff",
	wav: "wav",
};

export const ENCODE_STATUS_POLL_RATE = 2000; // ms

export const FOOTER_LINKS: FooterLinkCategory[] = [
	{
		label: "Company",
		links: [
			{
				label: "Footer.AboutBeatport",
				href: "https://about.beatport.com/",
				newWindow: true,
				cypressTag: "footer-link-aboutbeatport",
			},
			{
				label: "Footer.CustomerSupport",
				href: "https://support.beatport.com/hc/en-us",
				newWindow: true,
				cypressTag: "footer-link-customersupport",
			},
			{
				label: "Footer.ContactUs",
				href: "https://support.beatport.com/hc/en-us/articles/4412288252308",
				newWindow: true,
				cypressTag: "footer-link-contactus",
			},
			{
				label: "Footer.Careers",
				href: "https://about.beatport.com/careers/",
				newWindow: true,
				cypressTag: "footer-link-careers",
			},
			{
				label: "Footer.LogosAndImages",
				href: "https://support.beatport.com/hc/en-us/articles/4412316336404-Beatport-Logos-and-Images",
				newWindow: true,
				cypressTag: "footer-link-logosandimages",
			},
			{
				label: "Footer.TermsAndConditions",
				href: TOC_LINK,
				newWindow: true,
				cypressTag: "footer-link-termsandconditions",
			},
			{
				label: "Footer.PrivacyPolicy",
				href: PRIVACY_LINK,
				newWindow: true,
				cypressTag: "footer-link-privacyandcookie",
			},
			{
				label: "Footer.CopyrightInfringement",
				href: "https://research.beatport.com/jfe/form/SV_9FYk8tRdkWAmAJg",
				newWindow: true,
				cypressTag: "footer-link-reportcopyright",
			},
		],
	},
	{
		label: "Network",
		links: [
			{
				label: "ampsuite Distribution",
				href: "https://www.ampsuite.com/",
				newWindow: true,
				cypressTag: "footer-link-ampsuite",
			},
			{
				label: "Beatportal",
				href: "https://www.beatportal.com/",
				newWindow: true,
				cypressTag: "footer-link-beatportal",
			},

			{
				label: "Beatport DJ",
				href: "https://dj.beatport.com/home",
				newWindow: true,
				cypressTag: "footer-link-beatportdj",
			},
			{
				label: "Beatport Hype",
				href: "https://labels.beatport.com/hype",
				newWindow: true,
				cypressTag: "footer-link-beatporthype",
			},
			{
				label: "Beatport for Labels",
				href: "https://labels.beatport.com/",
				newWindow: true,
				cypressTag: "footer-link-beatportforlabels",
			},
			{
				label: "Beatport Next",
				href: "https://www.beatportal.com/next/",
				newWindow: true,
				cypressTag: "footer-link-beatportnext",
			},
			{
				label: "Beatport Streaming",
				href: "https://stream.beatport.com/",
				newWindow: true,
				cypressTag: "footer-link-beatportstreaming",
			},
			{
				label: "LabelRadar",
				href: "https://www.labelradar.com/?utm_medium=referral&utm_source=beatport&utm_campaign=evergreen&utm_content=footer",
				newWindow: true,
				cypressTag: "footer-link-labelradar",
			},
			{
				label: "Loopcloud",
				href: "https://www.loopcloud.com",
				newWindow: true,
				cypressTag: "footer-link-loopcloud",
			},
			{
				label: "Plugin Boutique",
				href: "https://www.pluginboutique.com/",
				newWindow: true,
				cypressTag: "footer-link-pluginboutique",
			},
		],
	},
	{
		label: "Languages",
		links: [
			{ label: "English", code: "en", cypressTag: "footer-link-english" },
			{ label: "Español", code: "es", cypressTag: "footer-link-espanol" },
			{ label: "Français", code: "fr", cypressTag: "footer-link-francais" },
			{ label: "German", code: "de", cypressTag: "footer-link-german" },
			{ label: "Italiana", code: "it", cypressTag: "footer-link-italiana" },
			{ label: "日本語", code: "ja", cypressTag: "footer-link-japanese" },
			{ label: "Nederlands", code: "nl", cypressTag: "footer-link-nederlands" },
			{ label: "Português", code: "pt", cypressTag: "footer-link-portuguese" },
		],
	},
];

export const GENRE_MENU_ITEMS = [
	{
		order: 0,
		links: [
			{
				label: "140 / Deep Dubstep / Grime",
				href: "/genre/140-deep-dubstep-grime/95",
				cypressTag: "header-subnav-link-genre95",
			},

			{
				label: "Afro House",
				href: "/genre/afro-house/89",
				cypressTag: "header-subnav-link-genre89",
			},
			{
				label: "Amapiano",
				href: "/genre/amapiano/98",
				cypressTag: "header-subnav-link-genre98",
			},
			{
				label: "Bass / Club",
				href: "/genre/bass-club/85",
				cypressTag: "header-subnav-link-genre85",
			},
			{
				label: "Bass House",
				href: "/genre/bass-house/91",
				cypressTag: "header-subnav-link-genre91",
			},
			{
				label: "Breaks / Breakbeat / UK Bass",
				href: "/genre/breaks-breakbeat-uk-bass/9",
				cypressTag: "header-subnav-link-genre9",
			},
			{
				label: "Dance / Electro Pop",
				href: "/genre/dance-electro-pop/39",
				cypressTag: "header-subnav-link-genre39",
			},
			{
				label: "Deep House",
				href: "/genre/deep-house/12",
				cypressTag: "header-subnav-link-genre12",
			},
		],
	},

	{
		order: 1,
		links: [
			{
				label: "DJ Tools",
				href: "/genre/dj-tools/16",
				cypressTag: "header-subnav-link-genre16",
			},
			{
				label: "Drum & Bass",
				href: "/genre/drum-bass/1",
				cypressTag: "header-subnav-link-genre1",
			},
			{
				label: "Dubstep",
				href: "/genre/dubstep/18",
				cypressTag: "header-subnav-link-genre18",
			},
			{
				label: "Electro (Classic / Detroit / Modern)",
				href: "/genre/electro-classic-detroit-modern/94",
				cypressTag: "header-subnav-link-genre94",
			},
			{
				label: "Electronica",
				href: "/genre/electronica/3",
				cypressTag: "header-subnav-link-genre3",
			},
			{
				label: "Funky House",
				href: "/genre/funky-house/81",
				cypressTag: "header-subnav-link-genre81",
			},
			{
				label: "Hard Dance / Hardcore",
				href: "/genre/hard-dance-hardcore/8",
				cypressTag: "header-subnav-link-genre8",
			},
			{
				label: "Hard Techno",
				href: "/genre/hard-techno/2",
				cypressTag: "header-subnav-link-genre2",
			},
		],
	},
	{
		order: 2,
		links: [
			{
				label: "House",
				href: "/genre/house/5",
				cypressTag: "header-subnav-link-genre5",
			},
			{
				label: "Indie Dance",
				href: "/genre/indie-dance/37",
				cypressTag: "header-subnav-link-genre37",
			},
			{
				label: "Jackin House",
				href: "/genre/jackin-house/97",
				cypressTag: "header-subnav-link-genre97",
			},
			{
				label: "Mainstage",
				href: "/genre/mainstage/96",
				cypressTag: "header-subnav-link-genre96",
			},
			{
				label: "Melodic House & Techno",
				href: "/genre/melodic-house-techno/90",
				cypressTag: "header-subnav-link-genre90",
			},
			{
				label: "Minimal / Deep Tech",
				href: "/genre/minimal-deep-tech/14",
				cypressTag: "header-subnav-link-genre14",
			},
			{
				label: "Nu Disco / Disco",
				href: "/genre/nu-disco-disco/50",
				cypressTag: "header-subnav-link-genre50",
			},
			{
				label: "Organic House / Downtempo",
				href: "/genre/organic-house-downtempo/93",
				cypressTag: "header-subnav-link-genre93",
			},
		],
	},
	{
		order: 3,
		links: [
			{
				label: "Progressive House",
				href: "/genre/progressive-house/15",
				cypressTag: "header-subnav-link-genre15",
			},
			{
				label: "Psy-Trance",
				href: "/genre/psy-trance/13",
				cypressTag: "header-subnav-link-genre13",
			},
			{
				label: "Tech House",
				href: "/genre/tech-house/11",
				cypressTag: "header-subnav-link-genre11",
			},
			{
				label: "Techno (Peak Time / Driving)",
				href: "/genre/techno-peak-time-driving/6",
				cypressTag: "header-subnav-link-genre6",
			},
			{
				label: "Techno (Raw / Deep / Hypnotic)",
				href: "/genre/techno-raw-deep-hypnotic/92",
				cypressTag: "header-subnav-link-genre92",
			},
			{
				label: "Trance",
				href: "/genre/trance/7",
				cypressTag: "header-subnav-link-genre7",
			},
			{
				label: "Trap / Wave",
				href: "/genre/trap-wave/38",
				cypressTag: "header-subnav-link-genre38",
			},
			{
				label: "UK Garage / Bassline",
				href: "/genre/uk-garage-bassline/86",
				cypressTag: "header-subnav-link-genre86",
			},
		],
	},
];

export const HEADER_MENU_ITEMS = [
	{
		order: 0,
		label: "Genres",
		subMenu: GENRE_MENU_ITEMS,
		cypressTag: "header-mainnav-link-genre",
	},
	{
		order: 1,
		label: "Dig Deeper",
		href: "/staff-picks",
		cypressTag: "header-mainnav-link-staffpicks",
	},
	{
		order: 2,
		label: "Beatport DJ",
		href: "https://dj.beatport.com/",
		newWindow: true,
		cypressTag: "header-mainnav-link-djapp",
	},
	{
		order: 3,
		label: "Beatportal",
		href: "https://www.beatportal.com/",
		newWindow: true,
		cypressTag: "header-mainnav-link-beatport",
	},
	{
		order: 4,
		label: "Beatport Studio",
		href: "https://www.pluginboutique.com/beatport-studio",
		newWindow: true,
		cypressTag: "header-mainnav-link-beatport-studio",
	},
	{
		order: 5,
		label: "Beatport.io",
		href: "https://beatport.io",
		newWindow: true,
		cypressTag: "header-mainnav-link-beatport-io",
	},
];

export const LABEL_PLACEHOLDER = {
	title: "Label.Title",
	href: "https://geo-media.beatport.com/image_size/500x500/cda9862c-cf92-4d13-ac65-7e9277181f51.jpg",
	image: {
		path: "/label-placeholder.jpg",
		width: 200,
		height: 200,
	},
	cypressTag: "search-labelplaceholder",
};
export const nextAuthRefreshInterval = 35995; // api4 is 36000 minutes - refresh this 5 min prior to expiration

export const PURCHASE_TYPE_IDS = {
	purchase: 1,
	upgrade: 2,
	preorder: 3,
};

export const PURCHASE_TYPE_TYPES: { [key: number]: string } = {
	1: "item_purchase",
	2: "lossless_upgrade",
	3: "pre_order",
};

export const ITEM_TYPE_IDS = {
	track: 1,
	release: 2,
};

export const RELEASE_PLACEHOLDER = {
	title: "Release",
	href: "https://geo-media.beatport.com/image_size/250x250/ab2d1d04-233d-4b08-8234-9782b34dcab8.jpg",
	image: {
		path: "/release-placeholder.jpg",
		width: 200,
		height: 200,
	},
	cypressTag: "search-releaseplaceholder",
};

export const SIDEBAR_LINKS: SidebarLinkProps[] = [
	{
		label: "MyBeatport",
		icon: SidebarIconKey.Heart,
		href: "/my-beatport",
		cypressTag: "sidebar-nav-my-beatport",
		requireAuth: true,
	},
	{
		label: "Collection.label",
		icon: SidebarIconKey.Collection,
		href: "/library",
		cypressTag: "sidebar-nav-collection",
		requireAuth: true,
	},
	{
		label: "Downloads.Title",
		icon: SidebarIconKey.Downloads,
		href: "/library/downloads",
		cypressTag: "sidebar-nav-downloads",
		requireAuth: true,
	},
	{
		label: "Playlists.Title",
		icon: SidebarIconKey.Playlists,
		href: "/library/playlists",
		cypressTag: "sidebar-nav-playlists",
		requireAuth: true,
	},
];

export const SOCIAL_LINKS = [
	{
		title: "Facebook",
		href: "https://www.facebook.com/beatport",
		icon: {
			path: "facebook-footer",
			width: 24,
			height: 20,
		},
		cypressTag: "footer-link-facebook",
	},
	{
		title: "Twitter",
		href: "https://www.twitter.com/beatport",
		icon: {
			path: "twitter-footer",
			width: 24,
			height: 20,
		},
		cypressTag: "footer-link-twitter",
	},
	{
		title: "Instagram",
		href: "https://www.instagram.com/beatport",
		icon: {
			path: "instagram",
			width: 24,
			height: 20,
		},
		cypressTag: "footer-link-instagram",
	},
	{
		title: "Tiktok",
		href: "https://www.tiktok.com/@beatport",
		icon: {
			path: "tiktok",
			width: 24,
			height: 20,
		},
		cypressTag: "footer-link-tiktok",
	},
	{
		title: "Youtube",
		href: "https://www.youtube.com/c/beatport",
		icon: {
			path: "youtube",
			width: 24,
			height: 20,
		},
		cypressTag: "footer-link-youtube",
	},
	{
		title: "Discord",
		href: "https://discord.gg/ezBmCaCknd",
		icon: {
			path: "discord",
			width: 24,
			height: 20,
		},
		cypressTag: "footer-link-discord",
	},
];

export const TRACK_PLACEHOLDER = {
	title: "Track",
	href: "https://geo-media.beatport.com/image_size/95x95/ab2d1d04-233d-4b08-8234-9782b34dcab8.jpg",
	image: {
		path: "/track-placeholder.jpg",
		width: 38,
		height: 38,
	},
	cypressTag: "search-trackplaceholder",
};

export const TRIAL_SUBSCRIPTION_STATUS = "in_trial";

export const ToCLink = () =>
	FOOTER_LINKS[0].links.filter((l) => l.label === "Footer.TermsAndConditions")[0];

export const urlPayload = (params: any) => new URLSearchParams(params);

export const ENCODING_STATUSES = {
	COMPLETE: "COMPLETE",
	ENCODING: "ENCODING",
	ERROR: "ERROR",
};

export const MOBILE_APP_LINKS: (ImageProps & Pick<LinkProps, "href">)[] =
	[{
		src: "/images/apple-store.svg",
		alt: "",
		width: "120",
		height: "40",
		href: "https://apps.apple.com/us/app/beatport-music-for-djs/id1543739988",
	},
	{
		src: "/images/google-store.svg",
		alt: "",
		width: "135",
		height: "40",
		href: "https://play.google.com/store/apps/details?id=com.beatport.mobile&hl=en",
	}];
