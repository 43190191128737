import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  user-select: none;

  > * {
    margin: 0 10px;
  }

  > div {
    height: 50px;
    
    svg {
      path {
        stroke: ${(props) => props.theme.colors.neutrals.primary.graytextFade};
        fill: ${(props) => props.theme.colors.neutrals.primary.graytextFade};
      }
    }
  }

  > span {
    font-size: 18px;
    color: ${(props) => props.theme.colors.neutrals.primary.graytextFade};
  }
`;
