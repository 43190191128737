import Button from "@components/Button";
import { Form, FormLabel, TextInput, ValidatorFn } from "@components/core/Form";
import { dynamicImageUrl } from "@lib/utils/dynamicImageUrl";
import { Playlist } from "@models/Playlists";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useState } from "react";
import {
	ActionToggle,
	ButtonsWrapper,
	ElementsWrapper,
	FormWrapper,
	ImagesWrapper,
	Wrapper,
} from "./PlaylistForm.style";

interface Props {
	onSubmit: (data: Record<string, any>) => void;
	onCancel: () => void;
	playlist?: Partial<Playlist>;
	formErrors?: Record<string, Array<string>>;
	formId?: string;
}

const PlaylistForm: React.FC<Props> = ({
	onSubmit,
	onCancel,
	playlist,
	formErrors,
	formId = "new-playlist",
}) => {
	const { t } = useTranslation("translation");
	const [loading, setLoading] = useState(false);

	const nameValidator: ValidatorFn = async (field, value) => {
		if (value.length > 64) {
			return {
				valid: false,
				message: t("Validation.64Characters"),
			};
		}

		const pattern = /^[a-z\d\-_\s]+$/i;

		if (!pattern.test(value)) {
			return {
				valid: false,
				message: t("Validation.Name"),
			};
		}

		return {
			valid: true,
			message: "",
		};
	};

	const handleSubmit = async (data: FormData) => {
		setLoading(true);
		const entries = {
			...Object.fromEntries(data.entries()),
		};

		await onSubmit(entries);
		setLoading(false);
	};

	const getElementError = (key: string) => {
		return formErrors ? formErrors[key] : undefined;
	};

	const renderReleaseImageAt = (position: number) => {
		const releaseImages = playlist?.release_images || [];
		const img = releaseImages.at(position);
		const srcUrl = img ? img : "/icons/playlist-default-img.svg";
		return (
			<Image
				key={position}
				width={0}
				height={0}
				src={dynamicImageUrl({ imageUri: srcUrl })}
				alt="Playlist Image"
				sizes="100vw"
				style={{
					width: "50%",
					height: "auto",
				}}
			/>
		);
	};

	return (
		<Form id={formId} onSubmit={handleSubmit}>
			<Wrapper>
				<ImagesWrapper>
					<div className="row">
						{renderReleaseImageAt(0)}
						{renderReleaseImageAt(1)}
					</div>
					<div className="row">
						{renderReleaseImageAt(2)}
						{renderReleaseImageAt(3)}
					</div>
				</ImagesWrapper>
				<FormWrapper>
					<ElementsWrapper>
						<FormLabel htmlFor="name">{t("Playlists.Name")}</FormLabel>
						<TextInput
							className="input-wrapper"
							id="name"
							name="name"
							type="text"
							defaultValue={playlist?.name || ""}
							errors={getElementError("name")}
							validators={["required", nameValidator]}
							required
						/>
						<ActionToggle>
							<label>
								{t("Public")}
								:
								<span className="switch">
									<input
										type="checkbox"
										name="is_public"
										value="true"
										defaultChecked={playlist ? playlist.is_public : true}
										disabled={loading}
									/>
									<span className="slider round"></span>
								</span>
							</label>
						</ActionToggle>
					</ElementsWrapper>
					<ButtonsWrapper>
						<div className="controls">
							<Button
								type="secondary"
								onClick={() => {
									onCancel();
								}}
							>
								{t("Actions.Cancel")}
							</Button>
							<Button
								type="primary"
								disabled={loading}
								onClick={() => {
									const event = new Event("submit", {
										bubbles: true,
										cancelable: true,
									});
									const formElement = document.getElementById(formId);
									if (formElement) {
										formElement.dispatchEvent(event);
									}
								}}
							>
								{t("Actions.Save")}
							</Button>
						</div>
					</ButtonsWrapper>
				</FormWrapper>
			</Wrapper>
		</Form>
	);
};

export default PlaylistForm;
