import { anonAccessToken } from "@lib/network/request";
import { Session } from "next-auth";
import { useSession } from "next-auth/react";
import { createContext, useContext } from "react";

interface SessionContextProps {
	session: Session | null;
	status: string;
	update: (data?: any) => Promise<Session | null>;
	importAnonSession: (anonSession: AnonSession) => void;
	getAccessToken: () => string;
	getIsAnon: () => boolean;
	getIsSessionValid: ({ isAnonAllowed }?: { isAnonAllowed?: boolean }) => boolean;
}

const SessionContext = createContext<SessionContextProps | object>({});

const LocalSessionProvider = ({ children }: { children: any }) => {
	const { data: session, status, update } = useSession({
		// ? Set as `false` because we handle the anon session manually using `getAccessToken` and `getIsAnon`
		required: false,
	});

	const importAnonSession = (anonSession: AnonSession) => {
		anonAccessToken.importSession(anonSession);
	};

	const getAccessToken = () => {
		return session?.token?.accessToken || anonAccessToken.accessToken() || "";
	};

	const getIsAnon = () => {
		if (session?.token?.accessToken) {
			return session?.token?.anon || session?.token?.scope?.includes("anon");
		}

		return anonAccessToken.accessToken() ? true : false;
	};

	const getIsSessionValid = ({ isAnonAllowed = true }: { isAnonAllowed?: boolean } = {}) => {
		const validAccessToken = getAccessToken();
		const isAnon = getIsAnon();

		if (!validAccessToken) {
			return false;
		} else if (!isAnonAllowed && isAnon) {
			return false;
		} else {
			return true;
		}
	};

	return (
		<SessionContext.Provider value={{ session, status, update, importAnonSession, getAccessToken, getIsAnon, getIsSessionValid }}>
			{children}
		</SessionContext.Provider>
	);
};

const useSessionContext = (): SessionContextProps => {
	const context: any = useContext(SessionContext);

	if (!context) {
		throw new Error("SessionContext must be wrapped in LocalSessionProvider");
	}

	return context;
};

const exportAnonSession = async () => {
	return await anonAccessToken.exportSession();
};

export { exportAnonSession, LocalSessionProvider, useSessionContext };
