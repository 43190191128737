import {
	Form,
	FormButton,
	FormGroup,
	FormLabel,
	FormRow,
	ValidatorFn,
} from "@components/core/Form";
import TextInput from "@components/core/Form/TextInput/TextInput";
import { FORGOT_PASSWORD_URL } from "@lib/constants/urls";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { useState } from "react";
import { ForgotPassword, FormWrapper } from "./LoginForm.style";

interface Props {
	onSubmit: (data: Record<string, any>) => void;
	formErrors?: Record<string, Array<string>>;
	hideSubmit?: boolean;
	formID?: string;
}

const LoginForm: React.FC<Props> = ({
	onSubmit,
	formErrors,
	hideSubmit,
	formID,
}) => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation("translation");

	const UsernameValidator: ValidatorFn = async (field, value) => {
		if (!value) {
			return {
				valid: false,
				message: t("Validation.Username.NotProvided"),
			};
		}

		return {
			valid: true,
			message: "",
		};
	};

	const handleSubmit = async (data: FormData) => {
		const entries = {
			...Object.fromEntries(data.entries()),
		};

		setLoading(true);
		await onSubmit(entries);
		setLoading(false);
	};

	const getElementError = (key: string) => {
		return formErrors ? formErrors[key] : undefined;
	};

	return (
		<FormWrapper>
			<Form id={formID} onSubmit={handleSubmit}>
				<FormRow>
					<FormGroup>
						<FormLabel htmlFor="username">{t("Account.Username")}</FormLabel>
						<TextInput
							data-testid="login-input-username"
							id="username"
							name="username"
							type="text"
							errors={getElementError("username")}
							validators={["required", UsernameValidator]}
							required
						/>
					</FormGroup>
				</FormRow>

				<FormRow>
					<FormGroup>
						<FormLabel htmlFor="password">{t("Account.Password.Title")}</FormLabel>
						<TextInput
							data-testid="login-input-password"
							id="password"
							name="password"
							type="password"
							errors={getElementError("password")}
							validators={["required", "password"]}
							required
						/>
					</FormGroup>
				</FormRow>
				<FormRow>
					<ForgotPassword>
						<Link
							href={FORGOT_PASSWORD_URL}
							prefetch={false}
							title="Forgot username or password?"
						>
							Forgot username or password?
						</Link>
					</ForgotPassword>
				</FormRow>
				<FormButton
					type="submit"
					value={t("Actions.Login")}
					disabled={loading}
					className={hideSubmit ? "hidden" : ""}
				/>
			</Form>
		</FormWrapper>
	);
};

export default LoginForm;
