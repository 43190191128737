import { t } from "i18next";

type Validator = "required" | "email" | "password" | "cvv";
type ValidatorResult = {
	valid: boolean;
	message: string;
};
type ValidatorFn = (field: string, val: string) => Promise<ValidatorResult>;

const validationMethods: Record<Validator, ValidatorFn> = {
	required: async (field, value) => ({
		valid: !!value,
		message: t("Validation.RequiredField"),
	}),
	email: async (field, value) => ({
		valid:
			/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				value,
			),
		message: t("Validation.InvalidEmail"),
	}),
	password: async (field, value) => ({
		valid: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(value),
		message: t("Validation.Password"),
	}),
	cvv: async (field, value) => ({
		valid: /^[0-9]{3,4}$/.test(value),
		message: t("Validation.CVV"),
	}),
};

export { validationMethods };
export type { Validator, ValidatorFn };
