import { Select } from "@components/core/Form";
import { TracksList } from "@components/lists/TracksList";
import { LibraryTable } from "@components/tables/LibraryTable";
import { useCart } from "@lib/context/cart";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { CartDetails } from "@models/Cart";
import { device } from "@styles/theme";
import { useState } from "react";
import { CartsWrapper } from "./Carts.style";

interface Props {
	selectedTracks: number[];
	loadingTracks: number[];
	onTrackSelected: (trackId: number) => void;
}

const renderCartName = (cart: CartDetails): string => {
	let cartName = "";
	switch (cart.name) {
		case "cart":
			cartName = "Main Cart";
			break;
		case "hold-bin":
			cartName = "Hold Bin";
			break;
		default:
			cartName = cart.name;
	}
	return `${cartName} (${cart.tracks?.length || 0})`;
};

const Carts: React.FC<Props> = ({
	selectedTracks,
	loadingTracks,
	onTrackSelected,
}) => {
	const { state } = useCart();
	const { sortedCarts, loading } = state;

	const isDesktop = useMediaQuery({ query: device.xl });

	const [selectedCart, setSelectedCart] = useState(
		sortedCarts.find((cart) => cart.name === "cart"),
	);
	const cartsOptions = sortedCarts.map((cart) => ({
		value: cart.id,
		name: renderCartName(cart),
	}));

	const cartTracks = selectedCart?.tracks?.map?.((track) => track.item);

	return (
		<CartsWrapper data-testid="add-to-chart-from-carts">
			Choose cart
			<Select
				id="carts"
				name="carts"
				options={cartsOptions}
				onChange={(e) =>
					setSelectedCart(sortedCarts.find((cart) => cart.id === Number(e.target.value)))}
			/>
			{!loading &&
				(isDesktop ?
						(
							<LibraryTable
								location="Tracks Selector Modal - Carts Tab"
								tracks={cartTracks || []}
								isAddTrackModal
								chartAddTrackLoading={loadingTracks}
								chartAddedTracks={selectedTracks}
								chartAddTrack={onTrackSelected}
							/>
						) :
						(
							<TracksList
								location="Tracks Selector Modal - Carts Tab"
								tracks={cartTracks || []}
								showArtwork
								isCollection
								isAddTrackModal
								chartAddTrackLoading={loadingTracks}
								chartAddedTracks={selectedTracks}
								chartAddTrack={onTrackSelected}
							/>
						))}
		</CartsWrapper>
	);
};

export default Carts;
