import { API_BASES } from "@lib/constants";
import { LocationParams } from "@models/facets";
import { deleteRequest, getRequest, postRequest, putRequest } from "./request";

const urlPayload = (params: any) => new URLSearchParams(params);

interface GetChartsParams {
	add_date?: string;
	dj_name?: string;
	dj_id?: number;
	enabled?: boolean;
	genre_name?: string;
	genre_id?: string;
	sub_genre_name?: string;
	sub_genre_id?: string;
	id?: number;
	is_approved?: boolean;
	is_indexed?: boolean;
	is_published?: boolean;
	name?: string;
	updated?: string;
	user_id?: number;
	username?: string;
	register_ip_address?: string;
	track_id?: number;
	publish_date?: string;
	is_curated_playlist?: boolean;
	curated_playlist_genre_id?: number;
	order_by?: string;
	page?: number;
	per_page?: number;
	include_facets?: boolean;
}

interface GetMyChartsParams {
	page?: string;
	per_page?: string;
	is_published?: boolean;
}

export const getChartsRequest = async ({
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	params: GetChartsParams;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = "catalog/charts/?" + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getChartsQuery = ({
	params,
	accessToken,
	enabled = true,
	location,
}: {
	params: GetChartsParams;
	accessToken?: string;
	enabled?: boolean;
	location?: LocationParams;
}) => ({
	queryKey: [`charts-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getChartsRequest({
			params,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
	enabled: enabled,
});

export const getChartRequest = async ({
	accessToken,
	id,
	location,
	withErrorHandling,
}: {
	accessToken?: string;
	id: number;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/charts/${id}/`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getChartQuery = ({
	accessToken,
	id,
	location,
}: {
	accessToken?: string;
	id: number;
	location?: LocationParams;
}) => ({
	queryKey: [`track-${id}`],
	queryFn: async () => {
		const { data } = await getChartRequest({
			accessToken,
			id,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getTrackChartsRequest = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/tracks/${id}/charts/?per_page=50`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getTrackChartsQuery = ({
	id,
	accessToken,
	location,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
}) => ({
	queryKey: [`track-${id}-charts`],
	queryFn: async () => {
		const { data } = await getTrackChartsRequest({
			id,
			accessToken: accessToken || "",
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getMyChartsRequest = async ({
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	params: GetMyChartsParams;
	accessToken: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = "my/charts/?" + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getMyChartsQuery = ({
	params,
	accessToken,
	enabled = true,
	location,
}: {
	params: GetMyChartsParams;
	accessToken: string;
	enabled?: boolean;
	location?: LocationParams;
}) => ({
	queryKey: [`my-charts-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getMyChartsRequest({
			params,
			accessToken: accessToken || "",
			location,
			withErrorHandling: false,
		});
		return data;
	},
	enabled: enabled,
});

export const getMyChartTracksRequest = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `my/charts/${id}/tracks/?per_page=100`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getMyChartTracksQuery = ({
	id,
	accessToken,
	location,
}: {
	id: number;
	accessToken: string;
	location?: LocationParams;
}) => ({
	queryKey: [`my-chart-${id}-tracks`],
	queryFn: async () => {
		const { data } = await getMyChartTracksRequest({
			id,
			accessToken: accessToken || "",
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const postMyChartTracksRequest = async (
	{ chartId, trackId, accessToken }: { chartId: number; trackId: number; accessToken: string },
) => {
	const url = `my/charts/${chartId}/tracks/`;
	return postRequest({
		url,
		apiBase: API_BASES.api4,
		accessToken,
		data: { track: trackId },
	});
};

export const putMyChartTracksRequest = async (
	{ chartId, trackId, params, accessToken }: {
		chartId: number; trackId: number; params: {
			position: number;
		}; accessToken: string;
	},
) => {
	const url = `my/charts/${chartId}/tracks/${trackId}/`;
	return putRequest({
		url,
		apiBase: API_BASES.api4,
		accessToken,
		data: params,
	});
};

export const deleteMyChartTracksRequest = async (
	{ chartId, trackId, accessToken }: { chartId: number; trackId: number; accessToken: string },
) => {
	const url = `my/charts/${chartId}/tracks/${trackId}/`;
	return deleteRequest({
		url,
		apiBase: API_BASES.api4,
		accessToken,
	});
};
