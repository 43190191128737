import { forwardRef } from "react";
import {
	FormButton,
	FormContainer,
	FormGroup,
	FormLabel,
	FormRow,
	StyledForm,
} from "./form.style";

interface Props {
	action?: string;
	id?: string;
	children: React.ReactNode[] | React.ReactNode;
	onSubmit: (data: FormData, element: HTMLFormElement) => void;
	onChange?: () => void;
}

const Form = forwardRef<HTMLFormElement, Props>(
	({ id, action, children, onSubmit, onChange }, ref) => {
		const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			const formElement = e.target as HTMLFormElement;
			formElement.classList.add("submitted");

			// form is submitted, but there is a possibility that user did not trigger a validation that happens onBlur event
			// focusing the first invalid field
			const focusNeeded = formElement.querySelectorAll("[data-focus='true']");

			focusNeeded.forEach((el) => {
				const event = new Event("onBlur", { bubbles: false, cancelable: true });
				el.dispatchEvent(event);
			});

			// we need to catch all invalid fields
			const invalidField = formElement.querySelector(
				"[invalid='invalid']",
			) as HTMLInputElement;
			if (invalidField) {
				invalidField.focus();
				return;
			}

			// finally we check internal validity
			const isValid = formElement.checkValidity();

			// focusing the first invalid field
			const firstInvalidField = formElement.querySelector(
				":invalid",
			) as HTMLInputElement;

			firstInvalidField?.focus();

			// submit the dataObject if isValid===true
			if (isValid) {
				const dataObject = new FormData(formElement);
				onSubmit(dataObject, formElement);
			}
		};
		return (
			<StyledForm
				id={id}
				action={action}
				onSubmit={handleSubmit}
				onChange={onChange ?? undefined}
				noValidate
				role="form"
				ref={ref}
			>
				<FormContainer>{children}</FormContainer>
			</StyledForm>
		);
	},
);

Form.displayName = "Form";

export default Form;
export { FormButton, FormGroup, FormLabel, FormRow };
