import { ButtonStateType } from "@bptypes/collection";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { ItemLoader } from "@components/loaders";
import { MAX_FREE_DOWNLOADS } from "@lib/constants/subscriptions";
import { useCollectionActions } from "@lib/context/collection";
import { useSessionContext } from "@lib/context/session";
import { useWindowContext } from "@lib/context/window";
import { Track } from "@models/track";
import { screenSizes } from "@styles/theme";
import { useTranslation } from "next-i18next";
import React, { MouseEventHandler, useState } from "react";
import { toast } from "react-hot-toast/headless";
import { Tooltip } from "../Tooltip";
import { ItemLoaderWrapper } from "./ReDownloadButton.style";

interface Props {
	track: Track;
	buttonState: ButtonStateType;
	filenamePattern?: string;
	disabled?: boolean;
	onClick?: (handleDownloadFn: MouseEventHandler) => void | undefined;
}

const ReDownloadButton: React.FC<Props> = ({
	track,
	buttonState,
	disabled,
	onClick,
}) => {
	const { t } = useTranslation("translation");
	const { width } = useWindowContext();
	const [buttonStatus, setButtonStatus] = useState<ButtonStateType>(buttonState);
	const { getAccessToken } = useSessionContext();
	const accessToken = getAccessToken();
	const { addTrackToDownload } = useCollectionActions();
	const handleDownload: MouseEventHandler = async () => {
		setButtonStatus("LOADING");
		addTrackToDownload({ ...track, received_status: false }, accessToken);
		toast.success(t("Collection.AddedToDownloadsToast.Success"), {
			duration: 2000,
		});
		setButtonStatus("ADDED");
	};

	const toolTipPosition = width < screenSizes.xl ? "top" : "left";

	let Element: JSX.Element;
	switch (buttonStatus) {
		case "ADD":
			if (disabled) {
				Element = (
					<button disabled={disabled}>
						<Tooltip text={t("Collection.LimitReached", { downloads: MAX_FREE_DOWNLOADS })} position={toolTipPosition}>
							<SpriteIcon id="re-download-disabled" />
						</Tooltip>
					</button>
				);
			} else {
				Element = (
					<button onClick={(event) => onClick ? onClick(handleDownload) : handleDownload(event)}>
						<Tooltip text={t("Collection.AddToDownloads")} position={toolTipPosition}>
							<SpriteIcon id="re-download" />
						</Tooltip>
					</button>
				);
			}
			break;
		case "ADDED":
			Element = (
				<button disabled>
					<Tooltip text={t("Collection.AddedToDownloads")} position={toolTipPosition}>
						<SpriteIcon id="download-finished" width="13" height="9" />
					</Tooltip>
				</button>
			);
			break;
		case "LOADING":
			Element = (
				<ItemLoaderWrapper disabled data-testid="loader-wrapper">
					<ItemLoader />
				</ItemLoaderWrapper>
			);
			break;
		case "UNAVAILABLE":
			Element = (
				<button disabled>
					<></>
				</button>
			);
			break;
	}
	return <>{Element}</>;
};

export default ReDownloadButton;
