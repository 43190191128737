import { Artist } from "@models/release";
import { pushProductArtistEvent } from "@utils/dataLayer";
import Link from "next/link";
import styled from "styled-components";

const logArtistClickEvent = (artist: Artist, location: string | null = null) => {
	pushProductArtistEvent({
		id: artist.id.toString(),
		name: artist.name,
		list: location,
		imageUrl: artist.image.uri,
	});
};

/*
  Renders a text line of artist names.
*/
export const renderArtistNames = (
	artists: Artist[],
	{
		prefetch = false,
		location = "",
	}: {
		prefetch?: boolean;
		location?: string;
	} = {},
): any =>
	Array.isArray(artists) &&
	artists
		?.map((artist) => (
			<Link
				key={`artist-${artist?.id}`}
				href={`/artist/${artist?.slug}/${artist?.id}`}
				prefetch={prefetch}
				title={artist?.name}
				onClick={() => logArtistClickEvent(artist, location)}
			>
				{artist?.name}
			</Link>
		))
		?.reduce((prev, curr) => (
			<>
				{prev},&nbsp;{curr}
			</>
		));

/*
  A basic Artist Names div. Note that renderArtistNames() does not use this component
  specifically, allowing presentation & logic to be seperated by the client component.
  Any component can render the artist text and still customize the appearance by overriding
  the style here.
*/
export const ArtistNames = styled.div<{ liveStream?: boolean }>`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  letter-spacing: 0.005em;

  color: ${(props) => (props.liveStream ? props.theme.colors.legacy.greybtn2 : props.theme.colors.blue[500].accent)};

  a:hover {
    text-decoration: underline;
  }
`;
