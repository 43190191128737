import { checkIsMobile } from "@lib/utils";
import { useEffect, useState } from "react";

export const useMobile = () => {
	const [isMobileDevice, setIsMobileDevice] = useState(false);
	useEffect(() => {
		setIsMobileDevice(checkIsMobile());
	}, []);

	return { isMobileDevice };
};
