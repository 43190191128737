import { ItemLoader } from "@components/loaders";
import { postMessage } from "@components/notifications";
import { useCart, useCartActions } from "@lib/context/cart";
import { LayoutContext } from "@lib/context/layout/LayoutContext";
import { useSessionContext } from "@lib/context/session";
import { cls } from "@lib/css";
import { useTranslation } from "next-i18next";
import { useContext, useEffect, useState } from "react";
import { Control } from "./UpgradeToCart.style";
import { AddRemoveItemUpgrade, AddRemoveLosslessUpgrade, UpgradeToCartProps } from "./UpgradeToCart.types";

const UpgradeToCart: React.FC<UpgradeToCartProps> = ({
	showPreOrderText = false,
	upgrade,
}) => {
	const { authenticateLink, showUpgradeTrackSelector } =
		useContext(LayoutContext);
	const { getIsSessionValid } = useSessionContext();
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });
	const { t } = useTranslation("translation");
	const [loading, setLoading] = useState(false);
	const { state } = useCart();
	const { carts, cartDetails, loading: cartsLoading } = state;
	const { removeItemFromCart, addTrackToCart, createCartQueries } = useCartActions();
	const { isTrackInCart, getDefaultCart, getCartById } = createCartQueries(
		carts,
		cartDetails,
	);

	const [pendingAction, setPendingAction] = useState<string[]>([]);

	let price = "N/A";
	let isInCart: { inCart: boolean; cartIds: number[] } = {
		inCart: false,
		cartIds: [],
	};
	let itemType = "";
	if (upgrade) {
		itemType = "upgrade";
		price = "Upgrade";
		isInCart = isTrackInCart(upgrade.id);
	}
	const addRemoveLosslessUpgrade: AddRemoveLosslessUpgrade = async ({
		cart,
		track,
		audioFormatId,
	}) => {
		const purchaseTypeId = 2;
		const sourceTypeId = 1;

		const isIncurrentCart =
			cart.tracks?.some((item) => item.item_id === track.id) || false;

		let cartItemId = 0;
		if (isIncurrentCart) {
			cart.tracks?.forEach((t) => {
				if (t.item_id == track.id) {
					cartItemId = t.id;
				}
			});

			const data = await removeItemFromCart(cart.id, cartItemId);
			const message = `${track.name} ${t("Cart.LosslessUpgrade")} ${t("Cart.RemovedFromCart")}`;

			return {
				success: data.success,
				message: data.success ? message : data.message,
				notificationType: "neutral",
			};
		} else {
			const data = await addTrackToCart({
				cartId: cart.id,
				track,
				audioFormatId,
				purchaseTypeId,
				sourceTypeId,
			});

			const message = `${track.name} ${track.mix_name} ${t("Cart.AddedToCart")}`;

			return {
				success: data.success,
				message: data.success ? message : data.message,
			};
		}
	};

	const addRemoveItem: AddRemoveItemUpgrade = async ({
		cartId,
		audioFormatId,
		defaultCart,
	}) => {
		const cart = defaultCart ?
			defaultCart :
			cartId ?
				getCartById(cartId) :
				getDefaultCart();

		if (cart) {
			if (upgrade) {
				return addRemoveLosslessUpgrade({ cart, track: upgrade, audioFormatId: audioFormatId || 1 });
			} else {
				return {
					success: false,
					message: t("Cart.UnsupportedItem"),
				};
			}
		}
		return {
			success: false,
			message: t("Cart.NotExist"),
		};
	};

	const handleTrackUpgradeClick = async (
		cartId: number,
		audioFormatId: number,
	) => {
		if (!isLoggedInSessionValid) {
			authenticateLink("", () => {
				setLoading(true);
				addRemoveItem({ cartId, audioFormatId }).then(({ success, message, notificationType }) => {
					postMessage({
						type: success ? notificationType || "success" : "error",
						message: message,
					});
					setLoading(false);
				});
			});
		} else {
			setLoading(true);
			addRemoveItem({ cartId, audioFormatId }).then(({ success, message, notificationType }) => {
				postMessage({
					type: success ? notificationType || "success" : "error",
					message: message,
				});
				setLoading(false);
			});
		}
	};

	const showUpgradePicker = async (e: React.MouseEvent<HTMLSpanElement>) => {
		if (itemType !== "upgrade") return;
		if (!isLoggedInSessionValid) {
			authenticateLink("", () => {
				// dont do anything :D
			});
		} else {
			const left = e.clientX + 10;
			const top = e.clientY - 15;

			showUpgradeTrackSelector({
				show: true,
				top,
				left,
				carts: Object.values(carts) || [],
				handleTrackUpgradeClick,
			});
		}
	};

	useEffect(() => {
		if (pendingAction.length > 0 && !cartsLoading) {
			pendingAction.forEach((action) => {
				if (action === "add-remove") {
					addRemoveItem({}).then(({ success, message, notificationType }) => {
						postMessage({
							type: success ? notificationType || "success" : "error",
							message: message,
						});
						setLoading(false);
					});
				}
			});
			setPendingAction([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cartsLoading, pendingAction]);

	return (
		<Control
			className={cls(
				showPreOrderText ? "with-text" : undefined,
				isInCart.inCart ? "in-cart" : undefined,
			)}
			onClick={showUpgradePicker}
			data-testid="upgrade-to-cart-button"
		>
			<>
				<span className="price">{isInCart.inCart ? "In cart" : price}</span>
				<span className="arrow">{loading ? <ItemLoader /> : <i />}</span>
			</>
		</Control>
	);
};

export default UpgradeToCart;
