/* eslint-disable react-hooks/exhaustive-deps */
import { TextInput } from "@components/core/Form";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { TracksList } from "@components/lists/TracksList";
import SimplePageSelect from "@components/paging/SimplePager/SimplePageSelect";
import { LibraryTable } from "@components/tables/LibraryTable";
import { mapSearchTracks } from "@lib/constants/search";
import { useSessionContext } from "@lib/context/session";
import { useChartTracks } from "@lib/hooks/useChartTracks";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { searchTracksQuery } from "@lib/network/search";
import { SearchTracksResponse } from "@models/search-track";
import { device } from "@styles/theme";
import { useQuery } from "@tanstack/react-query";
import { FormEvent, useState } from "react";
import {
	QueryPageWrapper,
	SearchIconWrapper,
	SearchInputWrapper,
	SearchWrapper,
} from "./Search.style";

interface Props {
	chartId: number;
}

const Search: React.FC<Props> = ({ chartId }) => {
	const [searchParams, setSearchParams] = useState({
		inputValue: "",
		query: "",
		page: 0,
	});
	const chartTracksHook = useChartTracks(chartId);
	const { getAccessToken } = useSessionContext();
	const isDesktop = useMediaQuery({ query: device.xl });

	const accessToken = getAccessToken();
	const perPage = 10;

	const { data: searchResponse } = useQuery<SearchTracksResponse>({
		...searchTracksQuery({
			params: {
				q: searchParams.query,
				count: "100",
				preorder: true,
			},
			accessToken,
		}),
		keepPreviousData: true,
	});

	const mappedTracks = mapSearchTracks(searchResponse?.data);

	const currentPageTracks = mappedTracks.slice(
		searchParams.page * perPage,
		(searchParams.page + 1) * perPage,
	);

	const handleSearch = async (e: FormEvent) => {
		e.preventDefault();
		setSearchParams({
			...searchParams,
			query: searchParams.inputValue,
			page: 0,
		});
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...searchParams, page });
	};

	return (
		<SearchWrapper data-testid="add-to-chart-from-search">
			<form onSubmit={handleSearch}>
				<SearchInputWrapper>
					<SearchIconWrapper>
						<SpriteIcon id="search" />
					</SearchIconWrapper>
					<TextInput
						className="search-input"
						type="text"
						id="search-query"
						name="search-query"
						value={searchParams.inputValue}
						onChange={(e) =>
							setSearchParams({ ...searchParams, inputValue: e.target.value })}
					/>
				</SearchInputWrapper>
			</form>
			{!!mappedTracks.length && (
				<>
					<QueryPageWrapper>
						{searchParams.query}
						<SimplePageSelect
							perPage={perPage}
							totalResults={mappedTracks.length}
							page={searchParams.page}
							onChange={handlePageChange}
						/>
					</QueryPageWrapper>
					{isDesktop ?
							(
								<LibraryTable
									location="DJ Chart Page"
									tracks={currentPageTracks || []}
									isAddTrackModal
									chartAddTrackLoading={chartTracksHook.addTrackLoading}
									chartAddedTracks={chartTracksHook.successfullyAddedTracks}
									chartAddTrack={chartTracksHook.addTrack}
								/>
							) :
							(
								<TracksList
									location="DJ Chart Page"
									tracks={currentPageTracks || []}
									showArtwork
									isCollection
									isAddTrackModal
									chartAddTrackLoading={chartTracksHook.addTrackLoading}
									chartAddedTracks={chartTracksHook.successfullyAddedTracks}
									chartAddTrack={chartTracksHook.addTrack}
								/>
							)}
					<QueryPageWrapper>
						<SimplePageSelect
							perPage={perPage}
							totalResults={mappedTracks.length}
							page={searchParams.page}
							onChange={handlePageChange}
						/>
					</QueryPageWrapper>
				</>
			)}
		</SearchWrapper>
	);
};

export default Search;
