import styled from "styled-components";

export const StyledForm = styled.form`
  width: 100%;

  &.submitted :invalid {
    outline: 2px solid ${(props) => props.theme.colors.semantic.danger};
    box-shadow: ${(props) => props.theme.boxShadows.danger["1"]};
  }
`;

export const FormContainer = styled.div``;

export const FormRow = styled.div<{ hidden?: boolean }>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`;

export const FormGroup = styled.div`
  width: 100%;
  &.right {
    display: flex;
    justify-content: flex-end;
  }
`;

export const FormLabel = styled.label`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const FormButton = styled.input`
  background-color: ${(props) => props.theme.colors.green[300].accent};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.neutrals.primary[900]};
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  line-height: 20px;
  padding: 9px 20px 11px;
  width: 100%;
  border-width: 0;
  margin-top: 8px;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    pointer-events: all !important;
    color: ${(props) => props.theme.colors.neutrals.primary[600]};
    background: ${(props) =>
		props.theme.colors.neutrals.secondary.gray.divider2};
  }

  &.hidden {
    display: none;
  }
`;
