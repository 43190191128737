import { API_BASES } from "@lib/constants";
import { getRequest } from "@lib/network/request";
import { LocationParams } from "@models/facets";
const urlPayload = (params: any) => new URLSearchParams(params);

interface GetReleasesParams {
	hype?: boolean;
	artist_name?: string;
	artist_id?: string;
	catalog_number?: string;
	created?: string;
	current_status?: number;
	enabled?: boolean;
	encoded_date?: string;
	exclusive?: boolean;
	genre_name?: string;
	genre_id?: string;
	id?: number;
	is_available_for_streaming?: boolean;
	label_name?: string;
	label_id?: string;
	label_enabled?: boolean;
	new_release_date?: string;
	pre_order_date?: string;
	publish_date?: string;
	release_type_id?: number;
	sub_genre_id?: string;
	supplier_name?: string;
	supplier_id?: string;
	track_name?: string;
	track_id?: string;
	upc?: string;
	updated?: string;
	type?: string;
	type_id?: number;
	order_by?: string;
	page?: number;
	per_page?: number;
	include_facets?: boolean;
	preorder?: boolean | null;
}

export const getReleaseRequest = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/releases/${id}/`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getReleaseQuery = ({
	id,
	accessToken,
	location,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
}) => ({
	queryKey: [`release-${id}`],
	queryFn: async () => {
		const { data } = await getReleaseRequest({
			id,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getReleasesRequest = async ({
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	params: GetReleasesParams;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = "catalog/releases/?" + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getReleasesQuery = ({
	params,
	accessToken,
	location,
}: {
	params: GetReleasesParams;
	accessToken?: string;
	location?: LocationParams;
}) => ({
	queryKey: [`releases-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getReleasesRequest({
			params,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getGenreTopReleasesRequest = async ({
	accessToken,
	genreId,
	number,
	location,
	withErrorHandling,
}: {
	accessToken?: string;
	genreId: number;
	number: number;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url =
    `catalog/genres/${genreId}/top/${number}/?` +
    urlPayload({ top_type: "release", per_page: number });
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getGenreTopReleasesQuery = ({
	accessToken,
	genreId,
	number,
	location,
}: {
	accessToken?: string;
	genreId: number;
	number: number;
	location?: LocationParams;
}) => ({
	queryKey: [`genre-${genreId}-top-${number}-releases`],
	queryFn: async () => {
		const { data } = await getGenreTopReleasesRequest({
			genreId,
			accessToken,
			number,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getTopReleasesRequest = async ({
	params,
	accessToken,
	number,
	location,
	withErrorHandling,
}: {
	params: GetReleasesParams;
	accessToken: string | undefined;
	number: number;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/releases/top/${number}/?` + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getTopReleasesQuery = ({
	params,
	accessToken,
	number,
	location,
}: {
	params: GetReleasesParams;
	accessToken?: string;
	number: number;
	location?: LocationParams;
}) => ({
	queryKey: [`top-${number}-releases-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getTopReleasesRequest({
			params,
			number,
			accessToken: accessToken || "",
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getReleaseRecommendationsRequest = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/releases/${id}/uab/?per_page=50`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getReleaseRecommendationsQuery = ({
	id,
	accessToken,
	location,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
}) => ({
	queryKey: [`release-${id}-recommendations`],
	queryFn: async () => {
		const { data } = await getReleaseRecommendationsRequest({
			id,
			accessToken: accessToken || "",
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getLabelTopReleasesRequest = async ({
	labelId,
	number,
	accessToken,
	location,
	withErrorHandling,
}: {
	labelId: number;
	number: number;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url =
    `catalog/labels/${labelId}/top/${number}/?` +
    urlPayload({ top_type: "release", per_page: number });
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getLabelTopReleasesQuery = ({
	labelId,
	number,
	accessToken,
	location,
}: {
	labelId: number;
	number: number;
	accessToken?: string;
	location?: LocationParams;
}) => ({
	queryKey: [`top-${number}-label-${labelId}`],
	queryFn: async () => {
		const { data } = await getLabelTopReleasesRequest({
			labelId,
			number,
			accessToken: accessToken || "",
			location,
			withErrorHandling: false,
		});
		return data;
	},
});
