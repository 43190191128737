import Link from "next/link";
import styled from "styled-components";

export const PageNavItems = styled.div`
display: flex;
flex-direction: row;
`;

export const Page = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  padding: 5px 8px 6px;

  &:hover {
    color: ${(props) => props.theme.colors.neutrals.primary.white};
  }

  &.active {
    color: ${(props) => props.theme.colors.neutrals.primary.white};

    border-bottom: 1px solid ${(props) => props.theme.colors.green[300].accent};
    box-shadow: inset 0px 1px 2px rgba(27, 31, 35, 0.075);
  }

  &.disabled {
    color: ${(props) => props.theme.colors.neutrals.primary.graytextFade};
    cursor: not-allowed;
  }
`;

export const DisabledPageLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  padding: 5px 8px 6px;

  &:hover {
    color: ${(props) => props.theme.colors.neutrals.primary.white};
  }

  &.active {
    color: ${(props) => props.theme.colors.neutrals.primary.white};

    border-bottom: 1px solid ${(props) => props.theme.colors.green[300].accent};
    box-shadow: inset 0px 1px 2px rgba(27, 31, 35, 0.075);
  }

  &.disabled {
    color: ${(props) => props.theme.colors.neutrals.primary.graytextFade};
    cursor: not-allowed;
  }
`;

export const PreOrderContainer = styled.div`
  padding: 0 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const PerPage = styled(Link)`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 8px 6px;
  gap: 8px;

  min-width: 28px;
  min-height: 28px;

  background: ${(props) => props.theme.colors.neutrals.primary[600]};

  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};

  box-shadow: inset 0px 1px 2px rgba(27, 31, 35, 0.075);
  border-radius: 100px;
  cursor: pointer;

  &.active {
    background: ${(props) =>
		props.theme.colors.neutrals.secondary.gray.divider2};
    color: ${(props) => props.theme.colors.neutrals.primary.white};
  }
`;

export const Title = styled.span`
  padding: 7px 4px 9px 0px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  input {
    width: 50px;
    padding: 5px 4px 6px;
  }

  @media ${(props) => props.theme.device.lg} {
    width: initial;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;

  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
`;

export const PagerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  &.right {
    justify-content: flex-end;
  }
`;
