import styled from "styled-components";

export const Message = styled.div`
  max-width: 30rem;
  text-align: center;

  a {
    text-decoration: underline;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 8px;
`;
