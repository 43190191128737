import styled from "styled-components";

// TODO: cribbed from PlansContainer. Make more D.R.Y.
export const ResultsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
`;

export const SearchInput = styled.input`
  font-size: max(100%, 16px);
`;

export const TrackRow = styled.li`
  display: flex;
  float: left;
  width: 100%;
  height: 34px;
`;

export const TrackThumbnail = styled.div`
  width: 34px;
`;

export const ArtistThumbnail = styled.div`
  width: calc((100% - 80px)/5);
  margin: 0 10px 10px 0;
`;
export const ChartThumbnail = ArtistThumbnail;

export const ReleaseThumbnail = styled.div`
  width: calc((100% - 80px)/7);
  margin: 0 10px 10px 0;
`;

export const LabelThumbnail = styled.div`
  width: calc((100% - 80px)/6);
  margin: 0 10px 10px 0;
`;
