import Button from "@components/Button";
import { Modal } from "@components/core/Modal";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { PlaylistItem } from "@models/Playlists";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { ButtonWrapper, Message, Wrapper } from "./DuplicateTracksModal.style";

interface Props {
	show?: boolean;
	playlistData: any[];
	playlistItems: PlaylistItem[];
	onClose: () => void;
	onAddToPlaylists: (
		playlists: number[],
		playlistItems: PlaylistItem[]
	) => void;
}

const AddToPlaylistModal: React.FC<Props> = ({
	show = false,
	playlistData,
	playlistItems,
	onClose,
	onAddToPlaylists,
}) => {
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const { t } = useTranslation("translation");

	const duplicates = playlistData
		.filter((item) => item.duplicates)
		.map((item) => item.playlist);

	const closeModal = () => {
		onClose();
	};

	const isSelected = (id: number) => {
		return selectedItems.indexOf(id) > -1;
	};

	const selectItem = (id: number) => {
		if (isSelected(id)) {
			const items = selectedItems.filter((item) => item !== id);
			setSelectedItems(items);
		} else {
			const items = [...selectedItems, id];
			setSelectedItems(items);
		}
	};

	const selectAll = () => {
		if (selectedItems.length === duplicates.length) {
			setSelectedItems([]);
		} else {
			setSelectedItems(duplicates.map((i) => i.id));
		}
	};

	const addToPlaylists = () => {
		const tracks = playlistItems.length;
		const duplicatePlaylists = duplicates.length;
		const totalPlaylists = playlistData.length;

		let playlists = [];

		if (
			(tracks === 1 || tracks > 1) &&
			duplicatePlaylists === 1 &&
			totalPlaylists === 1
		) {
			playlists = playlistData.map((item) => item.playlist.id);
		} else {
			playlists = playlistData
				.filter((item) => !item.duplicates)
				.map((item) => item.playlist.id);
			playlists.push(...selectedItems);
		}

		onAddToPlaylists(playlists, playlistItems);
		closeModal();
	};

	const getMessage = () => {
		const tracks = playlistItems.length;
		const duplicatePlaylists = duplicates.length;
		const totalPlaylists = playlistData.length;

		if (tracks === 1 && duplicatePlaylists === 1 && totalPlaylists === 1) {
			const track = playlistItems[0].track;
			const playlist = playlistData[0].playlist;
			const trackName = track.name + " " + track.mix_name;
			const playlistName = playlist.name;
			return t("Playlists.DuplicateTracks.TrackAlreadyIn", { track: trackName, playlist: playlistName });
		}

		if (tracks > 1 && duplicatePlaylists === 1 && totalPlaylists === 1) {
			const playlist = playlistData[0].playlist;
			const playlistName = playlist.name;
			return t("Playlists.DuplicateTracks.TracksExist", { playlist: playlistName });
		}

		if (tracks === 1 && totalPlaylists > 1) {
			const track = playlistItems[0].track;
			const trackName = track.name + " " + track.mix_name;
			return t("Playlists.DuplicateTracks.TrackInFollowing", { track: trackName });
		}

		return t("Playlists.DuplicateTracks.SomeExist");
	};

	return (
		<Modal
			show={show}
			onClose={closeModal}
			header="Duplicate Tracks Detected"
			footer={(
				<ButtonWrapper>
					<Button type="outline-secondary" onClick={closeModal}>
						{t("Actions.Cancel")}
					</Button>
					<Button type="primary" onClick={addToPlaylists}>
						{t("Actions.Add")}
					</Button>
				</ButtonWrapper>
			)}
			showClose
		>
			<Wrapper>
				<Message>{getMessage()}</Message>
				<ul>
					{duplicates.length > 1 && (
						<li>
							<Button onClick={() => selectAll()}>
								<span>
									{selectedItems.length === duplicates.length ?
											(
												<SpriteIcon id="checkbox-selected" width="20" height="20" />
											) :
											(
												<SpriteIcon id="checkbox" width="20" height="20" />
											)}
									{t("Actions.SelectAll")}
								</span>
							</Button>
						</li>
					)}
					{playlistData.length > 1 &&
						duplicates.map((item) => (
							<li key={`duplicate-playlist${item.id}`}>
								<Button onClick={() => selectItem(item.id)}>
									<span>
										{isSelected(item.id) ?
												(
													<SpriteIcon
														id="checkbox-selected"
														width="20"
														height="20"
													/>
												) :
												(
													<SpriteIcon id="checkbox" width="20" height="20" />
												)}
										{item.name}
									</span>
								</Button>
							</li>
						))}
				</ul>
			</Wrapper>
		</Modal>
	);
};

export default AddToPlaylistModal;
