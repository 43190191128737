// Stage Type
export type Stage = {
	host_vendor_url?: string;
	id?: number;
	stage_name?: string;
	stage_number?: number;
	tracklist_vendor_url?: string;
	tracklist_vendor?: {
		api_key?: string;
	};
};

// LiveStream Type
export type LiveStream = {
	id?: number;
	name?: string;
	enabled?: boolean;
	is_auto_enabled?: boolean;
	start_time?: string;
	end_time?: string;
};

// Action Types
export type ActionTypes = {
	setCurrentStage: (stage: Stage) => void;
	setAllStages: (stages: Stage[]) => void;
	setIsExpanded: (isExpanded: boolean) => void;
	setCurrentStream: (currentStream: LiveStream) => void;
};

// State Type
export type TwitchLiveStreamState = {
	currentStage: Stage;
	allStages: Stage[];
	isExpanded: boolean;
	currentStream: LiveStream;
};

// Action Constants
export const SET_CURRENT_STAGE = "@TWITCH_LIVE_STREAM/SET_CURRENT_STAGE";
export const SET_ALL_STAGES = "@TWITCH_LIVE_STREAM/SET_ALL_STAGES";
export const SET_IS_EXPANDED = "@TWITCH_LIVE_STREAM/SET_IS_EXPANDED";
export const SET_CURRENT_STREAM = "@TWITCH_LIVE_STREAM/SET_CURRENT_STREAM";
