import styled from "styled-components";

export const Spacer = styled.div`
  width: 12px;
`;

export const TrackNo = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 36px;
  min-width: 36px;
`;

export const ItemNumber = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 36px;
`;

export const Exclusive = styled.span`
  right: 0;
  top: 0;
  height: 14px;
  padding: 0 1px;
  font-size: 7px;
  background: ${(props) => props.theme.colors.green[300].accent};
  width: 100%;
  color: #000;
  position: absolute;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
`;

export const ReleaseName = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.neutrals.primary.white};

  span {
    font-weight: 400;
  }
`;

export const ArtworkAndControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  a.artwork {
    position: relative;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  &.loading {
    opacity: 0.5;
    select,
    div,
    input {
      filter: blur(0.25rem);
    }
    &::before {
      z-index: 10;
      position: absolute;
      content: "Updating...";
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: ${(props) => props.theme.fonts.aeonik};
      font-style: normal;
      font-weight: 600;
      font-size: 500;
    }
  }

  .list-wrapper {
    width: 100%;
  }
  .fade {
    opacity: 0.25;
    transition: opacity 0.2s ease-in-out;
  }

  .info {
    display: block;
    padding: 0.08rem 0.15rem 0 0;
  }

  span.tombstoned {
    opacity: 0.15;
  }

  .row {
    justify-content: flex-start;

    &:hover .fade {
      opacity: 1;
    }

    &.current {
      background: ${(props) =>
			props.theme.colors.neutrals.secondary.gray.active};
    }
  }

  .cell {
    padding-top: 0;
    padding-bottom: 0;

    a:hover {
      text-decoration: underline;
    }
  }

  .controls {
    padding-left: 0;
    width: 120px;
    min-width: 120px;
  }

  .title {
    width: 100%;
    overflow: hidden;

    .container {
      overflow: hidden;
      > div,
      > a > span {
        overflow: hidden;
      }
    }
  }
  .label {
    width: 200px;
    min-width: 200px;
  }

  .date {
    width: 120px;
    min-width: 120px;
  }

  .bpm {
    width: 150px;
    min-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cart-actions {
    width: 200px;
    min-width: 200px;
    display: flex;
    justify-content: flex-end;
  }

  .card {
    width: 120px;
    min-width: 120px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    button.reorder {
      cursor: grab;
    }
  }

  &.numbers {
    .controls {
      width: 6rem;
      min-width: 6rem;
    }
  }

  .scrollable {
    max-height: 500px;
    position: relative;
    overflow: auto;
  }

  @media ${(props) => props.theme.device.xsm} {

    .card {
      width: 135px;
      min-width: 135px;
    }

    .label {
      display: none;
    }
    .bpm {
      display: none;
    }
    .date {
      display: none;
    }

    .add-to-cart {
      display: none;
    }

    .no-controls {
      .add-to-cart {
        display: block;
      }
    }
    ${ArtworkAndControls} {
      a.artwork {
        min-width: 40px;
      }
      span.fade,
      span.tombstoned {
        display: none;
      }
    }
  }

  @media ${(props) => props.theme.device.md} {
    &.numbers {
      .controls {
        width: 10.625rem;
        min-width: 10.625rem;
      }
    }
    ${ArtworkAndControls} {
      a.artwork {
        min-width: 40px;
      }
      span.fade,
      span.tombstoned {
        display: block;
      }
    }

    .add-to-cart {
      display: flex;
    }
  }

  @media ${(props) => props.theme.device.lg} {
    .label {
      display: block;
    }
    .bpm {
      display: block;
    }

    .card {
      width: 135px;
      min-width: 135px;
    }
  }
  @media ${(props) => props.theme.device.xl} {
    .date {
      display: block;
    }
  }
`;
