import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { postMessage } from "@components/notifications";
import { cls } from "@lib/css";
import { Chart } from "@models/Chart";
import { Playlist } from "@models/Playlists";
import { Release } from "@models/release";
import { Track } from "@models/track";
import { pushSocialShareEvent } from "@utils/dataLayer";
import { useTranslation } from "next-i18next";
import { CopyBtn, Item, LinkItem, ShareLink, Wrapper } from "./Share.style";

interface Props {
	track?: Track;
	release?: Release;
	chart?: Chart;
	playlist?: Playlist;
	disabled?: boolean;
}

const getShareEventType = () => {
	const url = new URL(window.location.href);
	const paths = url.pathname.split("/").filter((x) => x !== "");
	return paths[0];
};

const Share: React.FC<Props> = ({
	track,
	release,
	chart,
	playlist,
	disabled = false,
}) => {
	const { t } = useTranslation("translation");
	const getShareLink = () => {
		if (playlist) {
			return window.location.href.replace(
				"/library/playlists/",
				"/playlists/share/",
			);
		}
		return typeof window !== "undefined" ? window.location.href : "";
	};

	const getEmbedLink = () => {
		if (disabled) return "";
		let id = 0;
		let type = "unknonw";
		if (track) {
			id = track.id;
			type = "track";
		} else if (release) {
			id = release.id;
			type = "release";
		} else if (chart) {
			id = chart.id;
			type = "chart";
		}

		if (id > 0) {
			return `<iframe src="https://embed.beatport.com/?id=${id}&type=${type}" width="100%" height="${
				type === "release" ? "362" : "162"
			}" frameborder="0" scrolling="no" style="max-width:600px;"></iframe>`;
		}
		return "";
	};

	const shareOnFacebook = () => {
		if (disabled) return;
		const navUrl =
      "https://www.facebook.com/sharer/sharer.php?u=" + getShareLink();
		window.open(navUrl, "_blank", "height=320,width=640");
	};
	const shareOnTwitter = () => {
		if (disabled) return;
		const navUrl =
      "https://twitter.com/intent/tweet?url=" +
      getShareLink() +
      "&hashtags=beatport&via=beatport";
		window.open(navUrl, "_blank", "height=320,width=640");
	};

	const embedLink = getEmbedLink();

	const copyLink = (isEmbed?: boolean) => {
		if (!disabled) {
			if (isEmbed) {
				navigator.clipboard.writeText(embedLink);
				postMessage({
					type: "success",
					message: t("Share.EmbedLink"),
				});
			} else {
				navigator.clipboard.writeText(getShareLink());
				postMessage({
					type: "success",
					message: t("Share.ShareLink"),
				});
			}
		}
	};

	return (
		<Wrapper>
			<Item
				className={cls(disabled ? "disabled" : "enabled")}
				title={t("Share.ShareOn", { brand: "Facebook" })}
				data-testid="share-facebook"
				onClick={() => {
					shareOnFacebook();
					pushSocialShareEvent({
						shareDestination: "facebook",
						type: getShareEventType(),
					});
				}}
			>
				<SpriteIcon id="facebook" />
			</Item>
			<Item
				className={cls(disabled ? "disabled" : "enabled")}
				title={t("Share.ShareOn", { brand: "Twitter" })}
				data-testid="share-twitter"
				onClick={() => {
					shareOnTwitter();
					pushSocialShareEvent({
						shareDestination: "twitter",
						type: getShareEventType(),
					});
				}}
			>
				<SpriteIcon id="twitter" />
			</Item>
			<Item
				title="Share Link"
				data-testid={embedLink === "" ? "share-link" : ""}
				className={cls(disabled ? "disabled" : "enabled")}
				onClick={() => {
					if (embedLink === "") {
						copyLink();
						pushSocialShareEvent({
							shareDestination: "clipboard",
							type: getShareEventType(),
						});
					}
				}}
			>
				<SpriteIcon id="link" />
				{embedLink !== "" && (
					<ShareLink>
						<LinkItem>
							<span>{t("Share.Link")}:</span>
							<div>
								<input type="text" value={getShareLink()} readOnly />
								<CopyBtn
									title={t("Share.CopyClipboard")}
									data-testid="share-link"
									onClick={() => {
										copyLink();
										pushSocialShareEvent({
											shareDestination: "clipboard",
											type: getShareEventType(),
										});
									}}
								>
									<SpriteIcon id="copy" />
								</CopyBtn>
							</div>
						</LinkItem>

						<LinkItem>
							<span>{t("Share.Embed")}:</span>
							<div>
								<input type="text" value={embedLink} readOnly />
								<CopyBtn
									title={t("Share.CopyClipboard")}
									onClick={() => {
										copyLink(true);
										pushSocialShareEvent({
											shareDestination: "clipboard",
											type: getShareEventType(),
										});
									}}
								>
									<SpriteIcon id="copy" />
								</CopyBtn>
							</div>
						</LinkItem>
					</ShareLink>
				)}
			</Item>
		</Wrapper>
	);
};

export default Share;
