import { GenreMenuItem, HeaderMenuItem } from "@models/constants";
import { GenreDropdownMenu } from "./HeaderLink.style";
import Link from "next/link";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { useQuery } from "@tanstack/react-query";
import { ONE_DAY_SECONDS } from "@lib/constants";
import { getGenresQuery } from "@lib/network/genres";
import { useSessionContext } from "@lib/context/session";
import { Genre } from "@models/release";
import { prepareGenreColumns } from "@lib/utils/genre";

export const GenreSubMenu = () => {
	const { getAccessToken, getIsSessionValid } = useSessionContext();
	const accessToken = getAccessToken();
	const isSessionValid = getIsSessionValid();

	// cache genres for 1 day since they rarely change
	const genres = useQuery({
		...getGenresQuery(accessToken),
		staleTime: ONE_DAY_SECONDS,
		cacheTime: ONE_DAY_SECONDS,
		enabled: isSessionValid,
	});

	const genreColumns = prepareGenreColumns(genres?.data);

	return (
		<GenreDropdownMenu>
			<div>
				{genreColumns.map((genreColumn, genreColumnIndex) => {
					const genreColumnItems = genreColumn.map((genreItem: Genre) => {
						return (
							<li key={`${genreItem.id}`}>
								<Link
									href={`/genre/${genreItem.slug}/${genreItem.id}`}
									prefetch={false}
									data-testid={`header-subnav-link-genre${genreItem.id}`}
								>
									{genreItem.name}
								</Link>
							</li>
						);
					});

					return (
						<div key={`${genreColumnIndex}`}>
							<ul className="dropdown_menu">{genreColumnItems}</ul>
						</div>
					);
				})}
			</div>
		</GenreDropdownMenu>
	);
};

export const HeaderLinkLabel = (props: {
	label: string;
	subMenu?: GenreMenuItem[];
	newWindow?: boolean;
}) => {
	const { label, subMenu, newWindow } = props;

	if (subMenu) {
		return (
			<span className="rotatable">
				{label}
				<SpriteIcon id="drop-down" />
			</span>
		);
	}
	if (newWindow) {
		return (
			<span>
				{label}
				<SpriteIcon id="menu-arrow" />
			</span>
		);
	}
	return <span>{label}</span>;
};

const HeaderLink = (props: { item: HeaderMenuItem }) => {
	const { item } = props;

	if (item.href) {
		if (item.newWindow === true) {
			return (
				<li className="header_item">
					<a
						target="_blank"
						href={item.href}
						rel="noopener noreferrer"
						data-testid={item.cypressTag}
					>
						<HeaderLinkLabel
							label={item.label}
							subMenu={item.subMenu}
							newWindow={true}
						/>
					</a>
				</li>
			);
		} else {
			return (
				<li className="header_item">
					<Link
						href={item.href}
						prefetch={false}
						data-testid={item.cypressTag}
					>
						<HeaderLinkLabel label={item.label} subMenu={item.subMenu} />
					</Link>
				</li>
			);
		}
	}
	return (
		<li className="header_item">
			<HeaderLinkLabel label={item.label} subMenu={item.subMenu} />
			{item.subMenu !== undefined && item.label === "Genres" && (
				<GenreSubMenu />
			)}
		</li>
	);
};

export default HeaderLink;
