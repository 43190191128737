import { QUEUE_LOCAL_STORAGE_KEY } from "@lib/constants/storage-keys";
import { Track } from "@models/track";
import React from "react";
import {
	ADD_TRACK,
	ADD_TRACKS,
	ActionTypes,
	CLEAR_QUEUE,
	CLEAR_VIRTUAL_QUEUE,
	PLAY_TRACK,
	PlayerActions,
	SELECT_TRACK,
	SET_PLAY,
	SET_PLAYED_HISTORY,
	SET_QUEUE,
	SET_VIRTUAL_QUEUE,
} from "./types";
import { makePlayerId } from "./util";

const isTrackDisabled = (track: Track) => {
	if (!track) return false;
	return track.enabled === false || (track.publish_status && track.publish_status !== "published");
};

const filterOutDisabledTracks = (tracks: Track[]) => {
	return tracks.filter((track) => !isTrackDisabled(track));
};

export const createActions = (
	dispatch: React.Dispatch<ActionTypes>,
): PlayerActions => {
	return {
		clearQueue: (): void => {
			localStorage.removeItem(QUEUE_LOCAL_STORAGE_KEY);
			dispatch({
				type: CLEAR_QUEUE,
			});
		},
		setQueue: (tracks: Track[]): void => {
			const t = filterOutDisabledTracks(tracks).map(makePlayerId);
			dispatch({
				type: SET_QUEUE,
				payload: {
					tracks: t,
				},
			});
		},
		clearVirtualQueue: (): void => {
			dispatch({
				type: CLEAR_VIRTUAL_QUEUE,
			});
		},
		setVirtualQueue: (tracks: Track[]): Track[] => {
			const t = filterOutDisabledTracks(tracks).map(makePlayerId);
			dispatch({
				type: SET_VIRTUAL_QUEUE,
				payload: {
					tracks: t,
				},
			});

			return t;
		},
		selectTrack: (track: Track): void => {
			const t = makePlayerId(track);
			dispatch({
				type: SELECT_TRACK,
				payload: {
					track: t,
				},
			});
		},
		addTrack: (track: Track): void => {
			const t = makePlayerId(track);
			dispatch({
				type: ADD_TRACK,
				payload: {
					track: t,
				},
			});
		},
		addTracks: (tracks: Track[]): void => {
			const t = filterOutDisabledTracks(tracks).map(makePlayerId);
			dispatch({
				type: ADD_TRACKS,
				payload: {
					tracks: t,
				},
			});
		},
		playTrack: (track: Track): void => {
			dispatch({
				type: PLAY_TRACK,
				payload: {
					track: track,
				},
			});
		},
		setPlay: (shouldPlay: boolean): void => {
			dispatch({
				type: SET_PLAY,
				payload: {
					shouldPlay: shouldPlay,
				},
			});
		},
		setPlayedHistory: (trackIds: []): void => {
			dispatch({
				type: SET_PLAYED_HISTORY,
				payload: {
					previouslyPlayedTracks: trackIds,
				},
			});
		},
	};
};
