import styled from "styled-components";

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 64px;
  gap: 0;

  &.embedded {
    width: 48px;
  }

  div.row {
    display: flex;
    flex-direction: row;

    span {
      width: 50% !important;
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .scrollable {
    max-height: 500px;
    position: relative;
    overflow: auto;
  }

  .list-wrapper {
    width: 100%;
  }

  .fade {
    opacity: 0.25;
    transition: opacity 0.2s ease-in-out;
  }

  .item-row {
    justify-content: flex-start;
    height: 64px;
    margin: 0 0 4px;

    &:hover .fade {
      opacity: 1;
    }

    .cell {
      font-family: ${(props) => props.theme.fonts.aeonik};
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.005em;

      color: ${(props) => props.theme.colors.neutrals.primary.graytext};

      display: flex;
      flex-direction: column;
    }

    .artwork {
      padding: 0;
      margin-right: 8px;
    }

    .controls {
      width: 32px;
    }

    .title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      .name {
        width: 100%;
        color: ${(props) => props.theme.colors.neutrals.primary.white};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: block;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .count {
      }
    }

    .genre-cell {
      width: 240px;
      min-width: 240px;
      .genre {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .length {
      }
    }

    .key-cell {
      width: 240px;
      min-width: 240px;
      .key {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .date-cell {
      width: 120px;
      min-width: 120px;
      .date {
      }
    }

    .actions {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    @media ${(props) => props.theme.device.xsm} {
      .genre-cell,
      .key-cell,
      .date-cell {
        display: none;
      }
    }

    @media ${(props) => props.theme.device.md} {
      .date-cell {
        display: flex;
      }
    }

    @media ${(props) => props.theme.device.lg} {
      .genre-cell,
      .key-cell {
        display: flex;
        width: 200px;
        min-width: 200px;
      }
    }

    @media ${(props) => props.theme.device.xl} {
      .genre-cell,
      .key-cell {
        width: 220px;
        min-width: 220px;
      }
    }

    &.embedded {
      height: 48px;
      margin: 0 0 2px;

      .title {
        width: 100%;
        .name {
          width: 220px;
          @media ${(props) => props.theme.device.lg} {
            width: 130px;
          }
        }

        .count {
        }
      }

      .genre-cell {
        display: none;
        width: 190px;
        @media ${(props) => props.theme.device.lg} {
          display: block;
        }

        .genre {
          display: block;
        }
      }

      .key-cell {
        display: none;
        width: 190px;
        @media ${(props) => props.theme.device.lg} {
          display: block;
        }

        .key {
          display: block;
        }
      }

      .date-cell {
        display: none;
        @media ${(props) => props.theme.device.lg} {
          display: block;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
