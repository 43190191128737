import styled from "styled-components";

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: underline;
  }
`;

export const ErrorImage = styled.img`
  width: 120px;
  height: 111px;
  margin-bottom: 30px;
`;

export const ErrorTitle = styled.h1`
  font-size: 30px;
  font-weight: 700;
`;

export const ErrorDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
  white-space: pre-line;
  text-align: center;
`;

export const ErrorButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > button:not(:first-child) {
    margin-top: 20px;
  }
`;
