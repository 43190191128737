import { PLAYER_QUEUE_MAX_SIZE } from "@lib/constants/player";
import { Track } from "@models/track";

/**
 * Generate a random string of a given length
 * @param length number
 * @returns string
 */
export function makeId(length: number): string {
	let result = "";
	const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

/**
 * Add a player_id to a track if it doesn't already have one
 * @param track Track
 * @returns Track
 * @example
 * const track = makePlayerId({ id: 1, title: "test" });
 */
export function makePlayerId(track: Track): Track {
	if (track.player_id) return track;

	return {
		...track,
		player_id: `player_id-${track.isrc || track.id}`, // A record of the track in the queue - The same track can be added multiple times with the same player_id
		unique_player_id: makeId(10), // A record of the track in the queue - The same track can be added multiple times with a different unique_player_id
	};
}

/**
 * Limit the queue size to a max size
 * @param queue Track[]
 * @returns Track[]
 */
export function limitQueueSize(queue: Track[]): Track[] {
	if (queue.length <= PLAYER_QUEUE_MAX_SIZE) {
		return queue;
	}

	return queue.slice(queue.length - PLAYER_QUEUE_MAX_SIZE);
}
