import { theme } from "@styles/theme";
import { HTMLAttributes } from "react";

/*
	Static icons for the Beatport store follow the "SVG Sprite" pattern.
	https://benadam.me/thoughts/react-svg-sprites/

	(Note: Icons that need computed properties, like theme colors & strokes, should
		still follow the inline svg component pattern. See ThemedIcons.tsx)

	The icon vectors are stored in a regular svg file in /public/icons/sprite.svg.
	This simple component renders the correct sprite by just referencing the
	id tag from the sprite file.

	The default icon size is 16x16. Width and Height props can be passed in to
	override the defaults, and any other SVG property tag can be set on the component
	as well to allow for flexible control of the svg render.
*/
interface SpriteIconProps extends HTMLAttributes<SVGElement> {
	"width"?: string;
	"height"?: string;
	"fill"?: string;
	"stroke"?: string;
	"disabled"?: boolean;
	"desc"?: string; // SVG <desc> tag
	"data-testid"?: string;
}

export const SpriteIcon = ({
	id,
	fill = theme.colors.neutrals.primary.graytext,
	stroke,
	"data-testid": testId,
	...props
}: SpriteIconProps) => (
	<svg {...props} stroke={stroke} fill={fill} width={props.width || "16"} height={props.height || "16"} data-testid={testId || `icon-${id}`}>
		<use href={`/icons/sprite.svg#${id}`} />
	</svg>
);
