import { createContext, ReactElement, useState } from "react";

interface MyBeatportContextValue {
	artistsLoading: boolean;
	setArtistsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	labelsLoading: boolean;
	setLabelsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MyBeatportContextDefaultValue = {
	artistsLoading: false,
	setArtistsLoading: () => {},
	labelsLoading: false,
	setLabelsLoading: () => {},
};

export const MyBeatportContext = createContext<MyBeatportContextValue>(MyBeatportContextDefaultValue);

export type MyBeatportProviderProps = {
	children: React.ReactNode;
};

export const MyBeatportProvider: React.FC<MyBeatportProviderProps> = ({
	children,
}: MyBeatportProviderProps): ReactElement => {
	const [artistsLoading, setArtistsLoading] = useState<boolean>(false);
	const [labelsLoading, setLabelsLoading] = useState<boolean>(false);
	return (
		<MyBeatportContext.Provider value={{ artistsLoading, setArtistsLoading, labelsLoading, setLabelsLoading }}>
			{children}
		</MyBeatportContext.Provider>
	);
};
