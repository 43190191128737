import localFont from "next/font/local";

export const aeonikFont = localFont({
	src: [
		{
			path: "../assets/Aeonik/Aeonik-Regular.ttf",
			weight: "400",
			style: "normal",
		},
		{
			path: "../assets/Aeonik/Aeonik-Medium.ttf",
			weight: "500",
			style: "normal",
		},
		{
			path: "../assets/Aeonik/Aeonik-Bold.ttf",
			weight: "700",
			style: "normal",
		},
	],
	display: "swap",
	variable: "--font-aeonik",
});

export const size = {
	xsm: "390px",
	sm: "580px",
	md: "768px",
	lg: "1024px",
	xl: "1200px",
	xxl: "1440px",
};

export const screenSizes = Object.fromEntries(
	Object.entries(size).map(([key, value]) => [key, Number(value.replace("px", ""))]),
);

export const device = {
	xsm: `(min-width: ${size.xsm})`,
	sm: `(min-width: ${size.sm})`,
	md: `(min-width: ${size.md})`,
	lg: `(min-width: ${size.lg})`,
	xl: `(min-width: ${size.xl})`,
	xxl: `(min-width: ${size.xxl})`,
};

export const colors = {
	green: {
		50: "#E3FFEE",
		100: "#BAFFD4",
		200: "#84FFB7",
		300: {
			accent: "#01FF95",
			stroke: "#00E586",
		},
		400: "#00FB78",
		500: "#00F562",
		600: "#00E356",
		700: "#00CE48",
		800: "#00BB3C",
		900: "#009925",
		1: "#01FF95",
		2: "#00E586",
	},
	pink: {
		50: "#FEE3EF",
		100: "#FDB9D7",
		200: "#FE89BC",
		300: {
			accent: "#FF53A0",
			stroke: "#d64988",
		},
		400: "#FF1189",
		500: "#FE0071",
		600: "#EC006E",
		700: "#D50069",
		800: "#BF0065",
		900: "#97005E",
	},
	blue: {
		50: "#E2F7FC",
		100: "#B6ECF7",
		200: "#88E0F2",
		300: "#5CD3EB",
		400: "#43C9E4",
		500: {
			accent: "#39C0DE",
		},
		600: "#34B0CA",
		700: "#2D9BB0",
		800: "#288797",
		900: "#1E646B",
	},
	orange: "#FF9800",
	yellow: {
		300: {
			accent: "#E58900",
			stroke: "#CC7A00",
		},
	},
	purple: {
		300: {
			accent: "#9654FA",
		},
	},
	black: "#000000",
	neutrals: {
		primary: {
			white: "#FFFFFF",
			graytext: "#8C8C8C",
			graytextFade: "#8C8C8C88",
			600: "#343434",
			700: "#282828",
			750: "#2D2D2D",
			800: "#262626",
			900: "#141414",
		},

		secondary: {
			gray: {
				stroke: "#383838",
				divider1: "#333333",
				divider2: "#5A5A5A",
				indicator1: "#5C5C5C",
				indicator2: "#B3B3B3",
				strple: "#D0D7DE",
				sectionlight: "#E6E6E6",
				active: "#494949",
			},
		},
	},
	legacy: {
		greybtn1: "#696969",
		greybtn2: "#585858",
		pinkbtn1: "#D64988",
	},
	semantic: {
		danger: "#CF222E",
		warn: "#F1B02E",
	},
	gray: {
		9: "#4D4D4D",
	},
	white: {
		1: "#ffffff",
	},
};

export const boxShadows = {
	green: {
		1: "0px 0px 0px 3px rgba(1, 255, 149, 0.15)",
	},

	danger: {
		1: "0px 90px 9x 3px rgba(207, 34, 46, 1)",
	},
};

export const fontWeights = {
	normal: "400",
	medium: "500",
	bold: "700",
};

export const fontSizes = [
	{ size: "10px", lineHeight: "16px", letterSpacing: "-0.009em" },
	{ size: "11px", lineHeight: "16px", letterSpacing: "-0.009em" },
	{ size: "12px", lineHeight: "16px", letterSpacing: "-0.009em" },
	{ size: "13px", lineHeight: "20px", letterSpacing: "-0.009em" },
	{ size: "14px", lineHeight: "20px", letterSpacing: "-0.009em" },
	{ size: "15px", lineHeight: "20px", letterSpacing: "-0.009em" },
	{ size: "16px", lineHeight: "24px", letterSpacing: "-0.009em" },
	{ size: "18px", lineHeight: "24px", letterSpacing: "-0.009em" },
	{ size: "20px", lineHeight: "32px", letterSpacing: "-0.009em" },
	{ size: "30px", lineHeight: "40px", letterSpacing: "-0.009em" },
];

export const fonts = {
	aeonik: aeonikFont.style.fontFamily,
};

export const theme = {
	size,
	device,
	colors,
	boxShadows,
	fontWeights,
	fontSizes,
	fonts,
};
