import { API_BASES } from "@lib/constants";
import { getConfig } from "config";
import { getRequest, postRequest } from "./request";

const config = getConfig();

export const postReDownloadEvent = async (
	params: { track_ids: number },
	accessToken: string,
) => {
	const url = "events/redownload/";
	return postRequest({
		url,
		data: { track_ids: params.track_ids },
		apiBase: API_BASES.api4,
		accessToken,
	});
};

export const getDownloadLink = async (
	params: { order_item_download_id: number },
	accessToken: string,
) => {
	const url = "catalog/tracks/purchase-download/";
	return getRequest({
		url,
		params,
		apiBase: API_BASES.api4,
		accessToken,
	});
};

export const tokenizeZip = async (
	params: { data: { url: string }[] },
	accessToken: string,
) => {
	const url = `${config.ZIPPER_URL}/v1/token`;
	return postRequest({
		url,
		accessToken,
		data: params.data,
	});
};
