import styled, { css } from "styled-components";

/*
  These styles are shared among ReleasesList, TracksList, & TrackListItems.

  If you need to modify a shared style, ensure you want to make the same modification
  to all the shared components. Otherwise, override the style in the specific style
  file for the component.
*/

const TrimmedArtwork = css`
  object-fit: cover;
  object-position: left center;
  width: 50px;
`;

export const Artwork = styled.div<{ $withTrim?: boolean }>`
  height: 80px;
  width: 80px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    left: 0;
    height: 80px;
    width: 80px;
    ${({ $withTrim }) => $withTrim && TrimmedArtwork}
  }
`;

export const CartMeta = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
`;

export const Exclusive = styled.span`
  left: -33px;
  top: 33px;
  height: 14px;
  padding: 0 2px;
  font-size: 7px;
  background: ${(props) => props.theme.colors.green[300].accent};
  width: 100%;
  color: #000;
  position: absolute;
  text-align: center;
  box-sizing: border-box;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  transform: rotate(-90deg);
`;

export const ItemControls = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 80px;
  width: 40px;
  overflow: hidden;
  transition: width 0.2s ease-in-out;
  color: ${(props) => props.theme.colors.neutrals.primary.white};
`;

export const MetaRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a:hover {
    text-decoration: underline;
  }
`;

export const MoreButton = styled.a`
  cursor: pointer;
`;
