import React, { FormEvent, useEffect, useState } from "react";
import { Validator, ValidatorFn, validationMethods } from "../Validators";
import { Description, ErrorMessage, InputWrapper } from "./TextInput.style";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	id: string;
	name: string;
	type: "email" | "number" | "password" | "search" | "tel" | "text" | "url";
	placeholder?: string;
	errors?: string[];
	description?: string;
	validators?: (Validator | ValidatorFn)[];
	inputRef?: React.RefObject<HTMLInputElement>;
	className?: string;
}

const TextInput = React.memo<TextInputProps>(
	({
		id,
		name,
		type,
		placeholder,
		errors,
		description,
		validators,
		inputRef,
		className,
		...rest
	}) => {
		const [validationMessages, setValidationMessages] = useState<string[]>(
			errors ? errors : [],
		);

		useEffect(() => {
			if (errors) {
				setValidationMessages([...errors]);
			} else if (validationMessages.length > 0 && !errors) {
				setValidationMessages([]);
			}
		}, [errors]);

		const validate = (target: HTMLInputElement) => {
			if (validators) {
				target.removeAttribute("invalid");
				target.setCustomValidity("");
				setValidationMessages([]);
				validators.every(async (validator) => {
					let func: ValidatorFn = async () => ({
						valid: true,
						message: "",
					});
					switch (typeof validator) {
						case "function":
							func = validator;
							break;
						case "string":
							func = validationMethods[validator];
							break;
						default:
							return;
					}
					const validation = await func(target.name, target.value);
					if (!validation.valid) {
						target.setAttribute("invalid", "invalid");
						target.setCustomValidity(validation.message);
						setValidationMessages([validation.message]);
					}
				});
			}
			if (validationMessages.length == 0 && target.validationMessage) {
				target.setAttribute("invalid", "invalid");
				setValidationMessages([target.validationMessage]);
			}
		};

		const onInvalid = (e: FormEvent) => {
			const target = e.target as HTMLInputElement;
			setValidationMessages([...validationMessages, target.validationMessage]);
		};

		const onFocus = (e: FormEvent) => {
			const target = e.target as HTMLInputElement;
			target.removeAttribute("data-focus");
		};

		const onBlur = (e: FormEvent) => {
			const target = e.target as HTMLInputElement;
			validate(target);
		};

		return (
			<InputWrapper className={className}>
				<input
					id={id}
					name={name}
					type={type}
					placeholder={placeholder}
					onInvalid={onInvalid}
					onFocus={onFocus}
					onBlur={onBlur}
					ref={inputRef}
					{...rest}
					data-focus={!!validators}
				/>
				{validationMessages.length > 0 && validationMessages[0] && (
					<ErrorMessage>{validationMessages[0]}</ErrorMessage>
				)}
				{description && <Description>{description}</Description>}
			</InputWrapper>
		);
	},
);

TextInput.displayName = "TextInput";

export default TextInput;
export type { TextInputProps };
