import { BEATPORT_CUSTOMER_SUPPORT_URL } from "@lib/constants/urls";
import Link from "next/link";

const ERROR_TYPES = {
	maintenance: {
		image: "/images/general-error.png",
		title: "We're upgrading the booth.",
		description: "Beatport is currently performing updates, we'll be back soon!",
		buttons: [
			"service-status",
		],
	},
	general: {
		image: "/images/general-error.png",
		title: "Something went wrong.",
		description: <>It&apos;s not you, it&apos;s on us. Please try again, or <Link href={BEATPORT_CUSTOMER_SUPPORT_URL}>contact support</Link> for help.</>,
		buttons: [
			"try-again",
			"service-status",
		],
	},
	404: {
		image: "/images/error.png",
		title: "404 Error",
		description: "We can't find the page you are looking for.",
		buttons: [
			"go-home",
		],
	},
	500: {
		image: "/images/error.png",
		title: "500 Error",
		description: "We're experiencing an internal server problem.\nPlease try again later.",
		buttons: [
			"go-home",
			"service-status",
		],
	},
};

export default ERROR_TYPES;
