import styled from "styled-components";

export const Item = styled.div`
  width: 100%;
  flex-direction: row;
  background: #141414;
  padding: 0 0.5rem 0 0;
  border-radius: 0.25rem;
  display: flex;

  span.icon {
    padding: 1rem;
    border-radius: 0.25rem 0 0 0.25rem;

    svg {
      path {
        fill: black;
      }
    }

    &.success {
      background: ${(props) => props.theme.colors.green[300].accent};
    }

    &.error {
      background: ${(props) => props.theme.colors.semantic.danger};
    }

    &.neutral {
      background: ${(props) => props.theme.colors.neutrals.secondary.gray.indicator2};
    }
  }

  span.msg {
    padding: 1rem;
    width: 100%;

    font-family: ${(props) => props.theme.fonts.aeonik};
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: -0.006em;

    color: #ffffff;
  }

  span.close {
    padding: 1rem;

    svg {
      cursor: pointer;
    }
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 3.75rem;
  left: calc(100% - 370px);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 999;

  @media ${(props) => props.theme.device.sm} {
    left: calc(100% - 385px);
  }

  @media ${(props) => props.theme.device.md} {
    left: calc(100% - 385px);
  }

  @media ${(props) => props.theme.device.xl} {
    left: calc(100% - 385px);
  }

  @media ${(props) => props.theme.device.xxl} {
    left: initial;
    right: calc((100% - 1371px) / 2);
  }
`;
