import {
	ActionTypes,
	ADD_TRACKS_TO_DOWNLOAD,
	ADD_TRACKS_TO_PLAYLIST,
	CollectionState,
	SET_DOWNLOAD_QUEUE,
	SET_PLAYLIST_QUEUE,
} from "./types";

export const collectionReducer = (
	state: CollectionState,
	action: ActionTypes,
): CollectionState => {
	switch (action.type) {
		case SET_PLAYLIST_QUEUE:
			return {
				...state,
				playlist_queue: [...action.payload.tracks],
			};
		case SET_DOWNLOAD_QUEUE:
			return {
				...state,
				download_queue: [...action.payload.tracks],
			};
		case ADD_TRACKS_TO_PLAYLIST:
			return {
				...state,
				playlist_queue: [...action.payload.tracks],
			};
		case ADD_TRACKS_TO_DOWNLOAD:
			return {
				...state,
				download_queue: [...state.download_queue, action.payload.track],
			};

		default:
			return state;
	}
};
