import styled from "styled-components";

export const ButtonStyled = styled.button<{ $playerHeight?: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  bottom: calc(${({ $playerHeight }) => $playerHeight}px + 1rem);
  right: 1rem;
  height: 2.5rem;
  padding: 0 1rem;
  font-weight: 500;
  background: ${(props) => props.theme.colors.blue[500].accent};
  border-radius: 2rem;
  color: ${(props) => props.theme.colors.neutrals.primary[900]};
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  z-index: 3;
  max-width: 2.5rem;

  > svg {
    min-width: 1rem;
    min-height: 1rem;
    margin-left: -0.2rem;
    margin-right: 0.5rem;
  }

  > span {
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
  }
  
  @media ${(props) => props.theme.device.lg} {
    bottom: 6rem;

    &:hover {
      max-width: 15rem;

      > span {
        opacity: 1;
      }
    }
  }
`;
