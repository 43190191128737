import styled from "styled-components";

export const Message = styled.div`
  padding-bottom: 8px;
`;

export const LoaderWrapper = styled.span`
  padding: 0 0px;
  width: 23px;
  height: 23px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 8px;
`;

export const Wrapper = styled.div`
  //width: 900px;

  ul {
    max-height: 400px;
    overflow-y: auto;
    position: relative;
  }

  ul li span {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 8px;
    color: white;
  }
`;
