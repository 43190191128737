import { CartDetails } from "@models/Cart";

export const getErrorMessage = (errorObj: any) => {
	const errorKeys = Object.keys(errorObj);
	const errVals = Object.values(errorObj);

	const errorKey = errorKeys[0];
	switch (errorKey) {
		case "ItemLimit":
		case "InvalidLosslessUpgrade":
		case "AlbumOnly":
		case "MixOnly":
		case "NoPrice":
		case "ReleaseExistsForTrack":
			return errorObj[errorKey];
		case "AlreadyPurchased":
			return `${errorObj[errorKey].name} ${errorObj[errorKey].mix_name} already Purchased`;
		case "AlreadyInCart":
			return "This item is already in your cart";
		default:
			if (errVals.length > 0) {
				return errVals[0] as string;
			}
			return "Unknown Error";
	}
};

export const getCartDetails = (
	carts: CartDetails[],
	cartId: number,
): CartDetails | null => {
	const currentCart = carts.filter((c) => c.id === cartId);
	if (currentCart && currentCart.length > 0) {
		return currentCart[0];
	}
	return null;
};

export const couponNotifications = (code: string) => {
	const states: Record<string, { category: string; content: string }> = {
		invalid: {
			category: "error",
			content: "We had a problem processing that coupon code",
		},
		gift_card: {
			category: "success",
			content: "Your gift card has been credited to your account",
		},

		PERCENT: {
			category: "success",
			content: "Your Discount coupon has been applied to your account",
		},
		already_used: {
			category: "warning",
			content:
        "It looks like you've already used that coupon code the max number of times",
		},
		expired: {
			category: "warning",
			content: "That coupon is expired",
		},
		min_amt: {
			category: "error",
			content: "The minimum order amount was not reached for this coupon.",
		},
	};

	if (code in states) {
		return states[code];
	}
	return undefined;
};
