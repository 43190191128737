import Carts from "@components/pages/addtotabs/Carts/Carts";
import Collections from "@components/pages/addtotabs/Collections/Collections";
import Search from "@components/pages/addtotabs/Search/Search";
import { useState } from "react";
import { Tab, TabContent, TabWrapper, Wrapper } from "./AddTracksModal.style";

interface Props {
	chartId: number;
}

enum Tabs {
	COLLECTION = "Collection",
	CARTS = "Carts",
	SEARCH = "Search",
}

const AddTracksModal = (props: Props) => {
	const { chartId } = props;
	const [selectedTab, setSelectedTab] = useState(Tabs.COLLECTION);

	const renderTabContent = () => {
		if (selectedTab === Tabs.COLLECTION) {
			return <Collections chartId={chartId} />;
		}
		if (selectedTab === Tabs.CARTS) {
			return <Carts chartId={chartId} />;
		}
		if (selectedTab === Tabs.SEARCH) {
			return <Search chartId={chartId} />;
		}
		return <></>;
	};

	const tabs = Object.values(Tabs);
	return (
		<Wrapper>
			<TabWrapper>
				{tabs.map((tabName, tabIndex) => (
					<Tab
						key={`tab-${tabIndex}`}
						onClick={() => setSelectedTab(tabName)}
						className={selectedTab === tabName ? "selected" : ""}
					>
						{tabName}
					</Tab>
				))}
			</TabWrapper>
			<TabContent>{renderTabContent()}</TabContent>
		</Wrapper>
	);
};

export default AddTracksModal;
