import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { postMessage } from "@components/notifications";
import { usePlayerActions } from "@lib/context/player";
import { useSessionContext } from "@lib/context/session";
import logger from "@lib/logger";
import { getCatalogPlaylistTracksRequest, getMyPlaylistTracksRequest } from "@lib/network/playlists";
import {
	getChartTracksRequest,
	getReleaseTracksRequest,
	getTrackQuery,
} from "@lib/network/tracks";
import { Playlist } from "@models/Playlists";
import { Track } from "@models/track";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import { Tooltip } from "../Tooltip";
import { Control } from "./AddToQueue.style";

interface Props {
	useTooltip?: boolean;
	disabled?: boolean;
	tracks?: Track[];
	releaseId?: number;
	chartId?: number;
	playlist?: Playlist;
	isMyPlaylist?: boolean;
}

export const PLAYER_QUEUE_LIMIT = 1000;
const DEFAULT_PER_PAGE = 100;

const AddToQueue: React.FC<Props> = ({
	useTooltip = true,
	disabled = false,
	tracks,
	releaseId,
	chartId,
	playlist,
	isMyPlaylist = false,
}) => {
	const { addTracks } = usePlayerActions();
	const { getAccessToken } = useSessionContext();
	const { t } = useTranslation("translation");
	const queryClient = useQueryClient();
	const accessToken = getAccessToken();

	const getTrackData = async (track: Track) => {
		try {
			const trackData = await queryClient.fetchQuery<Track>(getTrackQuery({ id: track.id, accessToken }));
			return trackData;
		} catch (err) {
			logger.error(err, "Error fetching Track");
			return;
		}
	};

	const handleOnClick = async () => {
		if (tracks && tracks.length > 0) {
			if (!tracks[0].sample_url) {
				const data = await Promise.all(tracks.map((track) => getTrackData(track)));
				if (data?.length > 0 && data[0]) {
					addTracks(data as Track[]);
				} else {
					postMessage({
						type: "error",
						message: "Unable to add to queue",
					});
					return;
				}
			} else {
				addTracks(tracks);
			}
			postMessage({
				type: "success",
				message:
					tracks.length > 1 ? `${t("Tracks")} ${t("Queue.Added")}` : `${t("Track")} ${t("Queue.Added")}`,
			});
		}

		if (releaseId) {
			const { data } = await getReleaseTracksRequest({
				releaseId,
				params: {
					per_page: PLAYER_QUEUE_LIMIT,
				},
				accessToken,
			});

			if (data && data.results && data.results.length > 0) {
				addTracks(data.results);
				postMessage({
					type: "success",
					message: `${t("Release")} ${t("Tracks")} ${t("Queue.Added")}`,
				});
			}
		}

		if (chartId) {
			const { data } = await getChartTracksRequest({
				params: { per_page: DEFAULT_PER_PAGE },
				accessToken,
				chartId,
			});

			if (data && data.results && data.results.length > 0) {
				addTracks(data.results);
				postMessage({
					type: "success",
					message: `${t("Chart")} ${t("Tracks")} ${t("Queue.Added")}`,
				});
			}
		}
		if (playlist) {
			const getPlaylistTracksRequest = isMyPlaylist ? getMyPlaylistTracksRequest : getCatalogPlaylistTracksRequest;
			const { data } = await getPlaylistTracksRequest({
				id: playlist.id,
				params: { page: 1, per_page: playlist.track_count || DEFAULT_PER_PAGE },
				accessToken,
			});
			if (data && data.results && data.results.length > 0) {
				const playlistTracks = data.results.map((track: any) => track.track);
				addTracks(playlistTracks);
				postMessage({
					type: "success",
					message: `${t("Playlist")} ${t("Tracks")} ${t("Queue.Added")}`,
				});
			}
		}
	};

	const control = () => (
		<Control onClick={handleOnClick} disabled={disabled} data-testid="add-to-queue-button">
			<SpriteIcon id="queue-add" width="16" height="15" />
		</Control>
	);

	return useTooltip && !disabled ?
			(
				<Tooltip text={t("Queue.Add")}>{control()}</Tooltip>
			) :
			(
				control()
			);
};

export default AddToQueue;
