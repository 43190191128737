import { createContext } from "react";
import {
	ActionTypes,
	LiveStream,
	SET_ALL_STAGES,
	SET_CURRENT_STAGE,
	SET_CURRENT_STREAM,
	SET_IS_EXPANDED,
	Stage,
} from "./types";

// Define action creators
export const setCurrentStage = (stage: Stage) => ({
	type: SET_CURRENT_STAGE,
	payload: stage,
});

export const setAllStages = (stages: Stage[]) => ({
	type: SET_ALL_STAGES,
	payload: stages,
});

export const setIsExpanded = (isExpanded: boolean) => ({
	type: SET_IS_EXPANDED,
	payload: isExpanded,
});

export const setCurrentStream = (currentStream: LiveStream) => ({
	type: SET_CURRENT_STREAM,
	payload: currentStream,
});

export const TwitchLiveStreamActionsContext = createContext<{ actions: ActionTypes }>({
	actions: {
		setCurrentStage: () => {},
		setAllStages: () => {},
		setIsExpanded: () => {},
		setCurrentStream: () => {},
	},
});
