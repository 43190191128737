import React, { FormEvent, useState } from "react";
import { Validator, ValidatorFn, validationMethods } from "../Validators";
import { ErrorMessage, InputWrapper, Label } from "./CheckBox.style";

interface TextInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "label"> {
	id: string;
	name: string;
	label: string | React.ReactNode;
	errorText?: string;
	validators?: (Validator | ValidatorFn)[];
	inputRef?: React.RefObject<HTMLInputElement>;
	updateGroupItem?: () => void;
}

const CheckBox = React.memo<TextInputProps>(
	({
		id,
		name,
		label,
		errorText,
		validators,
		inputRef,
		updateGroupItem,
		...rest
	}) => {
		const [validationMessages, setValidationMessages] = useState<string[]>([]);

		const onInvalid = (e: FormEvent) => {
			const target = e.target as HTMLInputElement;
			setValidationMessages((m) => [...m, target.validationMessage]);
		};

		const onBlur = (e: FormEvent) => {
			const target = e.target as HTMLInputElement;

			if (validators) {
				setValidationMessages([]);
				validators.every(async (validator) => {
					let func: ValidatorFn = async () => ({
						valid: true,
						message: "",
					});
					switch (typeof validator) {
						case "function":
							func = validator;
							break;
						case "string":
							func = validationMethods[validator];
							break;
						default:
							return;
					}
					const validation = await func(target.name, target.value);
					if (!validation.valid) {
						setValidationMessages((m) => [...m, validation.message]);
					}
				});
			}

			if (validationMessages.length === 0) {
				setValidationMessages((m) => [...m, target.validationMessage]);
			}
		};

		return (
			<InputWrapper>
				<input
					id={id}
					name={name}
					type="checkbox"
					onInvalid={onInvalid}
					onBlur={onBlur}
					ref={inputRef}
					{...rest}
				/>
				<span>
					<Label
						htmlFor={name}
						onClick={() => {
							if (updateGroupItem) {
								updateGroupItem();
							}
						}}
					>
						{label}
					</Label>
				</span>
				{validationMessages.length > 0 && (
					<ErrorMessage>{errorText || validationMessages[0]}</ErrorMessage>
				)}
			</InputWrapper>
		);
	},
);

CheckBox.displayName = "CheckBox";

export default CheckBox;
export type { TextInputProps };
