import styled from "styled-components";

export const Control = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  &:hover svg {
    filter: drop-shadow(0 0 7px #fff);
  }
  &:disabled svg {
    filter: unset;
  }
`;
