import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import debounce from "lodash/debounce";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import { HeaderSearch, HeaderSearchWrapper } from "@components/layouts/header/Header.style";
import usePrevious from "@lib/hooks/usePrevious";
import { SearchInput } from "./Search.style";

interface Props {
	showClearIcon?: boolean;
	setShowSearchBar?: any;
}

const SEARCH_DEBOUNCE_MS = 750;
const SEARCH_BOX_ID = "search-field"; // ! The ID is used to focus the element from the Search view
const SEARCH_URL = "/search";

const SearchBox = ({ showClearIcon = true, setShowSearchBar }: Props) => {
	const router = useRouter();
	const routerQuery = (router?.query?.q as string || "").trim();
	const [search, setSearch] = useState<string>("");
	const [searchFieldValue, setSearchFieldValue] = useState(routerQuery);
	const previousQuery = usePrevious(search);

	const handleSearch = (value: string) => {
		const valueTrimmed = value.trim();
		setSearch(valueTrimmed);
		const isOnSearchPage = router.route.startsWith(SEARCH_URL);
		const isSearchingSameQuery = valueTrimmed === previousQuery || valueTrimmed === routerQuery;
		const isQueryEmpty = !valueTrimmed.length;

		if (!isOnSearchPage || (!isQueryEmpty && !isSearchingSameQuery)) {
			router.push(`${SEARCH_URL}?q=${encodeURIComponent(valueTrimmed)}`);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(debounce(handleSearch, SEARCH_DEBOUNCE_MS), [routerQuery]);

	const shouldShowClearIcon = showClearIcon && searchFieldValue;

	return (
		<HeaderSearchWrapper>
			{
				setShowSearchBar && (
					<button
						onClick={() => {
							setShowSearchBar(false);
						}}
					>
						<SpriteIcon id="arrow-back" />
					</button>
				)
			}
			<HeaderSearch>
				<div>
					<SpriteIcon id="search" />
				</div>

				<SearchInput
					id={SEARCH_BOX_ID}
					name={SEARCH_BOX_ID}
					data-testid="header-search-input"
					placeholder="Search tracks, releases, artists, labels, and charts..."
					type="search"
					autoComplete="no-username"
					value={searchFieldValue}
					onChange={(e) => {
						handleSearchDebounced(e.target.value);
						setSearchFieldValue(e.target.value);
					}}
					onKeyUp={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
							handleSearch(searchFieldValue);
						}
					}}
				/>

				{shouldShowClearIcon && (
					<button
						data-clear-input
						onClick={() => {
							handleSearchDebounced.cancel();
							setSearchFieldValue("");
						}}
					>
						<SpriteIcon id="close-x" width="19" height="18" />
					</button>
				)}
			</HeaderSearch>
		</HeaderSearchWrapper>
	);
};

export default SearchBox;
