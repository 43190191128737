import styled from "styled-components";

export const PlaylistLoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  height: 48px;
  padding: 8px;
`;

export const LoaderWrapper = styled.span`
  padding: 0 35px;
  width: 93px;
  height: 23px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 8px;
`;

export const Wrapper = styled.div`
  width: 100%;

  .scrollable {
    height: calc(100vh - 350px);
  }

  @media ${(props) => props.theme.device.lg} {
    width: 900px;
  }
`;
