export const SYMBOL_MAP: Record<string, string> = {
	AUD: "$",
	EUR: "€",
	GBP: "£",
	USD: "$",
};

export const DEFAULT_CURRENCY = "USD";
export const DEFAULT_COUNTRY = "US";
export const CURRENCY_MAP: Record<any, string> = {
	AF: "USD",
	AX: "USD",
	AL: "USD",
	DZ: "USD",
	AS: "USD",
	AD: "EUR",
	AO: "USD",
	AI: "USD",
	AQ: "USD",
	AG: "USD",
	AR: "USD",
	AM: "USD",
	AW: "USD",
	AU: "AUD",
	AT: "EUR",
	AZ: "USD",
	BS: "USD",
	BH: "USD",
	BD: "USD",
	BB: "USD",
	BY: "USD",
	BE: "EUR",
	BZ: "USD",
	BJ: "USD",
	BM: "USD",
	BT: "USD",
	BO: "USD",
	BQ: "USD",
	BA: "USD",
	BW: "USD",
	BV: "USD",
	BR: "USD",
	IO: "USD",
	BN: "USD",
	BG: "EUR",
	BF: "USD",
	BI: "USD",
	CV: "EUR",
	KH: "USD",
	CM: "USD",
	CA: "USD",
	KY: "USD",
	CF: "USD",
	TD: "USD",
	CL: "USD",
	CN: "USD",
	CX: "USD",
	CC: "USD",
	CO: "USD",
	KM: "USD",
	CG: "USD",
	CD: "USD",
	CK: "USD",
	CR: "USD",
	CI: "USD",
	HR: "USD",
	CU: "USD",
	CW: "USD",
	CY: "EUR",
	CZ: "USD",
	DK: "EUR",
	DJ: "USD",
	DM: "USD",
	DO: "USD",
	EC: "USD",
	EG: "USD",
	SV: "USD",
	GQ: "USD",
	ER: "USD",
	EE: "EUR",
	SZ: "USD",
	ET: "USD",
	FK: "USD",
	FO: "EUR",
	FJ: "USD",
	FI: "EUR",
	FR: "EUR",
	GF: "EUR",
	PF: "USD",
	TF: "EUR",
	GA: "USD",
	GM: "USD",
	GE: "USD",
	DE: "EUR",
	GH: "USD",
	GI: "GBP",
	GR: "EUR",
	GL: "EUR",
	GD: "USD",
	GP: "EUR",
	GU: "USD",
	GT: "USD",
	GG: "USD",
	GN: "USD",
	GW: "USD",
	GY: "USD",
	HT: "USD",
	HM: "USD",
	VA: "EUR",
	HN: "USD",
	HK: "USD",
	HU: "USD",
	IS: "USD",
	IN: "USD",
	ID: "USD",
	IR: "USD",
	IQ: "USD",
	IE: "EUR",
	IM: "USD",
	IL: "USD",
	IT: "EUR",
	JM: "USD",
	JP: "USD",
	JE: "GBP",
	JO: "USD",
	KZ: "USD",
	KE: "USD",
	KI: "USD",
	KP: "USD",
	KR: "USD",
	KW: "USD",
	KG: "USD",
	LA: "USD",
	LV: "EUR",
	LB: "USD",
	LS: "USD",
	LR: "USD",
	LY: "USD",
	LI: "USD",
	LT: "EUR",
	LU: "EUR",
	MO: "USD",
	MG: "USD",
	MW: "USD",
	MY: "USD",
	MV: "USD",
	ML: "USD",
	MT: "EUR",
	MH: "USD",
	MQ: "EUR",
	MR: "USD",
	MU: "USD",
	YT: "EUR",
	MX: "USD",
	FM: "USD",
	MD: "USD",
	MC: "EUR",
	MN: "USD",
	ME: "USD",
	MS: "USD",
	MA: "EUR",
	MZ: "USD",
	MM: "USD",
	NA: "USD",
	NR: "USD",
	NP: "USD",
	NL: "EUR",
	NC: "USD",
	NZ: "USD",
	NI: "USD",
	NE: "USD",
	NG: "USD",
	NU: "USD",
	NF: "USD",
	MK: "USD",
	MP: "USD",
	NO: "USD",
	OM: "USD",
	PK: "USD",
	PW: "USD",
	PS: "USD",
	PA: "USD",
	PG: "USD",
	PY: "USD",
	PE: "USD",
	PH: "USD",
	PN: "USD",
	PL: "USD",
	PT: "EUR",
	PR: "USD",
	QA: "USD",
	RE: "EUR",
	RO: "USD",
	RU: "USD",
	RW: "USD",
	BL: "USD",
	SH: "USD",
	KN: "USD",
	LC: "USD",
	MF: "USD",
	PM: "EUR",
	VC: "USD",
	WS: "USD",
	SM: "EUR",
	ST: "USD",
	SA: "USD",
	SN: "USD",
	RS: "USD",
	SC: "USD",
	SL: "USD",
	SG: "USD",
	SX: "USD",
	SK: "USD",
	SI: "EUR",
	SB: "USD",
	SO: "USD",
	ZA: "USD",
	GS: "USD",
	SS: "USD",
	ES: "EUR",
	LK: "USD",
	SD: "USD",
	SR: "USD",
	SJ: "USD",
	SE: "EUR",
	CH: "USD",
	SY: "USD",
	TW: "USD",
	TJ: "USD",
	TZ: "USD",
	TH: "USD",
	TL: "USD",
	TG: "USD",
	TK: "USD",
	TO: "USD",
	TT: "USD",
	TN: "USD",
	TR: "USD",
	TM: "USD",
	TC: "USD",
	TV: "USD",
	UG: "USD",
	UA: "USD",
	AE: "USD",
	GB: "GBP",
	US: "USD",
	UM: "USD",
	UY: "USD",
	UZ: "USD",
	VU: "USD",
	VE: "USD",
	VN: "USD",
	VG: "USD",
	VI: "USD",
	WF: "USD",
	EH: "USD",
	YE: "USD",
	ZM: "USD",
	ZW: "USD",
};
