import { DateFilterProps } from "@models/facets";
export const ACTIVE_FILTER = "active";

export const ACTIVE_FORM_TYPES = {
	ARTIST_NAME: "artist_name",
	BPM: "bpm",
	LABEL_NAME: "label_name",
	RELEASE_DATE: "release_date",
	PUBLISH_DATE: "publsh_date",
	PURCHASE_DATE: "purchase_date",
	TRACK_NAME: "track_name",
};

export const BPM_LIMITS = {
	min: 1,
	max: 999,
};

export const DEFAULT_TEXT_FILTERS = {
	track: {
		className: "track-name",
		inputName: "track_name",
		stateName: "trackName",
		inputLabel: "TrackName",
	},
	label: {
		className: "label-name",
		inputName: "label_name",
		stateName: "labelName",
		inputLabel: "Label.Title",
	},
	artist: {
		className: "artist-name",
		inputName: "artist_name",
		stateName: "artistName",
		inputLabel: "Artist",
	},
};

export const EMPTY_BPM_FILTER = { min: 0, max: 0 };

export const EMPTY_DATE_FILTER: Record<string, DateFilterProps> = {
	releaseDate: {
		min: undefined,
		max: undefined,
		visible: "",
		inputLabel: "ReleaseDate",
		inputName: "release_date",
	},
	purchaseDate: {
		min: undefined,
		max: undefined,
		visible: "",
		inputLabel: "PurchaseDate",
		inputName: "purchase_date",
	},
	publishDate: {
		min: undefined,
		max: undefined,
		visible: "",
		inputLabel: "PublishDate",
		inputName: "publish_date",
	},
};

export const EMPTY_TEXT_FILTER = {
	artistName: "",
	trackName: "",
	labelName: "",
	inputLabel: "",
};
