import { useEffect, useState } from "react";

/**
 * A custom React hook for implementing useMediaQuery in a way that allows Hydration errors to be resolved
 * TODO: This hook is meant to be a holdover/partial fix until such time that we can refactor our UI to make proper
 * use of media queries and should remove the need for this hook
 *
 * @param {String} query the media query string to match
 * @returns {Boolean} True or False based on whether the media query matches
 */

export const useMediaQuery = ({ query }: { query: string }): boolean => {
	const [matches, setMatches] = useState<boolean>(true);
	useEffect(() => {
		const mediaQueryList = window.matchMedia(query);

		const handleChange = () => setMatches(!!mediaQueryList.matches);

		handleChange();

		mediaQueryList.addEventListener("change", handleChange);

		return () => {
			mediaQueryList.removeEventListener("change", handleChange);
		};
	}, [query]);
	return matches;
};
