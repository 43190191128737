import Button from "@components/Button";
import { Box } from "@components/core/Box";
import { Table, TableCell, TableData, TableRow } from "@components/core/Table";
import { PlusIcon } from "@components/core/icons";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { AddToQueue, Play } from "@components/interaction";
import { DATE_FILTER_FORMAT } from "@lib/constants";
import { cls } from "@lib/css";
import { dynamicImageUrl } from "@lib/utils/dynamicImageUrl";
import { Playlist } from "@models/Playlists";
import { format } from "@lib/utils/dateFnsWrapper";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
	ImagesWrapper,
	TableHeader,
	TableWrapper,
	Wrapper,
} from "./PlaylistTable.style";

const useStrictDroppable = (loading: boolean) => {
	const [enabled, setEnabled] = useState(false);

	useEffect(() => {
		let animation: any;

		if (!loading) {
			animation = requestAnimationFrame(() => setEnabled(true));
		}

		return () => {
			cancelAnimationFrame(animation);
			setEnabled(false);
		};
	}, [loading]);

	return [enabled];
};

interface Props {
	playlists: Playlist[];
	selectedItems?: number[];
	embedded?: boolean; // embeded table in popup or regular table
	scrollable?: boolean; // tracks container is scrollable
	showCreateButton?: boolean; // show or hide Create Playlist button
	loading?: boolean;
	onItemSelected?: (playlist: Playlist) => void;
	onItemsReorder?: (playlists: Playlist[]) => void;
	onItemDelete?: (playlist: Playlist) => void;
	onNewPlaylist?: () => void;
	onLoadMore?: () => void;
}

const PlaylistTable: React.FC<Props> = ({
	playlists,
	selectedItems = [],
	onNewPlaylist,
	onItemSelected,
	onItemsReorder,
	onItemDelete,
	onLoadMore,
	embedded = false,
	scrollable = false,
	showCreateButton = false,
	loading = false,
}) => {
	const router = useRouter();
	const { t } = useTranslation();
	const listInnerRef = useRef<HTMLDivElement>(null);
	const [items, setItems] = useState<Playlist[]>([]);

	useEffect(() => {
		setItems(playlists);
	}, [playlists]);

	const onScroll = () => {
		if (listInnerRef.current && scrollable) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight === scrollHeight) {
				if (onLoadMore) {
					onLoadMore();
				}
			}
		}
	};

	const reorderItems = (result: any) => {
		if (!result.destination) return;

		const currentIndex = result.source.index;
		const nextIndex = result.destination.index;

		const ordered = Array.from(items);
		const [removed] = ordered.splice(currentIndex, 1);
		ordered.splice(nextIndex, 0, removed);

		setItems(ordered);

		if (onItemsReorder) {
			onItemsReorder(ordered);
		}
	};

	const [enabled] = useStrictDroppable(false);

	const renderImagesWrapper = (item: Playlist) => {
		const renderReleaseImageAt = (position: number) => {
			const releaseImages = item?.release_images || [];
			const img = releaseImages.at(position);
			const srcUrl = img ? img : "/icons/playlist-default-img.svg";
			return (
				<Image
					key={position}
					width={0}
					height={0}
					src={dynamicImageUrl({ imageUri: srcUrl })}
					alt="Playlist Image"
					sizes="100vw"
					style={{
						width: "50%",
						height: "auto",
					}}
				/>
			);
		};
		return (
			<ImagesWrapper className={cls(embedded ? "embedded" : "")}>
				<div className="row">
					{renderReleaseImageAt(0)}
					{renderReleaseImageAt(1)}
				</div>
				<div className="row">
					{renderReleaseImageAt(2)}
					{renderReleaseImageAt(3)}
				</div>
			</ImagesWrapper>
		);
	};

	const renderItem = (item: Playlist, draggableProps?: any) => {
		const msToTime = (s: number) => {
			// Pad to 2 or 3 digits, default is 2
			function pad(n: number, z: number = 2) {
				return ("00" + n).slice(-z);
			}

			const ms = s % 1000;
			s = (s - ms) / 1000;
			const secs = s % 60;
			s = (s - secs) / 60;
			const mins = s % 60;
			const hrs = (s - mins) / 60;

			// hh:mm:ss
			// return pad(hrs) + ":" + pad(mins) + ":" + pad(secs); // + "." + pad(ms, 3);
			// hh hr mm min
			return hrs > 0 ?
				pad(hrs) + " hr " + pad(mins) + " min" :
				pad(mins) + " min";
		};

		const createdDate = format(new Date(item.created_date), DATE_FILTER_FORMAT);
		const selected = selectedItems.indexOf(item.id) > -1;

		return (
			<TableRow
				key={`playlist-item-${item.id}`}
				className={cls("item-row", embedded ? "embedded" : "")}
				onClick={() => onItemSelected && onItemSelected(item)}
			>
				<TableCell className="artwork">
					{embedded ?
							(
								renderImagesWrapper(item)
							) :
							(
								<Link href={`/library/playlists/${item.id}`}>
									{renderImagesWrapper(item)}
								</Link>
							)}
				</TableCell>
				<TableCell className="cell controls">
					<span className="fade">
						<Play playlist={item} />
					</span>
				</TableCell>
				<TableCell className="cell controls">
					<span className="fade">
						<AddToQueue playlist={item} />
					</span>
				</TableCell>
				<TableCell className="cell title">
					{embedded ?
							(
								<span className="name">{item.name}</span>
							) :
							(
								<Link href={`/library/playlists/${item.id}`} className="name">
									{item.name}
								</Link>
							)}
					<span className="count">
						{item.track_count} Track{item.track_count > 0 ? "s" : ""}
					</span>
				</TableCell>
				<TableCell className="cell genre-cell">
					<span className="genre">{item.genres.join(", ")}</span>
					<span className="length">{msToTime(item.length_ms)}</span>
				</TableCell>
				<TableCell className="cell key-cell">
					<span className="key">
						{item.keys
							// eslint-disable-next-line max-params
							.filter((value, index, array) => array.indexOf(value) === index)
							.join(", ")}
					</span>
					<span className="bpm">{item.bpm_range?.join(" - ")} BPM</span>
				</TableCell>
				<TableCell className="cell date-cell">
					<span className="date">{createdDate}</span>
				</TableCell>
				<TableCell className="cell fade actions">
					{onItemsReorder && (
						<button className="reorder" {...draggableProps}>
							<SpriteIcon id="re-order" />
						</button>
					)}
					{onItemDelete && (
						<button
							onClick={() => {
								onItemDelete(item);
							}}
						>
							<SpriteIcon id="close-x" />
						</button>
					)}
					{onItemSelected && (
						<button
							className="select"
							onClick={() => {
								onItemSelected(item);
							}}
						>
							{selected ?
									(
										<SpriteIcon id="checkbox-selected" width="20" height="20" />
									) :
									(
										<SpriteIcon id="checkbox" width="20" height="20" />
									)}
						</button>
					)}
				</TableCell>
			</TableRow>
		);
	};

	const PlaylistItem = ({ item, index }: { item: Playlist; index: number }) => (
		<Draggable
			draggableId={`playlist-item-${item.id}`}
			index={index}
			key={`playlist-item-${item.id}`}
		>
			{(provided) => (
				<div ref={provided.innerRef} {...provided.draggableProps}>
					{renderItem(item, provided.dragHandleProps)}
				</div>
			)}
		</Draggable>
	);

	const ItemList = React.memo(({ items }: { items: Playlist[] }) => {
		return (
			<>
				{items.map((item, index) => (
					<PlaylistItem item={item} index={index} key={item.id} />
				))}
			</>
		);
	});
	ItemList.displayName = "SortablePlaylist";

	if (playlists.length === 0 && !loading) {
		return (
			<Wrapper>
				<Box></Box>
				<Box p="20" text="secondary">
					{t("Playlists.NoPlaylistsFound")}
				</Box>
				<Button
					type="primary"
					onClick={() => {
						if (onNewPlaylist) {
							onNewPlaylist();
						} else {
							router.push("/library/playlists/new");
						}
					}}
				>
					{t("Playlists.Create")}
				</Button>
			</Wrapper>
		);
	}
	return (
		<TableWrapper>
			<TableHeader>
				{showCreateButton && (
					<Button
						type="primary-link"
						onClick={() => {
							if (onNewPlaylist) {
								onNewPlaylist();
							} else {
								router.push("/library/playlists/new");
							}
						}}
					>
						<>
							<PlusIcon active />
							{t("Playlists.Create")}
						</>
					</Button>
				)}
			</TableHeader>
			<Table>
				{onItemsReorder ?
						(
							<DragDropContext onDragEnd={reorderItems}>
								<TableData
									className={cls(scrollable ? "scrollable" : "")}
									onScroll={onScroll}
									ref={listInnerRef}
								>
									{enabled && (
										<Droppable droppableId="playlists">
											{(provided) => (
												<div
													className="list-wrapper"
													ref={provided.innerRef}
													{...provided.droppableProps}
												>
													<ItemList items={items} />
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									)}
								</TableData>
							</DragDropContext>
						) :
						(
							<TableData
								className={cls(scrollable ? "scrollable" : "")}
								onScroll={onScroll}
								ref={listInnerRef}
							>
								{items.map((playlist) => renderItem(playlist))}
							</TableData>
						)}
			</Table>
		</TableWrapper>
	);
};

export default PlaylistTable;
