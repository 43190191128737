import { PaginatedResponse } from "@/src/models/generics";
import { Genre } from "@/src/models/release";

const GENRE_MENU_COLUMNS = 4;

export const prepareGenreColumns = (genres: PaginatedResponse<Genre>, columns: number = GENRE_MENU_COLUMNS) => {
	if (!genres) return [];

	const genresPerColumn = Math.ceil((genres?.count || 0) / columns);
	const genreColumns: Array<Genre[]> = [];
	for (let i = 0; i < genres?.results?.length; i += genresPerColumn) {
		const chunk = genres?.results?.slice(i, i + genresPerColumn);
		genreColumns.push(chunk);
	}

	return genreColumns;
};
