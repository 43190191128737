export type Size = "sm" | "md" | "lg" | "auto";

export const dynamicImageUrl = ({ imageUri, size = "md", width, height }: { imageUri: any; size?: Size; width?: string; height?: null }) => {
	if (!imageUri) {
		return "";
	}

	// Don't apply dynamic image sizing on static assets
	if (!imageUri.includes("image_size")) return imageUri;
	if (size === "auto") return imageUri.replace(/image_size*\/{w}x{h}\//, "");
	switch (size) {
		case "sm":
			width = "95";
			break;
		case "md":
			width = "250";
			break;
		case "lg":
			width = "500";
			break;
	}
	const start = "image_size/";
	const beginningPath = imageUri.substring(0, imageUri.indexOf(start) + start.length);
	const pathWithSize = imageUri.substring(imageUri.indexOf(start) + start.length);
	const imagePath = pathWithSize.substring(pathWithSize.indexOf("/"));
	const dynamicImageUrl = `${beginningPath}${width}x${height || width}${imagePath}`;
	return dynamicImageUrl;
};
