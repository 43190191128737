import styled from "styled-components";

export const ItemLoaderWrapper = styled.button`
  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const DownloadAllButtonElement = styled.button`
  svg {
    transition: opacity 120ms ease-in;
    opacity: 1;

    &.disabled {
      opacity: 0.25;
    }
  }
`;

export const DownloadAllFullButtonTextSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  white-space: nowrap;
  width: 100%;

  > svg {
    margin-right: 0.5rem;
    
    > path {
      stroke: ${(props) => props.theme.colors.neutrals.primary[900]};
    }
  }

  ${ItemLoaderWrapper} svg > path {
    fill: ${(props) => props.theme.colors.neutrals.primary[900]};
  }
`;
