import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { cls } from "@lib/css";
import {
	CloseButton,
	Container,
	Content,
	Footer,
	Header,
	Overlay,
	Wrapper,
} from "./Modal.style";

interface Props {
	header?: React.ReactNode[] | React.ReactNode | string;
	footer?: React.ReactNode[] | React.ReactNode | string;
	showClose?: boolean;
	show: boolean;
	onClose: () => void;
	children?: React.ReactNode[] | React.ReactNode;
	noStyle?: boolean;
	alignSelf?: string;
}

const Modal: React.FC<Props> = ({
	show,
	header,
	footer,
	showClose,
	onClose,
	children,
	noStyle = false,
	alignSelf,
}) => {
	return (
		<>
			{show && (
				<Wrapper>
					<Overlay onClick={() => onClose()} />
					<Container className={cls(noStyle ? "no-style" : undefined)} alignSelf={alignSelf}>
						{header && (
							<Header>
								{header}
								{showClose && (
									<CloseButton
										onClick={() => {
											onClose();
										}}
									>
										<SpriteIcon id="close-x" width="19" height="18" />
									</CloseButton>
								)}
							</Header>
						)}
						<Content>{children}</Content>
						{footer && <Footer>{footer}</Footer>}
					</Container>
				</Wrapper>
			)}
		</>
	);
};

export default Modal;
