/* eslint-disable react-hooks/exhaustive-deps */
import { SearchFilter } from "@components/filters/SearchFilter";
import { TracksList } from "@components/lists/TracksList";
import { Pager, urlParamsToResetPagination } from "@components/paging";
import { PagerWrapper } from "@components/paging/Pager/Pager.style";
import { LibraryTable } from "@components/tables/LibraryTable";
import { useSessionContext } from "@lib/context/session";
import { useChartTracks } from "@lib/hooks/useChartTracks";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import {
	getGenresQuery,
	getKeysQuery,
	getSubGenresQuery,
} from "@lib/network/genres";
import { getLibraryCollectionsQuery } from "@lib/network/library";
import { device } from "@styles/theme";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Wrapper } from "./Collections.style";

interface Props {
	chartId: number;
}

interface Filters {
	release_date: undefined | string;
	purchase_date: undefined | string;
	bpm: undefined | string;
	genre_id: undefined | string;
	sub_genre_id: undefined | string;
	key_id: undefined | string;
	artist_name: undefined | string;
	label_name: undefined | string;
	track_name: undefined | string;
}

const Collections = (props: Props) => {
	const { chartId } = props;
	const chartTracksHook = useChartTracks(chartId);
	const isDesktop = useMediaQuery({ query: device.xl });
	const { getAccessToken } = useSessionContext();
	const router = useRouter();

	const [checkedGenres, setCheckedGenres] = useState<any>();
	const [checkedSubGenres, setCheckedSubGenres] = useState<any>();
	const [checkedKeys, setCheckedKeys] = useState<any>();

	const [filters, setFilters] = useState<Filters>({
		release_date: undefined,
		purchase_date: undefined,
		bpm: undefined,
		genre_id: undefined,
		sub_genre_id: undefined,
		key_id: undefined,
		artist_name: undefined,
		label_name: undefined,
		track_name: undefined,
	});

	const resultsPerPage = 10;

	const accessToken = getAccessToken();
	const page = Number(router.query.page) || 1;

	const {
		data: collectionsData,
		isLoading: collectionsIsLoading,
		refetch: collectionsRefetch,
	} = useQuery<any>({
		...getLibraryCollectionsQuery({
			params: {
				page: Number(page) || 1,
				per_page: resultsPerPage,
				publish_date: filters.release_date,
				purchase_date: filters.purchase_date,
				bpm: filters.bpm,
				genre_id: filters.genre_id,
				sub_genre_id: filters.sub_genre_id,
				key_id: filters.key_id,
				artist_name: filters.artist_name,
				label_name: filters.label_name,
				name: filters.track_name,
			},
			accessToken,
		}),
	});

	const { isLoading: genresLoading, refetch: genresRefetch } = useQuery({
		...getGenresQuery(accessToken),
		onSuccess: (data) => {
			let splitGenreIds: number[] = [];
			if (filters.genre_id) {
				splitGenreIds = filters.genre_id.split(",").map(Number);
			}
			const genres = data.results.map((item: any) => {
				if (splitGenreIds.filter((genreId) => genreId === item.id).length > 0) {
					return { id: item.id, name: item.name, checked: true };
				}
				return { id: item.id, name: item.name, checked: false };
			});

			setCheckedGenres(genres);
		},
	});

	const { isLoading: subGenresLoading, refetch: subGenresRefetch } = useQuery({
		...getSubGenresQuery(accessToken),
		onSuccess: (data) => {
			let splitSubGenreIds: number[] = [];
			if (filters.sub_genre_id) {
				splitSubGenreIds = filters.sub_genre_id.split(",").map(Number);
			}
			const subGenres = data.results.map((item: any) => {
				if (
					splitSubGenreIds.filter((subGenreId) => subGenreId === item.id)
						.length > 0
				) {
					return { id: item.id, name: item.name, checked: true };
				}
				return { id: item.id, name: item.name, checked: false };
			});
			setCheckedSubGenres(subGenres);
		},
	});

	const { refetch: keysRefetch } = useQuery({
		...getKeysQuery({ page: 1, per_page: 34 }, accessToken),
		onSuccess: (data) => {
			let splitKeyIds: number[] = [];
			if (filters.key_id) {
				splitKeyIds = filters.key_id.split(",").map(Number);
			}
			const keys = data.results.map((item: any) => {
				if (splitKeyIds.filter((keyId) => keyId === item.id).length > 0) {
					return { id: item.id, name: item.name, checked: true };
				}
				return { id: item.id, name: item.name, checked: false };
			});
			setCheckedKeys(keys);
		},
	});

	useEffect(() => {
		collectionsRefetch();
		genresRefetch();
		subGenresRefetch();
		keysRefetch();
	}, []);

	useEffect(() => {
		collectionsRefetch();
	}, [page, filters]);

	const handleFacetFilterChange = (data: Record<string, string>) => {
		let updatedFilters = { ...filters };
		Object.entries(data).forEach(([key, value]) => {
			if (value && key in filters) {
				updatedFilters = { ...updatedFilters, [key]: value };
			} else {
				updatedFilters = { ...updatedFilters, [key]: undefined };
			}
		});
		setFilters({
			...updatedFilters,
			...urlParamsToResetPagination(router),
		});
	};

	const handleFacetFilterReset = () => {
		let updatedFilters = { ...filters };
		Object.entries(filters).forEach(([key]) => {
			updatedFilters = { ...updatedFilters, [key]: undefined };
		});
		setFilters(updatedFilters);
	};

	const modalFilterApplied = () => {
		let filterApplied = false;
		Object.entries(filters).forEach(([, value]) => {
			if (value) {
				filterApplied = true;
				return;
			}
		});
		return filterApplied;
	};

	if (
		collectionsIsLoading ||
		genresLoading ||
		subGenresLoading ||
		collectionsData === undefined ||
		checkedGenres === undefined ||
		checkedSubGenres === undefined
	) {
		return <></>;
	}

	const slicedData = collectionsData.results.slice(0, page * resultsPerPage);
	return (
		<Wrapper>
			<SearchFilter
				showTrackTextFilter={true}
				showPurchaseDateFilter={true}
				showReleaseDateFilter={true}
				showBPMFilter={true}
				facets={{
					genre: checkedGenres || [],
					key: checkedKeys || [],
					sub_genre: checkedSubGenres || [],
				}}
				showArtistTextFilter
				showLabelTextFilter
				onChange={handleFacetFilterChange}
				onReset={handleFacetFilterReset}
				hideOrderBy
				modalFilterApplied={modalFilterApplied()}
				modal
			/>

			<PagerWrapper className="right">
				<Pager
					totalResults={collectionsData.count}
					hideResultsPerPage
					defaultPerPage={resultsPerPage}
				/>
			</PagerWrapper>
			{isDesktop ?
					(
						<LibraryTable
							location="DJ Chart Page"
							showNumbers={false}
							isLoading={collectionsIsLoading}
							tracks={slicedData || []}
							isAddTrackModal
							chartAddTrackLoading={chartTracksHook.addTrackLoading}
							chartAddedTracks={chartTracksHook.successfullyAddedTracks}
							chartAddTrack={chartTracksHook.addTrack}
						/>
					) :
					(
						<TracksList
							location="DJ Chart Page"
							showNumbers={false}
							isLoading={collectionsIsLoading}
							tracks={slicedData || []}
							showArtwork
							isCollection
							isAddTrackModal
							chartAddTrackLoading={chartTracksHook.addTrackLoading}
							chartAddedTracks={chartTracksHook.successfullyAddedTracks}
							chartAddTrack={chartTracksHook.addTrack}
						/>
					)}
		</Wrapper>
	);
};

export default Collections;
