import { LOGGED_IN_SESSION_STORAGE_KEY } from "@bptypes/dataLayerEvents";
import Button from "@components/Button";
import { Modal } from "@components/core/Modal";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import SearchBox from "@components/pages/search/SearchBox";
import {
	ACCOUNT_MENU_ITEMS,
	HEADER_MENU_ITEMS,
} from "@lib/constants";
import { useCart, useCartActions } from "@lib/context/cart";
import { LayoutContext } from "@lib/context/layout/LayoutContext";
import { usePlayerActions } from "@lib/context/player";
import { useSessionContext } from "@lib/context/session";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { getMyAccountQuery, getMySubscriptionsQuery } from "@lib/network/my";
import { slugify } from "@lib/utils";
import { dynamicImageUrl } from "@lib/utils/dynamicImageUrl";
import { CartDetails } from "@models/Cart";
import { IntrospectUser } from "@models/auth";
import * as Sentry from "@sentry/nextjs";
import { device } from "@styles/theme";
import { useQuery } from "@tanstack/react-query";
import { Trans, useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useContext, useEffect, useState } from "react";
import {
	AccountCartContent,
	AccountCartHeader,
	AccountCartItem,
	AccountCartMenuItem,
	AccountCartSelector,
	AvatarDropdownMenu,
	AvatarModalMenu,
	BeatportLogoLarge,
	BeatportLogoSmall,
	CartCounter,
	CartDetailsWrapper,
	DesktopHeader,
	FullSearchBoxWrapper,
	HeaderWrapper,
	LeftRegion,
	MobileHeader,
	RightRegion,
} from "./Header.style";
import HeaderLink, { HeaderLinkLabel } from "./headerLink/HeaderLink";

const Header = () => {
	const { clearQueue } = usePlayerActions();
	const { showSidebar, setShowSidebar, authenticateLink } = useContext(LayoutContext);
	const { getAccessToken, getIsSessionValid, session } = useSessionContext();
	const { t } = useTranslation("translation");
	const router = useRouter();
	const [showAvatarSubMenu, setShowAvatarSubMenu] = useState(false);
	const [showCartSubMenu, setShowCartSubMenu] = useState(false);
	const [showSearchBar, setShowSearchBar] = useState(false);
	const [isHeaderFixed, setIsHeaderFixed] = useState(false);
	const accessToken = getAccessToken();
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });

	const isSm = useMediaQuery({ query: device.sm });
	const isDesktop = useMediaQuery({ query: device.xl });

	const { state } = useCart();
	const { carts, cartDetails } = state;
	const { createCartQueries } = useCartActions();
	const { getDefaultCart } = createCartQueries(carts, cartDetails);

	const defaultCart = getDefaultCart();
	const cartItemCount = defaultCart?.summary?.item_count || 0;
	const cartLink = defaultCart ?
		`/cart/${slugify(defaultCart.name)}/${defaultCart.id}` :
		"/cart/";

	const { data: myAccount } = useQuery<any>({
		...getMyAccountQuery({
			accessToken,
			enabled: isLoggedInSessionValid,
		}),
		enabled: isLoggedInSessionValid,
	});

	const { data: mySubscriptions } = useQuery<any>({
		...getMySubscriptionsQuery({
			accessToken,
			personId: session?.introspect?.person_id,
		}),
		enabled: isLoggedInSessionValid,
	},

	);

	const activeSubscription = mySubscriptions?.active || false;

	const profileImage = myAccount?.dj_profile?.image?.media_url ?
		dynamicImageUrl({ imageUri: myAccount?.dj_profile?.image?.media_url, size: "sm" }) :
		"/images/avatar5.png";

	const guardLink = (e: any, path: string) => {
		e.preventDefault();

		if (router.asPath !== path) {
			authenticateLink(path);
		}
		return false;
	};

	useEffect(() => {
		const body = document.querySelector("body");
		if (body) {
			if (showAvatarSubMenu || showCartSubMenu) {
				body.style.overflow = "hidden";
			} else {
				body.style.overflow = "auto";
			}
		}
	}, [showAvatarSubMenu, showCartSubMenu]);

	// Set sentry attributes
	const introspectUser = (session?.introspect as IntrospectUser) || null;
	useEffect(() => {
		if (introspectUser && introspectUser.user_id) {
			// Logged In
			Sentry.setUser({
				username: introspectUser.username,
				id: String(introspectUser.user_id),
			});
		} else {
			// Not Logged In
			Sentry.setUser(null);
		}
	}, [introspectUser]);

	useEffect(() => {
		let lastScrollY = window.scrollY;
		let ticking = false;

		const updateScrollDir = () => {
			const scrollY = window.scrollY;

			if (scrollY === lastScrollY) {
				ticking = false;
				return;
			}

			if (scrollY > lastScrollY && isHeaderFixed) {
				setIsHeaderFixed(false);
			} else if (scrollY < lastScrollY && !isHeaderFixed) {
				setIsHeaderFixed(true);
			}

			lastScrollY = scrollY > 0 ? scrollY : 0;
			ticking = false;
		};

		const onScroll = () => {
			if (!ticking) {
				!isDesktop && window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, [isHeaderFixed, isDesktop]);

	const AvatarSubMenu = ({ isModal }: { isModal?: boolean }) => {
		const MenuItems = () => {
			return (
				<div>
					<ul>
						{ACCOUNT_MENU_ITEMS.map((menuItem) => {
							if (menuItem.action) {
								return (
									<li key={`${menuItem.order}`}>
										<a
											onClick={() =>
												menuItem.action().then(() => {
													if (menuItem.isSignOut) {
														clearQueue();
														sessionStorage.removeItem(LOGGED_IN_SESSION_STORAGE_KEY);
													}
													if (menuItem.route) {
														router.push(menuItem.route);
													}
												})}
											data-testid={menuItem.cypressTag}
										>
											<Trans>{menuItem.label}</Trans>
										</a>
									</li>
								);
							}
							return (
								<li key={`${menuItem.order}`}>
									<Link
										href={menuItem.href}
										prefetch={false}
										data-testid={menuItem.cypressTag}
									>
										<Trans>{menuItem.label}</Trans>
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			);
		};

		if (isModal) {
			return (
				<AvatarModalMenu>
					<MenuItems />
				</AvatarModalMenu>
			);
		}
		return (
			<AvatarDropdownMenu>
				<MenuItems />
			</AvatarDropdownMenu>
		);
	};

	const CartsSubmenu: React.FC<{ showClose?: boolean }> = ({
		showClose = false,
	}) => {
		const { t } = useTranslation("translation");
		const sortedCarts = Object.values(cartDetails).sort((a, b) => {
			if (a.name === "cart") {
				return -1;
			} else if (b.name === "cart") {
				return 1;
			} else if (a.name === "hold-bin") {
				return -1;
			} else if (b.name === "hold-bin") {
				return 1;
			} else {
				if (a.name > b.name) return 1;
				if (a.name < b.name) return -1;
			}
			return 0;
		});

		const renderCartName = (cart: CartDetails) => {
			switch (cart.name) {
				case "hold-bin":
					return (
						<>
							<div className="name">
								<SpriteIcon id="hold-bin" />
								{" "}
								<span className="title">Hold Bin</span>
								<span className="count">{cart.summary?.item_count}</span>
								<span className="spacer"></span>
							</div>
						</>
					);
				case "cart":
					return (
						<>
							<div className="name">
								{cart.default ?
										(
											<SpriteIcon id="cart-main" />
										) :
										(
											<SpriteIcon id="cart" />
										)}
								{" "}
								<span className="title">Main Cart</span>
								<span className="count">{cart.summary?.item_count}</span>
								<span className="spacer"></span>
								<span className="total">
									{cart.summary?.price?.total.display}
								</span>
							</div>
						</>
					);
				default:
					return (
						<>
							<div className="name">
								{cart.default ?
										(
											<SpriteIcon id="cart-main" />
										) :
										(
											<SpriteIcon id="cart" />
										)}
								{" "}
								<span className="title">{cart.name}</span>
								<span className="count">{cart.summary?.item_count}</span>
								<span className="spacer"></span>
								<span className="total">
									{cart.summary?.price?.total.display}
								</span>
							</div>
						</>
					);
			}
		};

		return (
			<AccountCartSelector>
				<AccountCartHeader>
					<Link href="/account/carts" prefetch={false} title="Cart Preferences">
						{t("Cart.ShoppingCarts")}
						<SpriteIcon id="settings" />
					</Link>
					{showClose && (
						<a
							className="close"
							title="close"
							onClick={() => {
								setShowCartSubMenu(false);
							}}
						>
							<SpriteIcon id="close-x" width="19" height="18" />
						</a>
					)}
				</AccountCartHeader>
				<AccountCartContent>
					{sortedCarts.map((cart) => (
						<AccountCartItem key={`cart-${cart.id}`}>
							<a
								title={`Open ${cart.name}`}
								onClick={() => {
									router.push(`/cart/${slugify(cart.name)}/${cart.id}`);
								}}
							>
								{renderCartName(cart)}
							</a>
						</AccountCartItem>
					))}
				</AccountCartContent>
			</AccountCartSelector>
		);
	};

	const RightRegionComponents = () => {
		const CartLink = () => {
			return (
				<AccountCartMenuItem>
					<Link
						href={isLoggedInSessionValid && defaultCart ? cartLink : "/"}
						prefetch={false}
						legacyBehavior
					>
						<span
							className="white"
							onClick={(e) => {
								if (!isLoggedInSessionValid) {
									guardLink(e, cartLink);
								} else if (!isDesktop) {
									e.preventDefault();
									setShowCartSubMenu(true);
								}
							}}
						>
							{isLoggedInSessionValid && <CartCounter>{cartItemCount}</CartCounter>}
							<a data-testid="header-mainnav-link-cart">
								<SpriteIcon
									id="shopping-cart"
									fill="none"
									width={isDesktop ? "16px" : "24px"}
									height={isDesktop ? "16px" : "24px"}
								/>
							</a>
						</span>
					</Link>
					{isDesktop ?
							(
								<>
									{isLoggedInSessionValid && (
										<CartDetailsWrapper>
											<CartsSubmenu />
										</CartDetailsWrapper>
									)}
								</>
							) :
							(
								<>
									<Modal
										onClose={() => setShowCartSubMenu(false)}
										show={showCartSubMenu}
										noStyle
									>
										<CartsSubmenu showClose />
									</Modal>
								</>
							)}
				</AccountCartMenuItem>
			);
		};

		const AvatarMenuModal = () => {
			if (isDesktop) {
				return (
					<div
						className="account_avatar"
						data-testid="header-button-account-avatar"
					>
						<Link href="/account/profile" prefetch={false}>
							<div className="svg_wrapper">
								<Image
									src={profileImage}
									alt="Account settings"
									width={32}
									height={32}
								/>
							</div>
						</Link>
						<AvatarSubMenu />
					</div>
				);
			}

			return (
				<div>
					<div
						data-testid="header-button-account-avatar"
						className="svg_wrapper account_avatar"
						onClick={() => setShowAvatarSubMenu(true)}
					>
						<Image src={profileImage} alt="" width={32} height={32} />
					</div>
					<Modal
						onClose={() => setShowAvatarSubMenu(false)}
						show={showAvatarSubMenu}
						header={t("Account.Title")}
						showClose
					>
						<AvatarSubMenu isModal={true} />
					</Modal>
				</div>
			);
		};

		const AddStreamingButton = () => {
			return (
				<li className="subscription_button">
					<Link
						href="/subscriptions"
						prefetch={false}
						data-testid="header-mainnav-link-subscribe"
					>
						<Button type="primary">{t("AddStreaming")}</Button>
					</Link>
				</li>
			);
		};

		return (
			<Fragment>
				{!activeSubscription && isSm && <AddStreamingButton />}
				{!isDesktop && !showSearchBar && (
					<li>
						<SpriteIcon
							id="search"
							width="24"
							height="24"
							onClick={() => setShowSearchBar(!showSearchBar)}
						/>
					</li>
				)}
				<li className="header_item">
					{isLoggedInSessionValid ?
							(
								<AvatarMenuModal />
							) :
							(
								<span className="white">
									<Link href="/account/login" prefetch={false} legacyBehavior>
										<span onClick={(e) => guardLink(e, "")}>
											<SpriteIcon
												id="person"
												fill="none"
												width={isDesktop ? "16px" : "24px"}
												height={isDesktop ? "16px" : "24px"}
											/>
											{isDesktop && (
												<a data-testid="header-mainnav-link-desktoplogin">
													<HeaderLinkLabel label={t("Account.Login")} />
												</a>
											)}
										</span>
									</Link>
								</span>
							)}
				</li>
				<li className="header_item">
					<CartLink />
				</li>
			</Fragment>
		);
	};

	return (
		<>
			{isDesktop ?
					(
						<HeaderWrapper $isHeaderFixed={true}>
							<DesktopHeader>
								<LeftRegion>
									<li>
										<Link href="/" prefetch={false}>
											<BeatportLogoLarge
												src="/images/beatport-logo-desktop.svg"
												alt="Beatport Logo"
												data-testid="header-mainnav-link-bplogo"
											/>
										</Link>
									</li>
									{HEADER_MENU_ITEMS.map((item) => (
										<HeaderLink item={item} key={item.order} />
									))}
								</LeftRegion>
								<RightRegion>
									<li className="search">
										<SearchBox />
									</li>
									<RightRegionComponents />
								</RightRegion>
							</DesktopHeader>
						</HeaderWrapper>
					) :
					(
						<>
							{showSearchBar ?
									(
										<HeaderWrapper $isHeaderFixed={isHeaderFixed}>
											<MobileHeader>
												<FullSearchBoxWrapper>
													<li>
														<SearchBox setShowSearchBar={setShowSearchBar} />
													</li>
												</FullSearchBoxWrapper>
											</MobileHeader>
										</HeaderWrapper>
									) :
									(
										<HeaderWrapper $isHeaderFixed={showSidebar ? true : isHeaderFixed}>
											<MobileHeader>
												<LeftRegion>
													<li>
														{showSidebar ?
																(
																	<SpriteIcon
																		id="close-x"
																		width="22"
																		height="18"
																		onClick={() => setShowSidebar(!showSidebar)}
																	/>
																) :
																(
																	<SpriteIcon
																		id="menu"
																		width="22"
																		height="18"
																		onClick={() => setShowSidebar(!showSidebar)}
																	/>
																)}
													</li>
													<li>
														<Link href="/" prefetch={false}>
															<BeatportLogoSmall
																src="/images/beatport-logo-mobile.svg"
																alt="Beatport Logo"
																data-testid="header-mainnav-link-bplogo"
															/>
														</Link>
													</li>
												</LeftRegion>
												<RightRegion>
													<RightRegionComponents />
												</RightRegion>
											</MobileHeader>
										</HeaderWrapper>
									)}
						</>
					)}
		</>
	);
};

export default Header;
