import Button from "@components/Button";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import {
	Table,
	TableCell,
	TableData,
	TableHeader,
	TableRow,
} from "@components/core/Table";
import { usePlayerActions, usePlayerState } from "@lib/context/player";

import ImageFallback from "@components/core/Image/ImageFallback";
import {
	AddToPlaylist,
	AddToQueue,
	Play,
	Tooltip,
} from "@components/interaction";
import { DownloadButton } from "@components/interaction/DownloadButtons";
import DownloadAllButton from "@components/interaction/DownloadButtons/DownloadAllButton";
import { Control } from "@components/interaction/Play/Play.style";
import ReDownloadButton from "@components/interaction/ReDownloadButton/ReDownloadButton";
import { UpgradeToCart } from "@components/interaction/UpgradeToCart";
import { ItemLoader } from "@components/loaders";
import Marquee from "@components/Marquee";
import { FinalRedownloadConfirmationModal } from "@components/modals/FinalRedownloadConfirmationModal";
import { renderArtistNames } from "@components/shared/Artists/ArtistNames";
import LabelLink from "@components/shared/Labels/LabelLink";
import {
	AUDIO_FORMAT_IDS,
	AUDIO_FORMATS,
	ENCODING_STATUSES,
	TRACK_PLACEHOLDER,
} from "@lib/constants";
import { filenamePresets } from "@lib/constants/audio-format";
import { MAX_FREE_DOWNLOADS } from "@lib/constants/subscriptions";
import {
	useCollectionActions,
	useCollectionState,
} from "@lib/context/collection";
import { useSessionContext } from "@lib/context/session";
import { cls } from "@lib/css";
import { getMyAccountRequest } from "@lib/network/my";
import { checkIfUserHasSubscription, updateSelectedTracks } from "@lib/utils";
import { CartItem } from "@models/Cart";
import { Track } from "@models/track";
import { theme } from "@styles/theme";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { MouseEventHandler, useEffect, useState } from "react";
import {
	ArtworkAndControls,
	Exclusive,
	ReleaseName,
	TrackAddIndicator,
	TrackNo,
	Wrapper,
} from "./LibraryTable.style";
import Loader from "./Loader";

interface Props {
	isLoading?: boolean;
	cartTracks?: CartItem<Track>[];
	tracks?: Track[];
	showNumbers?: boolean;
	isDownloads?: boolean;
	isThankYou?: boolean;
	isAddTrackModal?: boolean;
	chartAddTrackLoading?: number[];
	chartAddedTracks?: number[];
	chartAddTrack?: (trackId: number) => void;
	isCollection?: boolean;
	location?: string;
	overallEncodingStatus?: string;
}

const IMG_WIDTH = TRACK_PLACEHOLDER.image.width;
const IMG_HEIGHT = TRACK_PLACEHOLDER.image.height;
const FALLBACK_URL = TRACK_PLACEHOLDER.href;

const canReinteractWithTrack = (isSubscribed: boolean, track: Track) => {
	if (isSubscribed || !track.received_status) return true;

	return track.initial_download;
};

const checkIfTrackDownloadable = (track: Track) => {
	return track.encode_status_name === ENCODING_STATUSES.COMPLETE && !track.pre_order;
};

const LibraryTable: React.FC<Props> = ({
	isLoading,
	cartTracks,
	tracks = [],
	showNumbers = true,
	isDownloads,
	isThankYou,
	isAddTrackModal = false,
	chartAddTrackLoading = [],
	chartAddedTracks = [],
	chartAddTrack,
	isCollection = false,
	location,
	overallEncodingStatus,
}) => {
	const { t } = useTranslation("translation");
	const [finalRedownloadModalCallback, setFinalRedownloadModalCallback] = useState<(() => MouseEventHandler) | null>(null);
	const [selectedTracks, setSelectedTracks] = useState<Track[]>([]);
	const [filenamePattern, setFilenamePattern] = useState("");
	const [areTracksDownloaded, setAreTracksDownloaded] = useState(false);
	const { currentTrack } = usePlayerState();
	const { getAccessToken, getIsSessionValid, session } = useSessionContext();
	const { playTrack, addTracks } = usePlayerActions();
	const { playlist_queue: queueTracks } = useCollectionState();
	const { addTracksToPlaylist } = useCollectionActions();
	const accessToken = getAccessToken();
	const isSessionValid = getIsSessionValid();
	const isSubscribed = checkIfUserHasSubscription(session?.introspect);
	useEffect(() => {
		!isAddTrackModal &&
		(async () => {
			if (isSessionValid) {
				const account = (await getMyAccountRequest({ accessToken })).data;
				setFilenamePattern(
					account.preferences?.filename_convention || filenamePresets[1],
				);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken]);

	const downloadAllTracks = selectedTracks;
	const filteredTracks = cartTracks ?
		cartTracks
			.filter((t) => t.item !== undefined)
			.map((c) => {
				c.item.cart_item_data = c;
				return c.item;
			}) :
		tracks;

	useEffect(() => {
		// Disabled Thank You page Download All button if tracks have been downloaded
		if (isThankYou && downloadAllTracks.length > 0) {
			const downloadedTracks = downloadAllTracks.filter((track) => {
				return track.received_status === true;
			});
			if (downloadedTracks.length === downloadAllTracks.length) setAreTracksDownloaded(true);
		}
	}, [downloadAllTracks, isThankYou]);

	const handleSelectTrack = (
		e: React.ChangeEvent<HTMLInputElement>,
		track: Track,
	) => {
		const isChecked = e.target.checked;
		const updatedTracks = updateSelectedTracks(
			{ isChecked, isCollection, track, queueTracks, selectedTracks },
		);
		if (isCollection) {
			addTracksToPlaylist(updatedTracks);
		}
		setSelectedTracks(updatedTracks);
	};

	const handleSelectAllTracks = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			const interactableTracks = tracks.filter((track) =>
				canReinteractWithTrack(isSubscribed, track) && checkIfTrackDownloadable(track),
			);
			setSelectedTracks(interactableTracks);
		} else {
			setSelectedTracks([]);
		}
	};

	const renderTiming = (timing: number): any => {
		const minutes = Math.floor(timing / 60000);
		const seconds = ((timing % 60000) / 1000).toFixed(0);

		return (
			<div>{minutes + ":" + (parseInt(seconds) < 10 ? "0" : "") + seconds}</div>
		);
	};

	if (isLoading) {
		return <Loader />;
	}

	const handlePlayTrackClick = (track: Track) => {
		playTrack(track);
		setTimeout(() => addTracks([track]), 100);
	};

	const hasSelectedTracks = selectedTracks.length > 0;

	return (
		<Wrapper className={cls(showNumbers ? "numbers" : undefined)}>
			<FinalRedownloadConfirmationModal
				show={!!finalRedownloadModalCallback}
				onClose={() => { setFinalRedownloadModalCallback(null); }}
				onConfirmReDownload={() => {
					if (finalRedownloadModalCallback) {
						finalRedownloadModalCallback();
						setFinalRedownloadModalCallback(null);
					}
				}}
			/>
			<Table>
				{!isAddTrackModal && (
					<TableHeader className="row">
						<TableCell className="controls">&nbsp;</TableCell>

						<TableCell className="title">
							{t("TrackName")}
							<br />
							{t("ArtistsRemixers")}
						</TableCell>
						<TableCell className="genre">
							{t("Genre")}
							<br />
							{t("Time")}
						</TableCell>
						<TableCell className="bpm">
							{t("Key")}
							<br />
							BPM
						</TableCell>
						<TableCell className="label">
							{t("Label.Title")}
							<br />
							{t("ReleaseDate")}
						</TableCell>
						{!isDownloads && (
							<>
								<TableCell className="date">
									{t("PurchaseDate")}
									<br />
									Format
								</TableCell>
								<TableCell className="card">&nbsp;</TableCell>
							</>
						)}
						{(isCollection) && (
							<>
								<TableCell className="card">
									<div className="download-actions"></div>
								</TableCell>
							</>
						)}
						{isDownloads && (
							<>
								<TableCell className="card">
									<div className="download-actions">
										<DownloadAllButton
											tracks={hasSelectedTracks ? selectedTracks : downloadAllTracks}
											disabled={downloadAllTracks.length === 0 || areTracksDownloaded}
											filenamePattern={filenamePattern}
											fullButton
											overallEncodingStatus={hasSelectedTracks ? undefined : overallEncodingStatus}
										/>
										<input type="checkbox" onChange={handleSelectAllTracks} />
									</div>
								</TableCell>
							</>
						)}
					</TableHeader>
				)}
				<TableData>
					{filteredTracks.map((track, index) => {
						const shouldMarkDownloadAsComplete = !canReinteractWithTrack(
							isSubscribed,
							track,
						);
						const tombstoned = !track.enabled;
						const currentNumberOfDownloads = track.order_item_download_try_count || 0;
						const hasOneDownloadAttemptLeft = !isSubscribed && (currentNumberOfDownloads === MAX_FREE_DOWNLOADS - 1);
						const hasNoMoreDownloadAttempts = !isSubscribed && currentNumberOfDownloads >= MAX_FREE_DOWNLOADS;
						const isTrackDownloadable = checkIfTrackDownloadable(track);
						const isUsingUpgradedAudioFormat =
							track.audio_format &&
							AUDIO_FORMAT_IDS.filter(
								(item) =>
									item.name === AUDIO_FORMATS.aiff ||
									item.name === AUDIO_FORMATS.wav,
							)
								.map((item) => item.id)
								.includes(track.audio_format.id);

						return (
							<TableRow
								key={`tracks-${track.id}`}
								data-testid="library-tracks-table-row"
								className={cls(
									"row",
									!isThankYou ? "add-tracks-modal" : undefined,
									currentTrack && currentTrack.id === track.id ?
										"current" :
										undefined,
									tombstoned ? "tombstoned" : undefined,
								)}
								tooltipText={
									track.is_available_for_streaming ?
										undefined :
										t("Collection.NotAvailableStreaming")
								}
							>
								{isAddTrackModal ?
										(
											<TableCell className="add-tracks-controls">
												<ArtworkAndControls>
													<Control onClick={() => handlePlayTrackClick(track)}>
														<ImageFallback
															src={track.release.image?.uri}
															srcSize="sm"
															alt={track.release.name}
															width="40"
															height="40"
															fallbackSrc={FALLBACK_URL}
															blurDataURL={FALLBACK_URL}
														/>
														{track.exclusive && <Exclusive>EXCLUSIVE</Exclusive>}
														<SpriteIcon
															id="track-play-inline"
															width="11"
															height="16"
															className="album-cover"
														/>
													</Control>
												</ArtworkAndControls>
											</TableCell>
										) :
										(
											<TableCell className="controls">
												<ArtworkAndControls>
													<Link
														href={`/release/${track.release.slug}/${track.release.id}`}
														title={track.release.name}
														className="artwork"
													>
														<ImageFallback
															src={track.release.image?.uri}
															srcSize="sm"
															alt={track.release.name}
															width={IMG_WIDTH}
															height={IMG_HEIGHT}
															fallbackSrc={FALLBACK_URL}
															blurDataURL={FALLBACK_URL}
														/>
														{track.exclusive && <Exclusive>EXCLUSIVE</Exclusive>}
													</Link>
													{showNumbers && <TrackNo>{index + 1}</TrackNo>}
													<span className={tombstoned ? "tombstoned" : "fade"}>
														<Play
															disabled={tombstoned}
															tracks={[track]}
															virtualQueue={filteredTracks}
														/>
													</span>
													<span className={tombstoned ? "tombstoned" : "fade"}>
														<AddToQueue tracks={[track]} disabled={tombstoned} />
													</span>
													<span className={tombstoned ? "tombstoned" : "fade"}>
														<AddToPlaylist tracks={[track]} disabled={tombstoned} />
													</span>
												</ArtworkAndControls>
											</TableCell>
										)}
								<TableCell className="cell title">
									<div className="container">
										<Link
											href={`/track/${track.slug}/${track.id}`}
											title={track.name}
										>
											<Marquee>
												<ReleaseName>
													{track.name} <span>{track.mix_name}</span>{" "}
												</ReleaseName>
											</Marquee>
										</Link>
										{track.artists &&
											track.artists.length > 0 && (
											<Marquee>
												{renderArtistNames(track.artists, { location })}
											</Marquee>
										)}
									</div>
								</TableCell>
								<TableCell className="cell genre">
									<Link
										href={`/genre/${track.genre.slug}/${track.genre.id}`}
										title={track.genre.name}
									>
										<span>{track.genre.name}</span>
									</Link>
									{renderTiming(track.length_ms)}
								</TableCell>

								<TableCell className="cell bpm">
									<div>{track.key?.name}</div>
									<div>{track.bpm} BPM</div>
								</TableCell>

								<TableCell className="cell label">
									<LabelLink label={track.release.label} location={location} withMarquee />
									<div>{track.new_release_date}</div>
								</TableCell>
								{isAddTrackModal && chartAddTrack !== undefined && (
									<TableCell className="cell card">
										{chartAddTrackLoading.includes(track.id) ?
												(
													<TrackAddIndicator>
														<span className="arrow">
															<ItemLoader />
														</span>
													</TrackAddIndicator>
												) :
											chartAddedTracks.includes(track.id) ?
													(
														<TrackAddIndicator>
															<span className="arrow">
																<SpriteIcon id="check-circle" />
															</span>
														</TrackAddIndicator>
													) :
													(
														<Button
															data-testid="add-to-chart-button"
															type="primary"
															onClick={() => chartAddTrack(track.id)}
														>
															Add
														</Button>
													)}
									</TableCell>
								)}
								{!isDownloads && !isAddTrackModal && (
									<>
										<TableCell className="cell date">
											<div>
												{track.purchase_date ?
													track.purchase_date?.split("T")[0] :
													""}
											</div>
											<div>
												{track.audio_format ?
													track.audio_format.name.toUpperCase() :
													""}
											</div>
										</TableCell>
										<TableCell className="cell card">
											{!isUsingUpgradedAudioFormat &&
												!track?.pre_order &&
												!tombstoned ?
													(
														<span className="fade">
															<UpgradeToCart upgrade={track} />
														</span>
													) :
													(
														""
													)}
										</TableCell>
									</>
								)}
								{isCollection && (
									<>
										<TableCell className="cell card">
											{track.pre_order ?
													(
														<Tooltip text={t("Collection.Preorder")}>
															<SpriteIcon id="preorder" />
														</Tooltip>
													) :
													!tombstoned ?
															(
																<div className="download-actions">
																	<ReDownloadButton
																		track={track}
																		buttonState={!track.received_status ? "ADDED" : "ADD"}
																		disabled={hasNoMoreDownloadAttempts}
																		onClick={
																			hasOneDownloadAttemptLeft ?
																					(handleDownload) => { setFinalRedownloadModalCallback(() => handleDownload); } :
																				undefined
																		}
																	/>
																	<input
																		type="checkbox"
																		value={track.id}
																		onChange={(e) => handleSelectTrack(e, track)}
																		checked={queueTracks?.some(
																			(selectedTrack: Track) =>
																				selectedTrack.id === track.id,
																		)}
																	/>
																</div>
															) :
															(
																<span className="info">
																	<Tooltip
																		text={t("Collection.Unavailable")}
																		position="left"
																	>
																		<SpriteIcon
																			className={
																				tombstoned ? "tombstoned selectable" : ""
																			}
																			id="info"
																			width="16"
																			height="16"
																			stroke={theme.colors.neutrals.primary.white}
																			fill={theme.colors.neutrals.primary.white}
																		/>
																	</Tooltip>
																</span>
															)}
										</TableCell>
									</>
								)}
								{isDownloads && !isAddTrackModal && (
									<>
										<TableCell className="cell card">
											<div className="download-actions">
												<DownloadButton
													track={track}
													encodeStatus={track.encode_status_name}
													filenamePattern={filenamePattern}
													shouldMarkDownloadAsComplete={
														shouldMarkDownloadAsComplete
													}
												/>
												{!shouldMarkDownloadAsComplete && (
													<input
														type="checkbox"
														value={track.id}
														onChange={(e) => handleSelectTrack(e, track)}
														checked={selectedTracks.includes(track)}
														disabled={!isTrackDownloadable}
													/>
												)}
											</div>
										</TableCell>
									</>
								)}
							</TableRow>
						);
					})}
				</TableData>
			</Table>
		</Wrapper>
	);
};

export default LibraryTable;
