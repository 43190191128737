import { Cart, CartDetails } from "@models/Cart";

export const createQueries = (carts: Record<number, Cart>, cartDetails: Record<number, CartDetails>) => ({
	getHoldBin: (): CartDetails | undefined => {
		const defaultCart = Object.values(cartDetails).filter((cart) => cart.name === "hold-bin");
		if (defaultCart) {
			return defaultCart[0];
		}
		return undefined;
	},

	getDefaultCart: (): CartDetails | undefined => {
		const defaultCart = Object.values(cartDetails).filter((cart) => cart.default);
		if (defaultCart) {
			return defaultCart[0];
		}
		return undefined;
	},
	getCartById: (cartId: number): CartDetails | undefined => {
		const filteredCart = Object.values(cartDetails).filter((cart) => cart.id == cartId);
		if (filteredCart) {
			return filteredCart[0];
		}
		return undefined;
	},
	isTrackInCart: (trackId: number): { inCart: boolean; cartIds: number[] } => {
		let inCart = false;
		const cartIds: number[] = [];

		Object.values(cartDetails).forEach((cart) => {
			const hasTrack = cart.tracks?.some((item) => item.item_id === trackId) || false;
			if (hasTrack) {
				cartIds.push(cart.id || 0);
				inCart = hasTrack;
			}
		});

		return { inCart: inCart, cartIds: cartIds };
	},
	isReleaseInCart: (releaseId: number): { inCart: boolean; cartIds: number[] } => {
		let inCart = false;
		const cartIds: number[] = [];

		Object.values(cartDetails).forEach((cart) => {
			const hasRelease = cart.releases?.some((item) => item.item_id === releaseId) || false;
			if (hasRelease) {
				cartIds.push(cart.id || 0);
				inCart = hasRelease;
			}
		});

		return { inCart: inCart, cartIds: cartIds };
	},
});
