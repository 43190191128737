import { cls } from "@lib/css";
import { ItemLoader } from "../ItemLoader";
import { Container, Wrapper } from "./PageLoader.style";

interface Props {
	inline?: boolean;
}

const PageLoader: React.FC<Props> = ({ inline = false }) => {
	return (
		<Wrapper className={cls(inline ? "inline" : "")} data-testid="page-loader-wrapper">
			<Container data-testid="page-loader-container">
				<ItemLoader />
			</Container>
		</Wrapper>
	);
};

export default PageLoader;
