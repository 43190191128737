/*
    This module centralizes our Sentry configuration and error handling.

    We integrate Sentry on both the client AND the server side. For the most part,
    the configurations are the same, but we add different sentry plugins depending
    on where it's running. Those differences are found in the Sentry.init() calls
    elsewhere in the code (sentry.client.config.ts and sentry.server.config.ts respectively.)

    This module puts all the shared configuration loading & settings together so
    it can be parsed and added to the Sentry.init() calls.

    This code was ported from Beatsource and changed to be Typescript compatible.
*/
export const Sentry = (typeof window !== "undefined") ? require("@sentry/browser") : require("@sentry/node");

/**
 * @param {Object} options The options object for configuring Sentry.
 * @param {String} options.environment Current application environment, i.e., production, development, etc.
 * @param {String} options.sentryDSN Key for Sentry to track the project.
 * @param {Boolean} options.sentryEnabled Override to only send Sentry errors in certain environments.
 * @param {Number} options.sentrySampleRate The sample rate for Sentry.
 */
export const sentryBaseOptions = ({ environment, sentryDSN, sentryEnabled, sentrySampleRate }: { environment: string; sentryDSN: string; sentryEnabled: boolean; sentrySampleRate: number }) => {
	return {
		environment,
		dsn: sentryEnabled ? sentryDSN : "",
		release: process.env.CI_TAG,

		// Filter out paths from tracing so they don"t eat through our budget
		tracesSampler: (samplingContext: any) => {
			const pathToIgnore = [
				"/healthcheck",
				"/api/auth/token",
				"*", // This is a bit misleading but is a specific transaction name, not all paths
			];
			const transactionName = samplingContext.transactionContext.name;
			if (pathToIgnore.some((path) => transactionName.includes(path))) {
				return 0;
			} else {
				return sentrySampleRate;
			}
		},
	};
};

/**
 * @param {Error} error
 * @param {Object} res
 */
export const configureScope = (error: any, res: any) => (scope: any) => {
	if (error && error.message) {
		// De-duplication currently doesn"t work correctly for SSR / browser errors
		// so we force deduplication by error message if it is present
		scope.setFingerprint([error.message]);
	}

	const statusCode = error && error.statusCode ?
		error.statusCode :
		res && res.statusCode ?
			res.statusCode :
			null;

	scope.setExtra("statusCode", statusCode);
};

/**
 * @param {Error} error Error Object
 * @param {Object} tags custom tags to pass to sentry as extra context
 * @param {Object} ctx contains the request, response, props and anything else passed by next.js context object
 */
export const captureException = ({ error = {}, tags = {}, ctx = {} }: { error?: any; tags?: any; ctx?: any } = {}) => {
	const { res } = ctx;

	const statusCode = error && error.statusCode ?
		error.statusCode :
		res && res.statusCode ?
			res.statusCode :
			null;

	const extraContext = {
		tags,
		fingerprint: error.message,
		level: "error",
		extra: {
			statusCode,
		},
	};

	if (!tags.function_name) {
		extraContext.tags.function_name = "unknown";
	}

	return Sentry.captureException(error, extraContext);
};
