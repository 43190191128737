import { API_BASES } from "@lib/constants";
import { getRequest } from "@lib/network/request";
import { GetTracksParams, LocationParams } from "@models/facets";
const urlPayload = (params: any) => new URLSearchParams(params);

export const getTrackRequest = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/tracks/${id}/`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getTrackQuery = ({
	id,
	accessToken,
	location,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
}) => ({
	queryKey: [`track-${id}`],
	queryFn: async () => {
		const { data } = await getTrackRequest({
			id,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getTracksRequest = async ({
	params,
	accessToken,
	location,
	withErrorHandling,
}: {
	params: GetTracksParams;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = "catalog/tracks/?" + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getTracksQuery = ({
	params,
	accessToken,
	location,
}: {
	params: GetTracksParams;
	accessToken?: string;
	location?: LocationParams;
}) => ({
	queryKey: ["tracks", params],
	queryFn: async () => {
		const { data } = await getTracksRequest({
			params,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getReleaseTracksRequest = async ({
	params,
	accessToken,
	releaseId,
	location,
	withErrorHandling,
}: {
	params: GetTracksParams;
	accessToken: string | undefined;
	releaseId: number;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/releases/${releaseId}/tracks/?` + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getReleaseTracksQuery = ({
	params,
	accessToken,
	releaseId,
	location,
}: {
	params: GetTracksParams;
	accessToken: string | undefined;
	releaseId: number;
	location?: LocationParams;
}) => ({
	queryKey: [`release-${releaseId}-tracks-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getReleaseTracksRequest({
			params,
			accessToken,
			releaseId,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getGenreTopTracksRequest = async ({
	params,
	accessToken,
	genreId,
	number,
	location,
	withErrorHandling,
}: {
	params: GetTracksParams;
	accessToken?: string;
	genreId: number;
	number: number;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/genres/${genreId}/top/${number}/?` + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getGenreTopTracksQuery = ({
	params,
	accessToken,
	genreId,
	number,
	location,
	enabled = true,
}: {
	params: GetTracksParams;
	accessToken?: string;
	genreId: number;
	number: number;
	location?: LocationParams;
	enabled?: boolean;
}) => ({
	queryKey: [`genre-${genreId}-top-${number}-tracks-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getGenreTopTracksRequest({
			params,
			accessToken,
			genreId,
			number,
			location,
			withErrorHandling: false,
		});
		return data;
	},
	enabled: enabled,
});

export const getTopTracksRequest = async ({
	params,
	accessToken,
	number,
	location,
	withErrorHandling,
}: {
	params: GetTracksParams;
	accessToken: string | undefined;
	number: number;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/tracks/top/${number}/?` + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getTopTracksQuery = ({
	params,
	accessToken,
	number,
	enabled = true,
	location,
}: {
	params: GetTracksParams;
	accessToken?: string;
	number: number;
	enabled?: boolean;
	location?: LocationParams;
}) => ({
	queryKey: [`top-${number}-tracks-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getTopTracksRequest({
			params,
			accessToken,
			number,
			location,
			withErrorHandling: false,
		});
		return data;
	},
	enabled: enabled,
});

export const getTrackStreamRequest = async ({
	params,
	id,
	accessToken,
	location,
}: {
	params: {
		start: number;
		end: number;
	};
	id: number;
	accessToken: string;
	location?: LocationParams;
}) => {
	const url = `catalog/tracks/${id}/stream/?${urlPayload(params)}`;
	return getRequest({ url, apiBase: API_BASES.api4, accessToken, location });
};

export const getTrackRecommendationsRequest = async ({
	id,
	accessToken,
	location,
	withErrorHandling,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/tracks/${id}/beatbot/?per_page=20`;
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getTrackRecommendationsQuery = ({
	id,
	accessToken,
	enabled = true,
	location,
}: {
	id: number;
	accessToken?: string;
	enabled?: boolean;
	location?: LocationParams;
}) => ({
	queryKey: [`track-${id}-recommendations`],
	queryFn: async () => {
		const { data } = await getTrackRecommendationsRequest({
			id,
			accessToken,
			location,
			withErrorHandling: false,
		});
		return data;
	},
	enabled: enabled,
});

export const getArtistTopTracksRequest = async ({
	params,
	accessToken,
	artistId,
	number,
	location,
	withErrorHandling,
}: {
	params: GetTracksParams;
	accessToken?: string;
	artistId: number;
	number: number;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url =
    `catalog/artists/${artistId}/top/${number}/?` + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getArtistTopTracksQuery = ({
	params,
	accessToken,
	artistId,
	number,
	location,
}: {
	params: GetTracksParams;
	accessToken?: string;
	artistId: number;
	number: number;
	location?: LocationParams;
}) => ({
	queryKey: [`artist-${artistId}-top-${number}-tracks-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getArtistTopTracksRequest({
			params,
			accessToken,
			artistId,
			number,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getLabelTopTracksRequest = async ({
	params,
	accessToken,
	labelId,
	number,
	location,
	withErrorHandling,
}: {
	params: GetTracksParams;
	accessToken?: string;
	labelId: number;
	number: number;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/labels/${labelId}/top/${number}/?` + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getLabelTopTracksQuery = ({
	params,
	accessToken,
	labelId,
	number,
	location,
}: {
	params: GetTracksParams;
	accessToken?: string;
	labelId: number;
	number: number;
	location?: LocationParams;
}) => ({
	queryKey: [`label-${labelId}-top-${number}-tracks-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getLabelTopTracksRequest({
			params,
			accessToken,
			labelId,
			number,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});

export const getChartTracksRequest = async ({
	params,
	accessToken,
	chartId,
	location,
	withErrorHandling,
}: {
	params: GetTracksParams;
	accessToken?: string;
	chartId: number;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	const url = `catalog/charts/${chartId}/tracks/?` + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken, location },
		withErrorHandling,
	);
};

export const getChartTracksQuery = ({
	params,
	accessToken,
	chartId,
	location,
}: {
	params: GetTracksParams;
	accessToken?: string;
	chartId: number;
	location?: LocationParams;
}) => ({
	queryKey: [`chart-${chartId}-tracks-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getChartTracksRequest({
			params,
			accessToken,
			chartId,
			location,
			withErrorHandling: false,
		});
		return data;
	},
});
