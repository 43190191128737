import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { MouseEventHandler } from "react";
import {
	PageSelectButtonWrapper,
	SimplePageSelectWrapper,
} from "./SimplePageSelect.style";

interface Props {
	page: number;
	onChange: (page: number) => void;
	totalResults: number;
	perPage: number;
}

const PageSelectButton = ({
	children,
	disabled,
	onClick,
}: {
	children: JSX.Element;
	disabled?: boolean;
	onClick: MouseEventHandler<HTMLButtonElement>;
}) => {
	return (
		<PageSelectButtonWrapper disabled={disabled} onClick={onClick}>
			{children}
		</PageSelectButtonWrapper>
	);
};

const SimplePageSelect: React.FC<Props> = ({
	page,
	totalResults,
	perPage,
	onChange,
}) => {
	const isLastPage = Math.floor(totalResults / perPage) === page + 1;

	return (
		<SimplePageSelectWrapper>
			<PageSelectButton
				disabled={page === 0}
				onClick={() => page > 0 && onChange(page - 1)}
			>
				<SpriteIcon id="arrow-back-bold" width="8" height="10" />
			</PageSelectButton>
			<PageSelectButton
				disabled={isLastPage}
				onClick={() => !isLastPage && onChange(page + 1)}
			>
				<SpriteIcon id="arrow-forward-bold" width="8" height="10" />
			</PageSelectButton>
		</SimplePageSelectWrapper>
	);
};

export default SimplePageSelect;
