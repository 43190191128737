import Button from "@components/Button";
import { Box } from "@components/core/Box";
import { Modal } from "@components/core/Modal";
import { useTranslation } from "next-i18next";

interface Props {
	show: boolean;
	header: string;
	isLoading?: boolean;
	confirmation: string;
	onClose: () => void;
	onDelete: () => void;
}

const DeleteConfirmationModal = (props: Props) => {
	const { onDelete, onClose, show, header, confirmation, isLoading } = props;
	const { t } = useTranslation("translation");

	return (
		<Modal
			show={show}
			onClose={() => onClose()}
			header={header}
			footer={(
				<Box
					p="0"
					gap="4"
					width="100"
					direction="row"
					alignItems="end"
					justifyContent="end"
				>
					<Button
						type="secondary"
						onClick={() => onClose()}
					>
						{t("Actions.Cancel")}
					</Button>
					<Button
						type="danger"
						disabled={isLoading}
						onClick={() => {
							onDelete();
						}}
					>
						{t("YesDelete")}
					</Button>
				</Box>
			)}
			showClose
		>
			<Box p="0">
				{confirmation}
			</Box>
		</Modal>
	);
};

export default DeleteConfirmationModal;
