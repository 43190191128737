import { API_BASES } from "@lib/constants";
import { LocationParams } from "@models/facets";
import { getRequest } from "./request";

export const AUDIO_FORMATS_URLS = {
	AUDIO_FORMATS: "/catalog/audio-formats/?per_page=30&person_preference_visibility=true",
};

export const getAudioFormatsRequest = async ({
	accessToken,
	location,
	withErrorHandling,
}: {
	accessToken: string;
	location?: LocationParams;
	withErrorHandling?: boolean;
}) => {
	return getRequest({ url: AUDIO_FORMATS_URLS.AUDIO_FORMATS, apiBase: API_BASES.api4, accessToken, location }, withErrorHandling);
};

export const getAudioFormatsQuery = ({
	accessToken,
	location,
}: {
	accessToken: string;
	location?: LocationParams;
}) => ({
	queryKey: ["audio-formats"],
	queryFn: async () => {
		const { data } = await getAudioFormatsRequest({ accessToken: accessToken || "", location });
		return data;
	},
});
