import styled from "styled-components";

export const GenreDropdownMenu = styled.div`
  position: absolute;
  top: 58px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 1px);
  background: ${(props) => props.theme.colors.neutrals.primary[900]};
  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.divider1};
  display: none;
  z-index: 900;
  width: 100%;
  max-width: 1440px;
  div {
    max-width: 1420px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 8px 24px;
    div {
      margin: 0px;
      width: 100%;
      max-width: 310px;
      padding: 0;
      ul {
        padding: 0 4px;
        width: 100%;
      }
      li {
        display: block;
        text-transform: capitalize;
        font-size: ${(props) => props.theme.fontSizes[5].size};
        font-weight: ${(props) => props.theme.fontWeights.normal};
        padding: 12px 0;
        border-bottom: 1px solid
          ${(props) => props.theme.colors.neutrals.secondary.gray.divider1};

        &:nth-child(1) {
          span {
            color: ${(props) => props.theme.colors.neutrals.primary.white};
            font-size: ${(props) => props.theme.fontSizes[1].size};
            line-height: ${(props) => props.theme.fontSizes[1].lineHeight};
            font-weight: ${(props) => props.theme.fontWeights.normal};
            text-transform: none;
          }
        }
        &:last-child {
          border-bottom: 0;
        }

        a:hover {
          color: ${(props) => props.theme.colors.green[300].accent};
        }
      }
    }
  }
`;
