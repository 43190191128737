import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  align-items: start;
  justify-content: center;
  overflow: hidden;
  padding-top: 50px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  opacity: 1;
  z-index: 400;
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  cursor: default;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;

export const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  gap: 10px;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  border-radius: 6px 6px 0px 0px;
`;

export const CloseButton = styled.a`
  padding: 4px;
  cursor: pointer;
`;

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
`;

export const Footer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  isolation: isolate;
  border-top: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  border-radius: 0px 0px 0px 0px;
`;

export const Container = styled.div<{ alignSelf?: string }>`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.neutrals.primary[800]};
  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  border-radius: 6px;
  min-width: 360px;
  animation: slide-down 0.2s ease 1;
  z-index: 1;
  box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);
  margin: 0 15px;

  ${({ alignSelf }) => alignSelf && css`align-self: ${alignSelf};`}

  &.no-style {
    background: transparent;
    border: 0;
    padding: 0;

    ${Content} {
      padding: 0;
    }
  }
`;
