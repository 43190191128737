import checkIfServer from "../src/lib/constants/check-if-server";
import ENV_COMMON from "./env.common.json";
import ENV_DEVELOPMENT from "./env.development.json";
import ENV_FEATURE from "./env.feature.json";
import ENV_PRODUCTION from "./env.production.json";
import ENV_STAGING from "./env.staging.json";
import ENV_UAT from "./env.uat.json";

import { getServerConfig } from "./server-env";

const isServer = checkIfServer();

const EnvConfig = {
	common: ENV_COMMON,
	development: ENV_DEVELOPMENT,
	feature: ENV_FEATURE,
	staging: ENV_STAGING,
	uat: ENV_UAT,
	production: ENV_PRODUCTION,
};
export type EnvName = keyof typeof EnvConfig;

/**
 * Retrieve the name of the currently running environment. This
 * function handles the underlying logic necessary to do this
 * both browser and server side.
 *
 * @returns a string containing the name of the environment.
 */
export const getEnv = () => {
	let env = process.env.ENV_NAME;

	// In next.js, env vars can only be made available to the browser app at build time.
	// Since we build the app once and deploy to multiple environments, we have the
	// server set a meta tag we can read to determine the environment.
	if (!isServer) {
		const element = document?.querySelector(
			// eslint-disable-next-line quotes
			'meta[name="bp-env"]'
		) as HTMLMetaElement;
		env = element?.content;
	}

	return env as EnvName | undefined;
};

/**
 * Retrieve the application config. When run server side, it will
 * also include the values defined in `server-env.ts`.
 *
 * @returns an object with the config values.
 */
export const getConfig = () => {
	const env = getEnv();
	const config = {
		...ENV_COMMON,
		...EnvConfig[env || "common"],
		...getServerConfig(),
	};

	// env is undefined at build time, and we don't want to spam the build logs
	if (isServer && env) {
		let key: keyof typeof config;
		for (key in config) {
			// Check for missing settings, but not keys that are there and set to boolean false.
			if (!config[key] && !config[key] === false) {
				// we can't use the logger here or it will create a circular reference
				console.error(`Configuration key '${key}' undefined`);
			}
		}
	}

	return config;
};
