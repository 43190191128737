/**
 * These values (and only these values) are parsed from the
 * server environment variables. They are only available to the
 * server app and won't be exposed to the browser.
 *
 * @returns an object containing the server defined environment variables
 */
export const getServerConfig = () => {
  const baseEnv = {
    NEXTAUTH_URL: "",
    NEXTAUTH_SECRET: "",
    API4_CLIENT_SECRET: "",
    CI_SHORT_SHA: "",
  };

  let obj = { ...baseEnv };
  let key: keyof typeof baseEnv;
  for (key in baseEnv) {
    obj[key] = process.env[key] || "";
  }

  return obj;
};
