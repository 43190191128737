import styled from "styled-components";

export const CopyBtn = styled.span`
  position: relative;
  background: ${(props) => props.theme.colors.legacy.greybtn2};
  border-radius: 4px;
  padding: 6px;
  transition: background 0.2s;

  &:hover {
    background: ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
    cursor: pointer;
  }
`;

export const LinkItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    ont-family: "Aeonik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: ${(props) => props.theme.colors.neutrals.primary.white};
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;

    input {
      padding: 4px 8px;
      width: 100%;
      background: #ffffff;

      border: 1px solid #d0d7de;
      border-radius: 4px;

      font-family: ${(props) => props.theme.fonts.aeonik};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8c8c8c;
    }
  }
`;

export const ShareLink = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  top: 25px;
  right: 0;
  width: 275px;
  background: ${(props) => props.theme.colors.neutrals.primary[800]};
  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.2));
  border-radius: 6px;
  z-index: 10;
  transition: opacity 0.3s;

  visibility: hidden;
  opacity: 1;
`;

export const Item = styled.a`
  position: relative;
  background: ${(props) => props.theme.colors.legacy.greybtn2};
  border-radius: 4px;
  padding: 6px;
  transition: background 0.2s;

  &.disabled {
    cursor: not-allowed;
  }

  &.enabled {
    &:hover {
      background: ${(props) =>
			props.theme.colors.neutrals.secondary.gray.stroke};
      cursor: pointer;

      ${ShareLink} {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
