import styled from "styled-components";

export const SelectWrapper = styled.div`
  margin: 8px 0 14px 0;

  &.italic-placeholder {
    select.placeholder, option.italic {
      font-style: italic;
      color: ${(props) => props.theme.colors.neutrals.primary.graytext};
    }
  }

  select {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 12px 7px 12px;
    gap: 8px;

    background: ${(props) => props.theme.colors.white[1]};
    color: black;
    border: 1px solid
      ${(props) => props.theme.colors.neutrals.secondary.gray.strple};
    border-radius: 4px;
    width: 100%;

    &:focus {
      outline: 2px solid ${(props) => props.theme.colors.green[300].accent};
      box-shadow: ${(props) => props.theme.boxShadows.green["1"]};
    }

    option {
      color: black;
      font-style: normal;
    }
  }
`;

export const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  color: ${(props) => props.theme.colors.semantic.danger};
`;

export const Description = styled.p`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
`;
