import {
	createContext,
	ReactElement,
	ReactNode,
	useContext,
	useReducer,
} from "react";
import { createActions } from "./actions";
import { collectionReducer } from "./reducer";
import { CollectionActions, CollectionState } from "./types";

const initialState: CollectionState = {
	playlist_queue: [],
	download_queue: [],
};

const CollectionStateContext = createContext<CollectionState>(initialState);

export const useCollectionState = (): CollectionState => {
	const ctx = useContext(CollectionStateContext);
	if (!ctx) {
		throw new Error("useCollection must be used within CollectionProvider");
	}
	return ctx;
};

const CollectionActionsContext = createContext<CollectionActions | null>(null);

export const useCollectionActions = (): CollectionActions => {
	const ctx = useContext(CollectionActionsContext);
	if (!ctx) {
		throw new Error(
			"useCollectionActions must be used within CollectionActionsContext",
		);
	}
	return ctx;
};

export type CollectionProviderProps = {
	children: ReactNode;
};
export const CollectionProvider: React.FC<CollectionProviderProps> = ({
	children,
}: CollectionProviderProps): ReactElement => {
	const [state, dispatch] = useReducer(collectionReducer, initialState);

	return (
		<CollectionStateContext.Provider value={state}>
			<CollectionActionsContext.Provider value={createActions(dispatch)}>
				{children}
			</CollectionActionsContext.Provider>
		</CollectionStateContext.Provider>
	);
};

export default CollectionStateContext;
