import Button from "@components/Button";
import { Modal } from "@components/core/Modal";
import { Trans, useTranslation } from "next-i18next";
import Link from "next/link";
import { ButtonWrapper, Message } from "./FinalRedownloadConfirmationModal.style";
import { MAX_FREE_DOWNLOADS } from "@lib/constants/subscriptions";

interface Props {
	show?: boolean;
	onClose: () => void;
	onConfirmReDownload: () => void;
}

const FinalRedownloadConfirmationModal: React.FC<Props> = ({
	show = false,
	onClose,
	onConfirmReDownload,
}) => {
	const { t } = useTranslation("translation");

	return (
		<Modal
			onClose={onClose}
			show={show}
			header="This is your final download"
			showClose
			alignSelf="center"
			footer={(
				<ButtonWrapper>
					<Button type="outline-secondary" onClick={onClose}>
						{t("Actions.Cancel")}
					</Button>
					<Button type="danger" onClick={onConfirmReDownload}>
						{t("Collection.AddToDownloads")}
					</Button>
				</ButtonWrapper>
			)}
		>
			<Message>
				<Trans
					i18nKey="Collection.UpgradeToDownloadMore"
					values={{ maxDownloads: MAX_FREE_DOWNLOADS }}
					components={[
						<Link key="subscription-link" href="/subscriptions" />,
					]}
				/>
			</Message>
		</Modal>
	);
};

export default FinalRedownloadConfirmationModal;
