import { useEffect, useRef } from "react";

// Source: https://usehooks.com/usePrevious/
const usePrevious = <T>(value: T) => {
	const ref = useRef<T>();
	useEffect(() => { ref.current = value; }, [value]);
	return ref.current as T;
};

export default usePrevious;
