export const AUTH_TOKEN_URL = "/auth/o/token/";
export const BEATPORT_URL = "https://support.beatport.com/";
export const BEATPORT_CUSTOMER_SUPPORT_URL = "https://support.beatport.com/hc/en-us";
export const BEATPORT_SERVICE_STATUS_URL = "https://status.beatport.com/";
export const BEATPORT_SUBSCRIPTION_SURVEY = "https://research.beatport.com/jfe/form/SV_8kUTXAIbW1Putnw?store=Beatport";
export const BEATPORT_FEEDBACK_SURVEY = "https://research.beatport.com/jfe/form/SV_3RfHegWHmyBPM4C?store=Beatport";
export const CONTENT_TYPES = {
	applicationJson: "application/json",
	formUrlEncoded: "application/x-www-form-urlencoded",
};
export const FORGOT_PASSWORD_SUCCESS_URL = "/account/forgot-password-success";
export const FORGOT_PASSWORD_URL = "/account/forgot-password";
export const HOME_URL = "/";
export const LOGIN_URL = "/account/login";
export const MAINTENANCE_URL = "/maintenance";
export const MY_SUBSCRIPTIONS_URL = "/my-subscriptions";
export const SUBSCRIPTIONS_SUCCESS_URL = "/subscriptions/success";
export const SIGNUP_URL = "/account/signup";
export const SUBSCRIPTIONS_URL = "/subscriptions";
export const THREEDS_ACTION_REQUIRED = "three_d_secure_action_required";
