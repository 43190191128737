import Button from "@components/Button";
import { FormButton } from "@components/core/Form";
import { Modal } from "@components/core/Modal";
import { LoginForm } from "@components/forms/LoginForm";
import { SIGNUP_URL } from "@lib/constants/urls";
import { signIn } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import NProgress from "nprogress";
import { useCallback, useEffect, useState } from "react";
import {
	ButtonWrapper,
	ContentWrapper,
	IntroText,
	Line,
	SignInBox,
	SignInText,
} from "./AuthModal.style";

interface Props {
	show?: boolean;
	next?: string;
	onSuccess: () => void;
	onClose: () => void;
}

const AuthModal: React.FC<Props> = ({
	onClose,
	onSuccess,
	show = false,
	next,
}) => {
	const { t } = useTranslation("translation");
	const [loading, setLoading] = useState(false);
	const [calledPush, setCalledPush] = useState(false);
	const [formErrors, setFormErrors] = useState<Record<string, Array<string>>>(
		{},
	);
	const router = useRouter();
	const FORM_ID = "login-form";

	const redirect = useCallback(
		(to: string) => {
			if (!calledPush) {
				setCalledPush(true);
				if (to === "/account/login") {
					router.push("/");
				} else {
					router.push(to);
				}
			}
		},
		[calledPush, router],
	);

	const handleOnSubmit = async (formData: Record<string, string>) => {
		NProgress.start();
		setLoading(true);

		const response = await signIn("beatport", {
			username: formData.username,
			password: formData.password,
			redirect: false,
		});

		if (!response?.ok) {
			setFormErrors({
				username: [`${t("Error.UsernamePassword")}`],
			});
		} else {
			// this is a hack that forces next session to refresh
			const event = new Event("visibilitychange");
			document.dispatchEvent(event);
			setTimeout(() => onSuccess(), 300);
			onClose();
			if (next && next !== "") {
				redirect(next);
			}
		}
		NProgress.done(false);
		setLoading(false);
	};

	const submitLogin = () => {
		const event = new Event("submit", { bubbles: true, cancelable: true });
		const formElement = document.getElementById(FORM_ID);
		if (formElement) {
			formElement.dispatchEvent(event);
		}
	};

	useEffect(() => {
		const formLinks = document.querySelectorAll(`#${FORM_ID} a`);
		formLinks.forEach((el) => {
			el.addEventListener(
				"click",
				(e: any) => {
					if (e.srcElement && e.srcElement.href) {
						e.preventDefault();
						redirect(e.srcElement.href);
					}
					setCalledPush(false);
					onClose();
				},
				false,
			);
		});

		return () =>
			formLinks.forEach((el) => {
				el.removeEventListener("click", () => onClose());
			});
	}, [onClose, redirect]);

	return (
		<Modal
			onClose={() => onClose()}
			show={show}
			header={t("Welcome")}
			showClose
			footer={(
				<ButtonWrapper>
					<div>
						<FormButton
							data-testid="login-button-login"
							onClick={submitLogin}
							type="button"
							value="Log in"
							disabled={loading}
						/>
					</div>
				</ButtonWrapper>
			)}
		>
			<ContentWrapper>
				<IntroText>
					{t("BeatportMusicStore")}
				</IntroText>
				<Button
					onClick={() => {
						redirect(SIGNUP_URL);
						setCalledPush(false);
						onClose();
					}}
					type="primary"
					width="100"
					data-testid="login-button-createaccount"
				>
					{t("Account.CreateAnAccount")}
				</Button>
				<SignInBox>
					<Line />
					<SignInText>{t("Account.AlreadyHaveAccount")}</SignInText>
					<Line />
				</SignInBox>
				<LoginForm
					formID={FORM_ID}
					onSubmit={handleOnSubmit}
					formErrors={formErrors}
					hideSubmit
				/>
			</ContentWrapper>
		</Modal>
	);
};

export default AuthModal;
