import { Modal } from "@components/core/Modal";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import Carts from "./tabs/Carts/Carts";
import Collections from "./tabs/Collections/Collections";
import Search from "./tabs/Search/Search";
import {
	Tab,
	TabContent,
	TabWrapper,
	Wrapper,
} from "./TrackSelectorModal.style";

interface Props {
	show?: boolean;
	selectedTracks?: number[];
	loadingTracks: number[];
	onClose?: () => void;
	onTrackSelected: (trackId: number) => void;
}

enum Tabs {
	COLLECTION = "Collection",
	CARTS = "Carts",
	SEARCH = "Search",
}

const TrackSelectorModal = (props: Props) => {
	const {
		show = false,
		selectedTracks,
		loadingTracks,
		onClose,
		onTrackSelected,
	} = props;
	const [selectedTab, setSelectedTab] = useState(Tabs.COLLECTION);
	const { t } = useTranslation("translation");

	const handleOnTrackSelected = async (trackId: number) => {
		await onTrackSelected(trackId);
	};

	const handleTabChange = (tabName: Tabs) => {
		setSelectedTab(tabName);
	};

	const renderTabContent = () => {
		if (selectedTab === Tabs.COLLECTION) {
			return (
				<Collections
					selectedTracks={selectedTracks || []}
					loadingTracks={loadingTracks}
					onTrackSelected={handleOnTrackSelected}
				/>
			);
		}
		if (selectedTab === Tabs.CARTS) {
			return (
				<Carts
					selectedTracks={selectedTracks || []}
					loadingTracks={loadingTracks}
					onTrackSelected={handleOnTrackSelected}
				/>
			);
		}
		if (selectedTab === Tabs.SEARCH) {
			return (
				<Search
					selectedTracks={selectedTracks || []}
					loadingTracks={loadingTracks}
					onTrackSelected={handleOnTrackSelected}
				/>
			);
		}
		return <></>;
	};

	const tabs = Object.values(Tabs);

	const closeModal = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<Modal show={show} onClose={closeModal} header={t("Actions.AddTracks")} showClose>
			<Wrapper>
				<TabWrapper>
					{tabs.map((tabName, tabIndex) => (
						<Tab
							key={`tab-${tabIndex}`}
							onClick={() => handleTabChange(tabName)}
							className={selectedTab === tabName ? "selected" : ""}
						>
							{tabName}
						</Tab>
					))}
				</TabWrapper>
				<TabContent>{renderTabContent()}</TabContent>
			</Wrapper>
		</Modal>
	);
};

export default TrackSelectorModal;
