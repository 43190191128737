import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;

  .controls {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`;

export const ActionToggle = styled.div`
  label {
    font-family: ${(props) => props.theme.fonts.aeonik};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.legacy.greybtn2};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: ${(props) => props.theme.colors.neutrals.primary[800]};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${(props) => props.theme.colors.green[300].accent};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${(props) => props.theme.colors.green[300].accent};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const ElementsWrapper = styled.div`
  padding-bottom: 16px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .input-wrapper {
    @media ${(props) => props.theme.device.md} {
      max-width: 370px;
    }
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 210px;
  gap: 0;

  div.row {
    display: flex;
    flex-direction: row;

    span {
      width: 50% !important;
    }
  }

  @media ${(props) => props.theme.device.sm} {
    width: 210px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding-bottom: 24px;

  @media ${(props) => props.theme.device.sm} {
    flex-direction: row;
  }
`;
