import { MenuIconProps } from "@bptypes/icons";
import { theme } from "@styles/theme";
import { HTMLAttributes } from "react";

/*
  Icon components in this file have dynamic elements to them and may rely on
  the react theme or other information for their presentation.

  Currently this is mainly the menu items.
*/

export const HeartIcon = (props?: MenuIconProps): JSX.Element => (
	<svg
		style={{ height: "13px", width: "14px" }}
		viewBox="0 0 14 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.9987 0C8.8387 0 7.72536 0.54 6.9987 1.39333C6.27203 0.54 5.1587 0 3.9987 0C1.94536 0 0.332031 1.61333 0.332031 3.66667C0.332031 6.18667 2.5987 8.24 6.03203 11.36L6.9987 12.2333L7.96536 11.3533C11.3987 8.24 13.6654 6.18667 13.6654 3.66667C13.6654 1.61333 12.052 0 9.9987 0ZM7.06537 10.3667L6.9987 10.4333L6.93203 10.3667C3.7587 7.49333 1.66536 5.59333 1.66536 3.66667C1.66536 2.33333 2.66536 1.33333 3.9987 1.33333C5.02536 1.33333 6.02536 1.99333 6.3787 2.90667H7.62536C7.97203 1.99333 8.97203 1.33333 9.9987 1.33333C11.332 1.33333 12.332 2.33333 12.332 3.66667C12.332 5.59333 10.2387 7.49333 7.06537 10.3667Z"
			fill={props?.active ? theme.colors.green[300].accent : "white"}
		/>
	</svg>
);

export const CollectionIcon = (props?: MenuIconProps): JSX.Element => {
	const stroke = props?.active ? theme.colors.green[300].accent : "white";

	return (
		<svg
			style={{ height: "16px", width: "16px" }}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.33203 4H13.9987"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.33203 8H13.9987"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.33203 12H13.9987"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2 4H2.00667"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2 8H2.00667"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2 12H2.00667"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

interface DownloadIconProps extends HTMLAttributes<SVGElement> {
	disabled?: boolean;
}
export const DownloadIcon = (props: DownloadIconProps): JSX.Element => (
	<svg
		style={{ height: "16px", width: "16px" }}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1 8.99984V11.6665C1 12.3998 1.6 12.9998 2.33333 12.9998H11.6667C12.0203 12.9998 12.3594 12.8594 12.6095 12.6093C12.8595 12.3593 13 12.0201 13 11.6665V8.99984M10.3333 4.99984L7 8.33317L3.66667 4.99984M7 7.53317V0.666504"
			stroke={props.disabled ? "#8C8C8C" : "#39C0DE"}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const DownloadsIcon = (props?: MenuIconProps): JSX.Element => (
	<svg
		style={{ height: "16px", width: "16px" }}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2 10.0003V12.667C2 13.4003 2.6 14.0003 3.33333 14.0003H12.6667C13.0203 14.0003 13.3594 13.8598 13.6095 13.6098C13.8595 13.3598 14 13.0206 14 12.667V10.0003M11.3333 6.00033L8 9.33366L4.66667 6.00033M8 8.53366V1.66699"
			stroke={props?.active ? theme.colors.green[300].accent : "white"}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const PlusIcon = (props?: MenuIconProps): JSX.Element => {
	const stroke = props?.active ? theme.colors.green[300].accent : "white";
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 3.33203V12.6654"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.33203 8H12.6654"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const PlaylistIcon = (props: MenuIconProps) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.33594 2L12.6693 8L3.33594 14V2Z"
			stroke={props?.active ? theme.colors.green[300].accent : "white"}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
