import { getConfig } from "config";

const EVENT_GTM_CONSENT_DEFAULT = "gtm_consent_default";
const EVENT_GTM_CONSENT_UPDATE = "gtm_consent_update";
const GTM_CONSENT_GRANTED = "granted";

export const getLastUserConsent = () => {
	const dataLayer: Array<any> = window.dataLayer || [];

	for (let i = dataLayer.length - 1; i >= 0; i--) {
		const entry = dataLayer[i];
		if (entry?.value?.event === EVENT_GTM_CONSENT_DEFAULT || entry?.value?.event === EVENT_GTM_CONSENT_UPDATE) {
			return entry.value;
		}
	}
	return null;
};

export const shouldAllowPAPTracking = () => {
	const lastUserConsent = getLastUserConsent();
	const config = getConfig();
	// Only allow PAP tracking if user has given consent via OneTrust
	if (config.AFFILIATE_TRACKING_ENABLED && lastUserConsent?.analytics_storage === GTM_CONSENT_GRANTED) return true;
	return false;
};
