import styled from "styled-components";

export const CalendarWrapper = styled.div`
  background: #141414;

  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  border-radius: 6px;
  filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.2));

  &.active {
    display: flex;
  }

  .rdp {
    margin: 0px;
  }
  .rdp-day_selected {
    background: ${(props) => props.theme.colors.green[300].accent};
    color: #000;
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    color: ${(props) => props.theme.colors.neutrals.primary.white} !important;
    background: ${(props) =>
		props.theme.colors.neutrals.secondary.gray.divider2} !important;
  }

  .rdp-caption_label {
    padding: 0 0.5em;
  }

  .rdp-tfoot {
    p {
      margin: 0 0.8em;
    }
  }

  .rdp-months,
  .rdp-month,
  .rdp-table {
    max-width: 100%;
    width: 100%;
  }
`;

export const Reset = styled.a`
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  padding: 0 16px;
  cursor: pointer;
  white-space: nowrap;
  &.disabled {
    display: none;
  }
`;

export const FilterFormFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 1rem 0 0 0;

  border-top: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};

  @media ${(props) => props.theme.device.lg} {
    padding: 1rem;
  }
`;

export const Field = styled.li`
  display: flex;
  width: 50%;
  float: left;
  white-space: pre-wrap;
`;

export const FilterFormBody = styled.div`
  width: 100%;
  max-height: 440px;
  overflow: auto;

  padding: 0;

  ul {
    width: 100%;
  }

  @media ${(props) => props.theme.device.lg} {
    padding: 16px;

    &.group-release-date {
      padding: 10px;
    }
  }
`;

export const ReleaseQuickFilter = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
  div > {
    cursor: pointer;
  }
`;

export const FilterForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 0;

  transition: opacity 0.3s, visibility 0.3s;
  width: 480px;

  background: ${(props) => props.theme.colors.neutrals.primary[800]};

  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  border-radius: 6px;
  filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.2));

  @media ${(props) => props.theme.device.lg} {
    top: 29px;
  }

  left: 0;
  z-index: 10;
  &.group-bpm {
    width: 350px;
  }

  &.artist-name,
  &.track-name {
    width: 240px;
  }
  &.track-name {
    width: 240px;
  }
  &.label-name {
    width: 240px;
  }
  &.group-release-date {
    width: 306px;
  }

  &.group-genre.modal,
  &.group-key.modal,
  &.group-sub_genre.modal,
  &.artist-name.modal {
    right: 0;
    left: auto;
  }
`;

export const MobileWrapper = styled.div`
  width: 100%;

  ${FilterForm} {
    visibility: visible;
    opacity: 1;
    position: inherit;
    border: 0;
    width: 100%;
    max-width: 380px;
    background: unset;

    &.group-label ul li {
      width: 100%;
    }
  }
`;

export const Filter = styled.a`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;

  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  border-radius: 4px;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.0025em;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};
  white-space: nowrap;
  scroll-snap-align: start;

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  &.mobile {
    flex-direction: column;
    background: unset;
    width: 100%;
    @media ${(props) => props.theme.device.lg} {
      flex-direction: row;
    }
  }

  &:hover {
    color: ${(props) => props.theme.colors.neutrals.primary.white};
    background: ${(props) =>
		props.theme.colors.neutrals.secondary.gray.divider2};
    cursor: pointer;
  }

  &.active {
    span {
      color: ${(props) => props.theme.colors.green[300].accent};
    }

    i.arrow {
      border-top: 4px solid ${(props) => props.theme.colors.green[300].accent};
    }
  }

  &:hover ${FilterForm} {
    visibility: visible;
    opacity: 1;
  }

  i.arrow {
    margin: 4px;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;

    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid
      ${(props) => props.theme.colors.neutrals.primary.graytext};
  }
`;

export const Filters = styled.div<{ hideOrderBy?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: ${(props) => (props.hideOrderBy ? "flex-start" : "center")};
  gap: 4px;
  &.isCollection {
    flex-wrap: nowrap;
  }
  &.search-filter {
    margin: 8px 0 14px 0;
  }
`;

export const Sorter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: ${(props) => props.theme.colors.neutrals.primary.graytext};

  span {
    padding-right: 8px;
  }
`;

export const Wrapper = styled.div<{ hideOrderBy?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${(props) => (props.hideOrderBy ? "8px 0 0 0" : "0")};
  &.isCollection {
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-moz-scrollbar {
      display: none;
    }
    margin-right: -25px;
  }

  @media ${(props) => props.theme.device.lg} {
    flex-direction: ${(props) => (props.hideOrderBy ? "row" : "row-reverse")};
    justify-content: space-between;
    align-items: ${(props) => (props.hideOrderBy ? "flex-start" : "center")};
    &.isCollection {
      overflow: initial;
    }
  }
`;

export const FilterCheckBoxLabelText = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 120px;

  @media ${(props) => props.theme.device.lg} {
    max-width: 160px;
  }
`;

export const FilterCheckBoxLabelCount = styled.span`
  margin-left: 3.5px;
`;
