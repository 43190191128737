import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { MY_BEATPORT_PREORDER_STORAGE_KEY } from "@lib/constants/storage-keys";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { device } from "@styles/theme";
import { useTranslation } from "next-i18next";
import { NextRouter, useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
	Container,
	DisabledPageLink,
	Page,
	PageNavItems,
	PerPage,
	PreOrderContainer,
	Title,
	Wrapper,
} from "./Pager.style";

const MAX_RESULT_WINDOW = 10000;

export const PER_PAGE_OPTIONS = [25, 50, 100, 150];
const NUMBER_OF_FLOATING_PAGES = 3; // Must be odd number

export const urlParamsToResetPagination = (router: NextRouter) => {
	const { page } = router.query;

	if (page && Number(page) > 1) {
		return { page: 1 };
	}

	return {};
};

interface Props {
	totalResults?: number;
	showPreordersFilter?: boolean;
	hideResultsPerPage?: boolean;
	defaultPerPage?: number;
	rememberPreOrders?: boolean;
}

const Pager: React.FC<Props> = ({
	totalResults = 0,
	showPreordersFilter,
	hideResultsPerPage,
	defaultPerPage,
	rememberPreOrders,
}) => {
	const isLg = useMediaQuery({ query: device.lg });
	const { t } = useTranslation("translation");
	const router = useRouter();

	const page = Number(router.query.page) || 1;
	const perPage = defaultPerPage ?? (Number(router.query.per_page) || PER_PAGE_OPTIONS[0]);
	const preOrdersQueryParam = router.query?.preorders ? router.query?.preorders === "true" : null;

	const [showPreOrders, setShowPreOrders] = useState(preOrdersQueryParam);

	const lastPage =
		totalResults && totalResults > 0 && totalResults < MAX_RESULT_WINDOW ?
			Math.ceil(totalResults / perPage) :
			Math.ceil(MAX_RESULT_WINDOW / perPage);

	const showPageNav = (totalResults || 0) > perPage;

	const updatePageQuery = (page: number, perPage: number) => {
		return {
			pathname: router.pathname,
			query: {
				...router.query,
				page,
				per_page: perPage,
			},
		};
	};

	const updatePerPageQuery = (perPage: number) => {
		return {
			pathname: router.pathname,
			query: {
				...router.query,
				page: 1,
				per_page: perPage,
			},
		};
	};

	useEffect(() => {
		setShowPreOrders(preOrdersQueryParam);
	}, [preOrdersQueryParam]);

	useEffect(() => {
		if (rememberPreOrders) {
			const lsShowPreorders = localStorage.getItem(MY_BEATPORT_PREORDER_STORAGE_KEY) === "true";
			if (showPreOrders === null) {
				setShowPreOrders(lsShowPreorders);
			}
		}
	}, []);

	const updatePreOrdersQuery = () => {
		return {
			pathname: router.pathname,
			query: {
				...router.query,
				page: 1,
				preorders: !showPreOrders,
			},
		};
	};

	const renderPageLink = ({ isLastPage, className, currentPageIteration }: { isLastPage: boolean; className: string; currentPageIteration: number }) => {
		if (isLastPage) {
			return (
				<DisabledPageLink className={className}>
					<span>{currentPageIteration}</span>
				</DisabledPageLink>
			);
		}

		return (
			<Page className={className} href={updatePageQuery(currentPageIteration, perPage)}>
				<span>{currentPageIteration}</span>
			</Page>
		);
	};
	const renderPages = () => {
		const isPastFirstPage = page > 1;
		const isAtLastPage = page === lastPage;
		return (
			<Container className="pages">
				{isPastFirstPage && (
					<>
						<Page href={updatePageQuery(page - 1, perPage)}>
							<SpriteIcon id="arrow-back" width="8" height="14" /> <span>{t("Actions.Previous")}</span>
						</Page>
					</>
				)}

				{
					new Array(lastPage).fill(0).map((_, i) => {
						const currentPageIteration = i + 1;
						const isFirstPage = currentPageIteration === 1;
						const isLastPage = currentPageIteration === lastPage;

						const isOnEdgePage = page === 1 || page === lastPage;
						const isOnActivePage = page === currentPageIteration;

						const floatingPagesRange = isOnEdgePage ?
							NUMBER_OF_FLOATING_PAGES - 1 :
							Math.floor(NUMBER_OF_FLOATING_PAGES / 2);
						const isWithinActivePageRange = currentPageIteration >= page - floatingPagesRange && currentPageIteration <= page + floatingPagesRange;
						const isTooFarFromFirstPage = isFirstPage && page > 1 + Math.ceil(NUMBER_OF_FLOATING_PAGES / 2);
						const isTooFarFromLastPage = isLastPage && page < lastPage - Math.ceil(NUMBER_OF_FLOATING_PAGES / 2);
						const key = `pager_page-${i}`;
						const Ellipsis = () => <span>...</span>;

						if (!isWithinActivePageRange && !isFirstPage && !isLastPage) {
							return null;
						}

						let className = isOnActivePage ? "active" : "";
						if (isLastPage) {
							className = isOnActivePage ? "active" : "disabled";
						}

						return (
							<PageNavItems key={key}>
								{isTooFarFromLastPage && <Ellipsis />}
								{renderPageLink({ isLastPage, className, currentPageIteration })}
								{isTooFarFromFirstPage && <Ellipsis />}
							</PageNavItems>
						);
					})
				}

				{!isAtLastPage && (
					<Page href={updatePageQuery(page + 1, perPage)}>
						<span>{t("Actions.Next")}</span> <SpriteIcon id="arrow-forward" width="8" height="14" />
					</Page>
				)}
			</Container>
		);
	};

	return (
		<Wrapper>
			{!isLg && showPageNav && renderPages()}
			{!hideResultsPerPage && (
				<Container>
					<Title>{t("ResultsPerPage")}</Title>
					{
						PER_PAGE_OPTIONS.map((perPageOption, index) => {
							const isFirstOption = index === 0;
							const resultsExceedPreviousOption = totalResults > PER_PAGE_OPTIONS[index - 1];

							if (isFirstOption || (!isFirstOption && resultsExceedPreviousOption)) {
								return (
									<PerPage
										key={`result_per_page-${perPageOption}`}
										className={perPage === perPageOption ? "active" : undefined}
										href={updatePerPageQuery(perPageOption)}
									>
										{perPageOption}
									</PerPage>
								);
							}
						})
					}
				</Container>
			)}

			{showPreordersFilter && (
				<PreOrderContainer>
					<Title>{t("PreOrder")}</Title>
					<PerPage
						onClick={() => {
							if (rememberPreOrders) {
								localStorage.setItem(MY_BEATPORT_PREORDER_STORAGE_KEY, (!showPreOrders).toString());
							}
						}}
						href={updatePreOrdersQuery()}
					>
						{showPreOrders ? t("Actions.On") : t("Actions.Off")}
					</PerPage>
				</PreOrderContainer>
			)}
			{isLg && showPageNav && renderPages()}
		</Wrapper>
	);
};

export default Pager;
