import styled from "styled-components";

export const Spacer = styled.div`
  width: 12px;
`;

export const TrackNo = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 36px;
  min-width: 36px;
`;

export const ItemNumber = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 36px;
`;

export const Exclusive = styled.span`
  right: 0;
  top: 0;
  height: 14px;
  padding: 0 1px;
  font-size: 7px;
  background: ${(props) => props.theme.colors.green[300].accent};
  width: 100%;
  color: #000;
  position: absolute;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
`;

export const ReleaseName = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.neutrals.primary.white};

  span {
    font-weight: 400;
  }
`;

export const ArtworkAndControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  a.artwork {
    position: relative;
  }
`;

export const Wrapper = styled.div`
  .fade {
    opacity: 0.25;
    transition: opacity 0.2s ease-in-out;
  }

  .row {
    justify-content: flex-start;

    &:hover .fade {
      opacity: 1;
    }

    &.current {
      background: ${(props) =>
			props.theme.colors.neutrals.secondary.gray.active};
    }
  }

  .cell {
    padding-top: 0;
    padding-bottom: 0;

    a:hover {
      text-decoration: underline;
    }
  }

  .controls {
    padding-left: 0;
    width: 120px;
    min-width: 120px;
  }

  .add-tracks-controls {
    padding-left: 0;
    width: 40px;
    min-width: 40px;
    padding: 0px;
  }

  .title {
    width: 100%;
    overflow: hidden;

    .container {
      overflow: hidden;
      > div,
      > a > span {
        overflow: hidden;
      }
    }
  }
  .label {
    width: 200px;
    min-width: 200px;
  }

  .date {
    width: 120px;
    min-width: 120px;
  }

  .bpm {
    width: 150px;
    min-width: 150px;
  }

  .genre {
    width: 100%;

    > a > span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      white-space: pre-wrap;
    }
  }

  .cart-actions {
    width: 200px;
    min-width: 200px;
    display: flex;
    justify-content: flex-end;
  }

  .download-actions {
    display: flex;
    gap: 1.25rem;
    text-transform: none;
  }

  .card {
    width: 100px;
    min-width: 100px;
    display: flex;
    justify-content: flex-end;
  }

  &.numbers {
    .controls {
      width: 165px;
      min-width: 165px;
    }
  }

  span.tombstoned {
    opacity: 0.15;
  }
`;

export const TrackAddIndicator = styled.div`
  span.arrow {
    background-color: rgb(0, 0, 0, 0);
    border-radius: 0px 4px 4px 0px;
    padding: 4px;
    margin-right: 31px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    i {
      margin: 4px;
      display: inline-block;
      vertical-align: middle;

      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid
        ${(props) => props.theme.colors.neutrals.primary.white};
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
