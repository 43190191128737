import styled, { css, keyframes } from "styled-components";

const SPACE_BETWEEN_MARQUEES = 16; // 16px = 1rem
const SCROLL_DELAY = 0.75; // seconds

const calculateMarqueeScrollTime = (elementWidth: number) => elementWidth / 20;

const animationMarqueeScrolling = (elementWidth: number, scrollTime: number) => {
	const delayPercentage = (SCROLL_DELAY / scrollTime) * 100;

	return keyframes`
        0%, ${delayPercentage}% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-${elementWidth + SPACE_BETWEEN_MARQUEES}px);
        }
    `;
};

export const MarqueeElement = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
`;

export const MarqueeWrapper = styled.div<{
	$isActive: boolean;
	$elementWidth: number;
	$shouldWrap: boolean;
	$maxWidth: string | undefined;
}>`
    overflow: hidden;
    width: 100%;

    ${({ $maxWidth }) => $maxWidth && css`
        max-width: ${$maxWidth};
    `};

    & > ${MarqueeElement} {
        display: inline-block;
        
        &:first-of-type {
            width: fit-content;
        }

        &:nth-child(2) {
            margin-left: ${SPACE_BETWEEN_MARQUEES}px;
            visibility: hidden;
        }
    }

    ${({ $shouldWrap }) => $shouldWrap && css`
        white-space: nowrap;
        & > ${MarqueeElement} { white-space: nowrap;}
    `};

    ${({ $isActive, $elementWidth }) => {
		if ($isActive) {
			const scrollTime = calculateMarqueeScrollTime($elementWidth);

			return css`
                &:hover > ${MarqueeElement} {
                    animation-delay: 5s;
                    animation: ${animationMarqueeScrolling($elementWidth, scrollTime)} ${scrollTime}s linear infinite;
                    
                    visibility: visible;
                }
            `;
		}
	}};
`;
