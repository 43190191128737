/* eslint-disable @typescript-eslint/no-unused-vars */
import { Cart } from "@models/Cart";
import { Track } from "@models/track";
import React from "react";

const LayoutContextDefaultValue = {
	showSidebar: false,
	setShowSidebar: (value: boolean) => {},
	authenticateLink: (next: string, onSuccess?: () => void) => {},
	showCartSelector: ({
		show, top, left, carts, selectedCarts, onSelectedCart,
	}: {
		show: boolean;
		top: number;
		left: number;
		carts: Cart[];
		selectedCarts: number[];
		onSelectedCart: (cartId: number) => void;
	}) => {},
	showUpgradeTrackSelector: ({
		show, top, left, carts, handleTrackUpgradeClick,
	}: {
		show: boolean;
		top: number;
		left: number;
		carts: Cart[];
		handleTrackUpgradeClick: (cartId: number, audioFormatId: number) => void;
	}) => {},
	showAddToPlaylist: ({ id, type, tracks }: { id: number; type: string; tracks?: Track[] }) => {},
};

export const LayoutContext = React.createContext(LayoutContextDefaultValue);
