import {
	SET_ALL_STAGES,
	SET_CURRENT_STAGE,
	SET_CURRENT_STREAM,
	SET_IS_EXPANDED,
	TwitchLiveStreamState,
} from "./types";

// Define the reducer
export const twitchLiveStreamReducer = (
	state: TwitchLiveStreamState,
	action: any,
): TwitchLiveStreamState => {
	switch (action.type) {
		case SET_CURRENT_STAGE:
			return { ...state, currentStage: action.payload };
		case SET_ALL_STAGES:
			return { ...state, allStages: action.payload, currentStage: action.payload[0] };
		case SET_IS_EXPANDED:
			return { ...state, isExpanded: action.payload };
		case SET_CURRENT_STREAM:
			return { ...state, currentStream: action.payload };
		default:
			return state;
	}
};
