import { createContext, ReactElement, useState } from "react";

interface ChartsContextValue {
	chartsLoading: boolean;
	setChartsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	isPublished: boolean;
	setIsPublished: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChartsContextDefaultValue = {
	chartsLoading: false,
	setChartsLoading: () => {},
	isPublished: true,
	setIsPublished: () => {},
};

export const ChartsContext = createContext<ChartsContextValue>(ChartsContextDefaultValue);

export type ChartsProviderProps = {
	children: React.ReactNode;
};

export const ChartsProvider: React.FC<ChartsProviderProps> = ({
	children,
}: ChartsProviderProps): ReactElement => {
	const [chartsLoading, setChartsLoading] = useState<boolean>(false);
	const [isPublished, setIsPublished] = useState<boolean>(true);
	return (
		<ChartsContext.Provider value={{ chartsLoading, setChartsLoading, isPublished, setIsPublished }}>
			{children}
		</ChartsContext.Provider>
	);
};
