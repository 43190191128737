export enum SidebarIconKey {
	Heart = "heart",
	Collection = "collection",
	Downloads = "downloads",
	Playlists = "playlists",
}

export type SidebarLinkProps = {
	label: string;
	href: string;
	icon?: SidebarIconKey;
	action?: () => void;
	cypressTag: string;
	requireAuth: boolean;
};
