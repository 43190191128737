import Button from "@components/Button";
import { BEATPORT_SERVICE_STATUS_URL, HOME_URL } from "@lib/constants/urls";
import { i18n } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import {
	ErrorButtonsContainer,
	ErrorContainer,
	ErrorDescription,
	ErrorImage,
	ErrorTitle,
} from "./Error.style";
import errorConfig from "./error.config";

enum ErrorTypes {
	Maintenance = "maintenance",
	General = "general",
	NotFound = "404",
	ServerError = "500",
}

interface Props {
	errorType?: ErrorTypes;
	onTryAgain?: () => void;
}

const Error = ({
	errorType = ErrorTypes.General,
	onTryAgain = () => {},
}: Props) => {
	const router = useRouter();
	const errorDetails = errorConfig[errorType || ErrorTypes.NotFound];
	const errorImage = errorDetails.image;

	const hasTryAgainButton = errorDetails.buttons.includes("try-again");
	const hasGoHomeButton = errorDetails.buttons.includes("go-home");
	const hasServiceStatusButton = errorDetails.buttons.includes("service-status");

	useEffect(() => {
		const handleRouteChangeComplete = () => {
			onTryAgain();
		};

		router.events.on("routeChangeComplete", handleRouteChangeComplete);

		return () => {
			router.events.off("routeChangeComplete", handleRouteChangeComplete);
		};
	}, [router, onTryAgain]);

	return (
		<ErrorContainer>
			<ErrorImage src={errorImage} />
			<ErrorTitle>{errorDetails.title}</ErrorTitle>
			<ErrorDescription>{errorDetails.description}</ErrorDescription>
			<ErrorButtonsContainer>
				{
					hasTryAgainButton && (
						<Button
							type="primary"
							onClick={onTryAgain}
						>
							{i18n?.t("Error.PleaseTryAgain") || "Please Try Again"}
						</Button>
					)
				}
				{
					hasGoHomeButton && (
						<Button
							type={hasTryAgainButton ? "outline" : "primary"}
							onClick={() => router.push(HOME_URL)}
						>
							{i18n?.t("Error.ReturnToHomepage") || "Return to Homepage"}
						</Button>
					)
				}
				{
					hasServiceStatusButton && (
						<Button
							type="outline"
							onClick={() => router.push(BEATPORT_SERVICE_STATUS_URL)}
						>
							{i18n?.t("Error.ServiceStatus") || "Service Status"}
						</Button>
					)
				}
			</ErrorButtonsContainer>
		</ErrorContainer>
	);
};

export { ErrorTypes };
export default Error;
