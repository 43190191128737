import { API_BASES } from "@lib/constants";
import { getRequest } from "@lib/network/request";
import { LocationParams } from "@models/facets";
const urlPayload = (params: any) => new URLSearchParams(params);

const GENRE_REQUEST_PARAMS = { page: 1, per_page: 100 };

export const getGenreRequest = async (
	{
		genreId,
		withErrorHandling,
		accessToken,
	}: {
		genreId: number;
		withErrorHandling?: boolean;
		accessToken: string | undefined;
	},
) => {
	const url = `/catalog/genres/${genreId}/`;
	return getRequest({ url, apiBase: API_BASES.api4, accessToken }, withErrorHandling);
};

export const getGenreQuery = ({ genreId, accessToken }: { genreId: number; accessToken?: string }) => ({
	queryKey: [`genre-${genreId}`],
	queryFn: async () => {
		const { data } = await getGenreRequest({ genreId, withErrorHandling: false, accessToken });
		return data;
	},
});

export const getGenresRequest = async (
	{ params, accessToken, withErrorHandling }: {
		params: {
			name?: string;
			enabled?: boolean;
			order_by?: string;
			page?: number;
			per_page?: number;
		}; accessToken: string | undefined; withErrorHandling?: boolean;
	},
) => {
	const url = "/catalog/genres/?" + urlPayload(params);
	return getRequest({ url, apiBase: API_BASES.api4, accessToken }, withErrorHandling);
};

export const getGenrePageModuleIdRequest = async ({
	id,
	accessToken,
	location,
}: {
	id: number;
	accessToken?: string;
	location?: LocationParams;
}) => {
	const url = `/curation/pages/?page_type_name=genre&source_type_name=sushi&item_id=${id}`;
	return getRequest({ url, apiBase: API_BASES.api4, accessToken, location });
};

export const getGenresQuery = (accessToken: string | undefined) => ({
	queryKey: [`genres-${urlPayload(GENRE_REQUEST_PARAMS)}`],
	queryFn: async () => {
		const { data } = await getGenresRequest({ params: GENRE_REQUEST_PARAMS, accessToken, withErrorHandling: false });
		return data;
	},
});

export const getSubGenresRequest = async (
	accessToken: string,
	withErrorHandling?: boolean,
) => {
	const url = "/catalog/sub-genres/?" + urlPayload(GENRE_REQUEST_PARAMS);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken },
		withErrorHandling,
	);
};

export const getSubGenresQuery = (accessToken: string) => ({
	queryKey: [`sub-genres-${urlPayload(GENRE_REQUEST_PARAMS)}`],
	queryFn: async () => {
		const { data } = await getSubGenresRequest(accessToken || "", false);
		return data;
	},
});

export const getKeysRequest = async (
	{ params, accessToken, withErrorHandling }: {
		params: {
			page: number;
			per_page: number;
		}; accessToken: string; withErrorHandling?: boolean;
	},
) => {
	const url = "/catalog/keys/?" + urlPayload(params);
	return getRequest(
		{ url, apiBase: API_BASES.api4, accessToken },
		withErrorHandling,
	);
};

export const getKeysQuery = (
	params: {
		page: 1;
		per_page: 34;
	},
	accessToken: string,
) => ({
	queryKey: [`keys-${urlPayload(params)}`],
	queryFn: async () => {
		const { data } = await getKeysRequest({ params, accessToken: accessToken || "", withErrorHandling: false });
		return data;
	},
});
