import styled from "styled-components";

export const IntroText = styled.p`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 18px;

  display: flex;
  align-items: center;
  letter-spacing: -0.006em;
`;

export const SignInBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 100%;
`;

export const SignInText = styled.div`
  width: 164px;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: ${(props) => props.theme.colors.neutrals.secondary.gray.divider2};
`;
export const Line = styled.hr`
  width: 60px;
  color: ${(props) => props.theme.colors.neutrals.secondary.gray.divider2};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: -16px;
  display: flex;
  justify-content: end;

  div {
    width: initial;
  }
`;

export const ContentWrapper = styled.div`
  width: 320px;

  @media ${(props) => props.theme.device.sm} {
    width: 460px;
  }
`;
