import styled, { css } from "styled-components";

export const Control = styled.button`
  display: flex;
  align-items: center;
  position: relative;

  &:hover svg {
    ${(props) => css`
      filter: drop-shadow(0 0 7px ${props.theme.colors.neutrals.primary.white});
      fill: ${props.theme.colors.neutrals.primary.white};
    `};
  }

  &:disabled svg {
    filter: unset;
  }
`;
