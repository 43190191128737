import { TooltipContainer, Wrapper } from "./Tooltip.style";

interface Props {
	text: string;
	children: React.ReactNode[] | React.ReactNode;
	position?: "top" | "bottom" | "left" | "right" | "top-left" | "top-right";
	[key: string]: any;
}

const Tooltip: React.FC<Props> = ({ text, children, position = "top", ...extra }) => {
	return (
		<Wrapper {...extra}>
			{children}
			<TooltipContainer
				data-testid="tooltip"
				className={position}
			>
				{text}
			</TooltipContainer>
		</Wrapper>
	);
};

export default Tooltip;
