import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  input {
    height: 14px;
    margin: 10px;
    background: ${(props) => props.theme.colors.white[1]};
    color: black;
    border: 1px solid
      ${(props) => props.theme.colors.neutrals.secondary.gray.strple};
    border-radius: 4px;
  }
`;

export const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  color: ${(props) => props.theme.colors.semantic.danger};
`;

export const Label = styled.label`
  display: inline-flex;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`;
