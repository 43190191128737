import { MenuIconProps } from "@bptypes/icons";
import { SidebarIconKey, SidebarLinkProps } from "@bptypes/sidebar";
import Button from "@components/Button";
import Marquee from "@components/Marquee";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import {
	CollectionIcon,
	DownloadsIcon,
	HeartIcon,
	PlaylistIcon,
	PlusIcon,
} from "@components/core/icons/ThemedIcons";
import {
	HEADER_MENU_ITEMS,
	ONE_DAY_SECONDS,
	SIDEBAR_LINKS,
} from "@lib/constants";
import { LayoutContext } from "@lib/context/layout/LayoutContext";
import { useSessionContext } from "@lib/context/session";
import { SIDEBAR_DESKTOP_WIDTH_NUMBER } from "@lib/css";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { getGenresQuery } from "@lib/network/genres";
import { getMyPlaylistsInfiniteQuery } from "@lib/network/playlists";
import { Genre } from "@models/Genre";
import { Playlist } from "@models/Playlists";
import { HeaderMenuItem } from "@models/constants";
import { PaginatedResponse } from "@models/generics";
import { device } from "@styles/theme";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { Trans, useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, {
	FunctionComponent,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import {
	CreatePlaylist,
	DesktopSidebar,
	HorizontalBar,
	MobileSidebar,
	MobileSidebarDivider,
	MobileSubMenuContent,
	MobileSubMenuWrapper,
	PlaylistElement,
	PlaylistSectionContainer,
	PlaylistSectionContent,
	SidebarExternalLinkElement,
	SidebarLink,
	SidebarLinkActiveBar,
	SubSectionContainer,
} from "./Sidebar.style";

const IconMap: { [key in SidebarIconKey]: FunctionComponent<MenuIconProps> } = {
	[SidebarIconKey.Heart]: HeartIcon,
	[SidebarIconKey.Collection]: CollectionIcon,
	[SidebarIconKey.Downloads]: DownloadsIcon,
	[SidebarIconKey.Playlists]: PlaylistIcon,
};

const SidebarLinkComponent = ({
	link,
	genre,
	guard,
}: {
	link: SidebarLinkProps;
	genre?: boolean;
	guard?: (e: any, link: string) => void;
}) => {
	const router = useRouter();

	const isLinkActive = router.pathname === link.href;
	return (
		<Link href={link.href} prefetch={false} legacyBehavior>
			<SidebarLink
				className={genre ? "genre" : ""}
				data-testid={link.cypressTag}
				onClick={(e) =>
					link.requireAuth && guard ? guard(e, link.href) : undefined}
				href={link.href}
			>
				{isLinkActive && <SidebarLinkActiveBar />}
				{link.icon && IconMap[link.icon]({ active: isLinkActive })}
				<Trans>{link.label}</Trans>
			</SidebarLink>
		</Link>
	);
};

const SidebarExternalLink = (props: { item: HeaderMenuItem }) => {
	const { item } = props;
	return (
		<li className="header_item">
			<SidebarExternalLinkElement
				target="_blank"
				href={item.href}
				rel="noopener noreferrer"
				data-testid={item.cypressTag}
			>
				<span>{item.label}</span>
				<SpriteIcon id="menu-arrow" />
			</SidebarExternalLinkElement>
		</li>
	);
};

const PlaylistSection: React.FC<{
	playlists: Playlist[];
	guard?: (e: any, path: string) => void;
	onLoadMore?: () => void;
}> = ({ playlists, guard, onLoadMore }) => {
	const { t } = useTranslation("translation");
	const listInnerRef = useRef<HTMLDivElement>(null);

	const onScroll = () => {
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			// load more only when we are at the end of scroll area
			if (scrollTop + 1 >= scrollHeight - clientHeight) {
				if (onLoadMore) {
					onLoadMore();
				}
			}
		}
	};

	useEffect(() => {
		if (playlists.length > 0 && listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			// load more only when page height is higher than current list
			if (scrollTop === 0 && clientHeight === scrollHeight) {
				if (onLoadMore) {
					onLoadMore();
				}
			}
		}
	}, [onLoadMore, playlists.length]);

	return (
		<PlaylistSectionContainer>
			<div className="wrapper">
				<PlaylistSectionContent className="content">
					<CreatePlaylist
						className="element extra-margin"
						data-testid="sidebar-create-playlist"
					>
						<Link
							href="/library/playlists/new"
							prefetch={false}
							onClick={(e) =>
								guard ? guard(e, "/library/playlists/new") : undefined}
						>
							<PlusIcon active />
							{t("Playlists.Create")}
						</Link>
					</CreatePlaylist>
				</PlaylistSectionContent>
				<PlaylistSectionContent
					className="scrollable"
					onScroll={onScroll}
					ref={listInnerRef}
				>
					<ul>
						{playlists?.map((playlist, i) => (
							<PlaylistElement
								className="element extra-margin"
								key={`sidebar_playlist_element_${i}`}
								data-testid="sidebar-playlist-element"
							>
								<Link
									href={`/library/playlists/${playlist.id}`}
									prefetch={false}
								>
									<Marquee>
										{playlist.name}
									</Marquee>
								</Link>
							</PlaylistElement>
						))}
					</ul>
				</PlaylistSectionContent>
			</div>
		</PlaylistSectionContainer>
	);
};

const BackButton: React.FC<{
	children: React.ReactNode;
	onClick: React.MouseEventHandler<HTMLAnchorElement>;
}> = ({ children, onClick }) => {
	return (
		<SidebarLink onClick={onClick}>
			<SpriteIcon id="arrow-back" width="8" height="14" />
			{children}
		</SidebarLink>
	);
};

const MobileGenres: React.FC<{
	onClose: React.MouseEventHandler<HTMLAnchorElement>;
	setShowSidebar: (value: boolean) => void;
}> = ({ onClose, setShowSidebar }) => {
	const { getAccessToken } = useSessionContext();
	const accessToken = getAccessToken();
	const { t } = useTranslation("translation");
	const { data } = useQuery({
		...getGenresQuery(accessToken),
		staleTime: ONE_DAY_SECONDS,
		cacheTime: ONE_DAY_SECONDS,
	});

	const genres = (data?.results || []) as Genre[];

	return (
		<SubSectionContainer>
			<BackButton onClick={onClose}>{t("Genres")}</BackButton>
			<MobileSubMenuContent>
				<ul>
					{genres?.map((genreItem) => {
						const link: SidebarLinkProps = {
							href: `/genre/${genreItem.slug}/${genreItem.id}`,
							label: genreItem.name,
							cypressTag: `header-subnav-link-genre-${genreItem.id}`,
							requireAuth: false,
						};
						return (
							<li
								key={`sidebar_genre_${genreItem.id}`}
								onClick={() => setShowSidebar(false)}
							>
								<SidebarLinkComponent link={link} genre />
							</li>
						);
					})}
				</ul>
			</MobileSubMenuContent>
		</SubSectionContainer>
	);
};

const Sidebar = ({ showDesktop = false }) => {
	const isDesktop = useMediaQuery({ query: device.xl });
	const isSm = useMediaQuery({ query: device.sm });
	const { t } = useTranslation("translation");
	const [showGenresSubMenu, setShowGenresSubMenu] = useState(false);
	const { getAccessToken, getIsSessionValid, session } = useSessionContext();
	const { showSidebar, setShowSidebar, authenticateLink } =
    useContext(LayoutContext);

	const router = useRouter();
	const accessToken = getAccessToken();
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });
	const subscribed = isLoggedInSessionValid && session?.introspect?.subscription !== null;

	const sidebarRef = useRef<HTMLDivElement>(null);
	const [isResizing, setIsResizing] = useState(false);
	const [sidebarWidth, setSidebarWidth] = useState(
		SIDEBAR_DESKTOP_WIDTH_NUMBER,
	);

	const startResizing = React.useCallback(() => {
		setIsResizing(true);
	}, []);

	const stopResizing = React.useCallback(() => {
		setIsResizing(false);
	}, []);

	const resize = React.useCallback(
		(mouseMoveEvent: any) => {
			if (isResizing && sidebarRef.current) {
				setSidebarWidth(
					mouseMoveEvent.clientX -
					sidebarRef.current.getBoundingClientRect().left,
				);
			}
		},
		[isResizing],
	);

	const {
		data: playlistsData,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
	} = useInfiniteQuery<PaginatedResponse<Playlist>>(
		getMyPlaylistsInfiniteQuery({
			params: { page: 1, per_page: 50 },
			accessToken: accessToken,
			enabled: isLoggedInSessionValid,
		}),
	);

	const pagedResults = playlistsData?.pages?.map((page) => page.results) || [];
	const myPlaylists: Playlist[] = pagedResults.flat(1);

	const guardLink = (e: any, path: string) => {
		if (isLoggedInSessionValid) {
			return false;
		}

		e.preventDefault();

		if (router.asPath !== path) {
			authenticateLink(path);
		}
	};

	useEffect(() => {
		window.addEventListener("mousemove", resize);
		window.addEventListener("mouseup", stopResizing);
		return () => {
			window.removeEventListener("mousemove", resize);
			window.removeEventListener("mouseup", stopResizing);
		};
	}, [resize]);

	return isDesktop && showDesktop ?
			(
				<DesktopSidebar
					ref={sidebarRef}
					style={{ width: sidebarWidth }}
					onMouseDown={(e) => e.preventDefault()}
				>
					<div className="sidebar-content">
						<ul aria-label="sidebar">
							{SIDEBAR_LINKS.map((link, i) => {
								return (
									<li key={`sidebar_link_${i}`}>
										<SidebarLinkComponent link={link} guard={guardLink} />
									</li>
								);
							})}
						</ul>
						<HorizontalBar />
						<PlaylistSection
							playlists={myPlaylists}
							guard={guardLink}
							onLoadMore={() => {
								if (!isFetchingNextPage && hasNextPage) {
									fetchNextPage();
								}
							}}
						/>
					</div>
					<div className="sidebar-resizer" onMouseDown={startResizing} />
				</DesktopSidebar>
			) :
		showSidebar ?
				(
					<>
						<MobileSidebar>
							<ul>
								{SIDEBAR_LINKS.map((link, i) => (
									<li key={`sidebar_link_${i}`} onClick={() => setShowSidebar(false)}>
										<SidebarLinkComponent link={link} guard={guardLink} />
									</li>
								))}
								<MobileSidebarDivider />
								{HEADER_MENU_ITEMS.map((item) => {
									if (item.subMenu)
										return (
											<li key={`sidebar_menu_link_${item.order}`}>
												<SidebarLink onClick={() => setShowGenresSubMenu(true)}>
													<Trans>{item.label}</Trans>
													<SpriteIcon
														id="arrow-forward"
														width="8"
														height="14"
														className="forward-arrow"
													/>
												</SidebarLink>
											</li>
										);
									else if (item.newWindow) {
										return (
											<SidebarExternalLink
												item={item}
												key={`sidebar_menu_link_${item.order}`}
											/>
										);
									} else {
										return (
											<SidebarLinkComponent
												link={{
													label: item.label,
													href: item.href,
													cypressTag: item.cypressTag,
													requireAuth: false,
												}}
												key={`sidebar_menu_link_${item.order}`}
											/>
										);
									}
								})}
								<MobileSidebarDivider />
								{!subscribed && !isSm && (
									<li
										className="header_item subscribe"
										onClick={() => setShowSidebar(false)}
									>
										<Link
											href="/subscriptions"
											prefetch={false}
											data-testid="sidebar-link-subscribe"
										>
											<Button type="primary">{t("AddStreaming")}</Button>
										</Link>
									</li>
								)}
							</ul>
						</MobileSidebar>
						{showGenresSubMenu && (
							<MobileSubMenuWrapper>
								<MobileGenres
									onClose={() => setShowGenresSubMenu(false)}
									setShowSidebar={setShowSidebar}
								/>
							</MobileSubMenuWrapper>
						)}
					</>
				) :
				(
					<></>
				);
};

export default Sidebar;
