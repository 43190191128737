import { cls } from "@lib/css";
import { BoxWrapper } from "./Box.style";

interface Props {
	margin?: string;
	bg?:
		| "default"
		| "primary"
		| "secondary"
		| "outline"
		| "tertiary"
		| "pink"
		| "blue"
		| "purple"
		| "danger"
		| "warn";
	text?:
		| "default"
		| "primary"
		| "secondary"
		| "outline"
		| "tertiary"
		| "pink"
		| "blue"
		| "purple"
		| "danger"
		| "warn";
	width?:
		| "100"
		| "90"
		| "80"
		| "75"
		| "70"
		| "60"
		| "50"
		| "40"
		| "30"
		| "20"
		| "25"
		| "10";
	children?: React.ReactNode[] | React.ReactNode | string;
	justifyContent?:
		| "start"
		| "end"
		| "center"
		| "space-between"
		| "space-around"
		| "space-evenly";
	alignItems?: "stretch" | "center" | "start" | "end";
	direction?: "column" | "row";
	size?:
		| "xxx-small"
		| "xx-small"
		| "x-small"
		| "small"
		| "medium"
		| "large"
		| "x-large"
		| "xx-large"
		| "xxx-large";

	p?:
		| "0"
		| "1"
		| "2"
		| "3"
		| "4"
		| "5"
		| "6"
		| "7"
		| "8"
		| "9"
		| "10"
		| "15"
		| "20";

	gap?:
		| "0"
		| "1"
		| "2"
		| "3"
		| "4"
		| "5"
		| "6"
		| "7"
		| "8"
		| "9"
		| "10"
		| "15"
		| "20"
		| "40";
}

const Box: React.FC<Props> = ({
	bg = "default",
	text = "default",
	width = "100",
	justifyContent,
	alignItems,
	direction,
	size = "large",
	p = "0",
	margin,
	gap = "0",
	children,
}) => {
	return (
		<BoxWrapper
			alignItems={alignItems}
			justifyContent={justifyContent}
			direction={direction}
			margin={margin}
			className={cls(
				`bg-${bg}`,
				`text-${text}`,
				`w-${width}`,
				`size-${size}`,
				`p-${p}`,
				`gap-${gap}`,
			)}
		>
			{children}
		</BoxWrapper>
	);
};

export default Box;
