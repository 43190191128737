import styled from "styled-components";

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;

  form {
    height: fit-content;
  }

  .search-input {
    margin: 0;
    input {
      height: 32px;
      border: 1px solid
        ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
      border-radius: 4px;
      padding-left: 2.2rem;
      width: 100%;
      color: white;

      background: ${(props) => props.theme.colors.neutrals.primary[600]};
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${(props) => props.theme.colors.neutrals.primary.graytext};
      }
      &:focus {
        border: 1px solid ${(props) => props.theme.colors.green[300].accent};
        box-shadow: ${(props) => props.theme.boxShadows.green["1"]};
        outline: 2px solid transparent;
        outline-offset: 2px;
      }
    }
  }
`;

export const SearchIconWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0.7rem;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
`;

export const QueryPageWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  div:last-child {
    margin-left: auto;
  }
`;
