import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { usePlayerActions, usePlayerState } from "@lib/context/player";
import { useSessionContext } from "@lib/context/session";
import logger from "@lib/logger";
import { getCatalogPlaylistTracksRequest, getMyPlaylistTracksRequest } from "@lib/network/playlists";
import {
	getChartTracksRequest,
	getReleaseTracksRequest,

	getTrackQuery,
} from "@lib/network/tracks";
import { Playlist } from "@models/Playlists";
import { Track } from "@models/track";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import React from "react";
import { Tooltip } from "../Tooltip";
import { Control } from "./Play.style";

const DEFAULT_PER_PAGE = 100;

interface Props {
	useTooltip?: boolean;
	tracks?: Track[];
	chartId?: number;
	releaseId?: number;
	variant?: "inline" | "rounded";
	playlist?: Playlist;
	autoMarkAsPlayed?: boolean;
	virtualQueue?: Track[];
	disabled?: boolean;
	isMyPlaylist?: boolean;
}

const Play: React.FC<Props> = ({
	useTooltip,
	tracks,
	chartId,
	releaseId,
	playlist,
	variant = "inline",
	autoMarkAsPlayed = true,
	virtualQueue = [],
	disabled = false,
	isMyPlaylist = false,
}) => {
	const { playTrack, setVirtualQueue } = usePlayerActions();
	const { currentTrack, previouslyPlayedTracks } = usePlayerState();
	const { getAccessToken } = useSessionContext();
	const { t } = useTranslation("translation");
	const accessToken = getAccessToken();

	const hasItemBeenPlayed = (): boolean => {
		return previouslyPlayedTracks.includes(
			tracks?.[0]?.id || releaseId || chartId || playlist?.id || 0,
		);
	};

	const queryClient = useQueryClient();

	const isCurrentItem = (): boolean => {
		if (!currentTrack) return false;

		return [tracks?.[0]?.id, releaseId, chartId, playlist?.id].includes(
			currentTrack?.id,
		);
	};

	const getTrackData = async (track: Track) => {
		try {
			const trackData = await queryClient.fetchQuery<Track>(getTrackQuery({ id: track.id, accessToken }));
			return trackData;
		} catch (err) {
			logger.error(err, "Error fetching Track");
			return;
		}
	};

	const handleOnClick = async () => {
		let newVirtualQueue = [];
		let clickedTrackId = 0;

		if (tracks && tracks.length > 0) {
			// Check if selected track / Virtual Queue has sample_url, if not fetch them before playing
			// We are only checking the first track in the list because we can assume that if one is missing, they all are
			if (!tracks[0].sample_url) {
				const tracksList = virtualQueue.length ? virtualQueue : tracks;
				const data = await Promise.all(tracksList.map((track) => getTrackData(track)));
				if (data?.length > 0 && data[0]) {
					newVirtualQueue = data;
				} else {
					postMessage({
						type: "error",
						message: "Unable to play track",
					});
					return;
				}
			} else {
				newVirtualQueue = virtualQueue.length ? virtualQueue : tracks;
			}
			clickedTrackId = tracks[0].id;
		}

		if (releaseId) {
			const { data } = await getReleaseTracksRequest({
				releaseId: releaseId || 0,
				params: {
					per_page: DEFAULT_PER_PAGE,
				},
				accessToken,
			});

			if (data && data.results && data.results.length > 0) {
				newVirtualQueue = virtualQueue.length ? virtualQueue : data.results;
				clickedTrackId = data.results[0].id;
			}
		}

		if (chartId) {
			const { data } = await getChartTracksRequest({
				params: { per_page: DEFAULT_PER_PAGE },
				accessToken,
				chartId,
			});

			if (data && data.results && data.results.length > 0) {
				newVirtualQueue = virtualQueue.length ? virtualQueue : data.results;
				clickedTrackId = data.results[0].id;
			}
		}

		if (playlist) {
			const getPlaylistTracksRequest = isMyPlaylist ? getMyPlaylistTracksRequest : getCatalogPlaylistTracksRequest;
			const { data } = await getPlaylistTracksRequest({
				id: playlist.id,
				params: { page: 1, per_page: playlist.track_count || DEFAULT_PER_PAGE },
				accessToken,
			});

			if (data && data.results && data.results.length > 0) {
				const tracks = data.results.map((track: any) => track.track);
				newVirtualQueue = virtualQueue.length ? virtualQueue : tracks;
				clickedTrackId = tracks[0].id;
			}
		}

		const virtualQueueTracks = setVirtualQueue(newVirtualQueue.filter(Boolean));
		const currentVirtualQueueIndex = virtualQueueTracks.findIndex(
			(trk: Track) => trk.id === clickedTrackId,
		);

		playTrack(virtualQueueTracks[currentVirtualQueueIndex]);
	};

	const ControlButton = () => {
		const shouldShowReplayIcon =
			autoMarkAsPlayed && hasItemBeenPlayed() && !isCurrentItem();

		let controlSprite = (
			<SpriteIcon
				id="play-button"
				width="32"
				height="32"
				title="Play"
				desc="Play button"
			/>
		);

		if (variant === "inline") {
			controlSprite = (
				<SpriteIcon
					id="track-play-inline"
					width="16"
					height="16"
					title={t("Actions.Play")}
					desc="Play button"
					data-testid="inline-button-play_track"
				/>
			);
		}

		if (shouldShowReplayIcon) {
			controlSprite = (
				<SpriteIcon
					id="replay"
					width="16"
					height="16"
					title="Replay"
					desc="Replay button"
				/>
			);
		}

		return (
			<Control onClick={handleOnClick} disabled={disabled} data-testid="play-button">
				{controlSprite}
			</Control>
		);
	};

	if (useTooltip && !disabled) {
		return (
			<Tooltip text="Play">
				<ControlButton />
			</Tooltip>
		);
	}

	return <ControlButton />;
};

export default Play;
