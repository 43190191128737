import { ItemLoader } from "@components/loaders";
import { Wrapper } from "./InfiniteListLoader.style";

export const LOADING_TEXT = "Turning the tables on that request";

export const REACHED_END_TEXT = "Looks like you've reached the end";

interface Props {
	isActive?: boolean;
	hasReachedEnd?: boolean;
	loadingText?: string;
	reachedEndText?: string;
}

const InfiniteListLoader: React.FC<Props> = ({
	isActive = false,
	hasReachedEnd = false,
	loadingText = "",
	reachedEndText = "",
}) => {
	if (hasReachedEnd) {
		return (
			<Wrapper>
				<span>{reachedEndText || REACHED_END_TEXT}</span>
			</Wrapper>
		);
	}

	if (isActive) {
		return (
			<Wrapper>
				<ItemLoader />
				<span>{loadingText || LOADING_TEXT}</span>
			</Wrapper>
		);
	}

	return null;
};

export default InfiniteListLoader;
