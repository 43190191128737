import { API_BASES } from "@lib/constants";
import { LocationParams } from "@models/facets";
import { getRequest } from "./request";
const urlPayload = (params: any) => new URLSearchParams(params);

interface Params {
	q?: string;
	count?: string;
	page?: number;
	from_bpm?: string;
	to_bpm?: string;
	genre_id?: string;
	from_release_date?: string;
	to_release_date?: string;
	from_publish_date?: string;
	to_publish_date?: string;
	key_name?: string;
	is_approved?: boolean;
	preorder?: boolean | null;
}

interface QueryParams {
	params: Params;
	accessToken?: string;
	location?: LocationParams;
}

const filterQueryParams = (searchParams: QueryParams): QueryParams => {
	const params = searchParams.params;
	return {
		params: Object.fromEntries(
			Object.entries(params).filter(([, v]) => v !== undefined),
		),
		accessToken: searchParams.accessToken,
		location: searchParams.location || {},
	} as QueryParams;
};

export const searchAllRequest = async (
	{ params, accessToken, location }: QueryParams,
	withErrorHandling?: boolean,
) => {
	const url = `all/?${urlPayload(params)}`;
	return getRequest(
		{ url, apiBase: API_BASES.search, accessToken, location },
		withErrorHandling,
	);
};

export const searchAllQuery = (searchParams: QueryParams) => {
	const filteredParams = filterQueryParams(searchParams);

	return {
		queryKey: ["search-all", filteredParams.params, filteredParams.location?.countryCode || null],
		queryFn: async () => {
			const { data } = await searchAllRequest(filteredParams, false);
			return data;
		},
	};
};

export const searchArtistsRequest = async (
	{ params, accessToken, location }: QueryParams,
	withErrorHandling?: boolean,
) => {
	const url = `artists/?${urlPayload(params)}`;
	return getRequest(
		{ url, apiBase: API_BASES.search, accessToken, location },
		withErrorHandling,
	);
};

export const searchArtistsQuery = (searchParams: QueryParams) => {
	const params = { ...searchParams.params, available: true, enabled: true };
	const filteredParams = filterQueryParams({
		...searchParams,
		params,
	});

	return {
		queryKey: ["search-artists", filteredParams.params, filteredParams.location?.countryCode || null],
		queryFn: async () => {
			const { data } = await searchArtistsRequest(filteredParams, false);
			return data;
		},
	};
};

export const searchReleasesRequest = async (
	{ params, accessToken, location }: QueryParams,
	withErrorHandling?: boolean,
) => {
	const url = `releases/?${urlPayload(params)}`;
	return getRequest(
		{ url, apiBase: API_BASES.search, accessToken, location },
		withErrorHandling,
	);
};

export const searchReleasesQuery = (searchParams: QueryParams) => {
	const filteredParams = filterQueryParams(searchParams);

	return {
		queryKey: ["search-releases", filteredParams.params, filteredParams.location?.countryCode || null],
		queryFn: async () => {
			const { data } = await searchReleasesRequest(filteredParams, false);
			return data;
		},
	};
};

export const searchTracksRequest = async (
	{ params, accessToken, location }: QueryParams,
	withErrorHandling?: boolean,
) => {
	const url = `tracks/?${urlPayload(params)}`;
	return getRequest(
		{ url, apiBase: API_BASES.search, accessToken, location },
		withErrorHandling,
	);
};

export const searchTracksQuery = (searchParams: QueryParams) => {
	const filteredParams = filterQueryParams(searchParams);

	return {
		queryKey: ["search-tracks", filteredParams.params, filteredParams.location?.countryCode || null],
		queryFn: async () => {
			if (!filteredParams.params.q) return [];
			const { data } = await searchTracksRequest(filteredParams, false);
			return data;
		},
	};
};

export const searchLabelsRequest = async (
	{ params, accessToken, location }: QueryParams,
	withErrorHandling?: boolean,
) => {
	const url = `labels/?${urlPayload(params)}`;
	return getRequest(
		{ url, apiBase: API_BASES.search, accessToken, location },
		withErrorHandling,
	);
};

export const searchLabelsQuery = (searchParams: QueryParams) => {
	const filteredParams = filterQueryParams(searchParams);
	return {
		queryKey: ["search-labels", filteredParams.params, filteredParams.location?.countryCode || null],
		queryFn: async () => {
			const { data } = await searchLabelsRequest(filteredParams, false);
			return data;
		},
	};
};

export const searchChartsRequest = async (
	{ params, accessToken, location }: QueryParams,
	withErrorHandling?: boolean,
) => {
	const url = `charts/?${urlPayload(params)}`;
	return getRequest(
		{ url, apiBase: API_BASES.search, accessToken, location },
		withErrorHandling,
	);
};

export const searchChartsQuery = (searchParams: QueryParams) => {
	const filteredParams = filterQueryParams(searchParams);
	return {
		queryKey: ["search-charts", filteredParams.params, filteredParams.location?.countryCode || null],
		queryFn: async () => {
			const { data } = await searchChartsRequest(filteredParams, false);
			return data;
		},
	};
};
