import styled from "styled-components";

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  form {
    @media ${(props) => props.theme.device.lg} {
      max-width: 450px;
    }
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 8px;

  color: ${(props) => props.theme.colors.green[300].accent};
`;
