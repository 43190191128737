/* eslint-disable no-case-declarations */
import {
	ADD_TRACK,
	ADD_TRACKS,
	ActionTypes,
	CLEAR_QUEUE,
	CLEAR_VIRTUAL_QUEUE,
	PLAY_TRACK,
	PlayerState,
	SELECT_TRACK,
	SET_PLAY,
	SET_PLAYED_HISTORY,
	SET_QUEUE,
	SET_VIRTUAL_QUEUE,
} from "./types";
import { limitQueueSize, makePlayerId } from "./util";

export const playerReducer = (
	state: PlayerState,
	action: ActionTypes,
): PlayerState => {
	switch (action.type) {
		case CLEAR_QUEUE:
			return {
				...state,
				queue: [],
				shouldPlay: false,
				currentTrack: undefined,
			};
		case SET_QUEUE:
			return {
				...state,
				queue: [...action.payload.tracks],
				currentTrack: state.currentTrack ?
					state.currentTrack :
					action.payload.tracks[0],
				shouldPlay: state.currentTrack ? state.shouldPlay : false,
			};
		case CLEAR_VIRTUAL_QUEUE:
			return {
				...state,
				virtualQueue: [],
			};
		case SET_VIRTUAL_QUEUE:
			return {
				...state,
				virtualQueue: [...action.payload.tracks],
			};
		case SELECT_TRACK:
			return {
				...state,
				currentTrack: action.payload.track,
			};
		case ADD_TRACK:
			return {
				...state,
				queue: limitQueueSize([...state.queue, action.payload.track]),
				currentTrack: state.currentTrack ?
					state.currentTrack :
					action.payload.track,
				shouldPlay: state.currentTrack ? state.shouldPlay : false,
			};
		case ADD_TRACKS:
			return {
				...state,
				queue: limitQueueSize([...state.queue, ...action.payload.tracks]),
				currentTrack: state.currentTrack ?
					state.currentTrack :
					action.payload.tracks[0],
				shouldPlay: state.currentTrack ? state.shouldPlay : false,
			};
		case PLAY_TRACK:
			const trk = action.payload.track;

			if (!trk) return state;

			let queueToNavigate = state.queue;

			if (state.virtualQueue.length) {
				queueToNavigate = state.virtualQueue;
			}

			const track = queueToNavigate.find((t) =>
				trk.unique_player_id ? t.unique_player_id === trk.unique_player_id : t.id === trk.id,
			);

			let newQueue = [];
			const t = makePlayerId(trk);

			if (state.virtualQueue.length) {
				newQueue = [...state.queue];
				const trackAlreadyInQueue = newQueue.find((t) => t.unique_player_id === trk.unique_player_id);
				if (!trackAlreadyInQueue) { newQueue = [...newQueue, t]; }
			} else if (track) {
				newQueue = [...state.queue];
			} else {
				newQueue = [...state.queue, t];
			}

			return {
				...state,
				queue: limitQueueSize(newQueue),
				currentTrack: track ? track : t,
				shouldPlay: true,
			};
		case SET_PLAY:
			return {
				...state,
				shouldPlay: action.payload.shouldPlay,
			};

		case SET_PLAYED_HISTORY:
			return {
				...state,
				previouslyPlayedTracks: action.payload.previouslyPlayedTracks,
			};

		default:
			return state;
	}
};
