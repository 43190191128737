import styled from "styled-components";

export const CartItem = styled.div`
  position: relative;
  z-index: 402;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.0025em;
  color: ${(props) => props.theme.colors.neutrals.primary.white};

  button {
    display: flex;
    flex-direction: row;
    gap: 4px;
    cursor: pointer;
    padding: 8px 8px;
    width: 100%;
    background: ${(props) => props.theme.colors.neutrals.primary[900]};

    &:hover {
      color: ${(props) => props.theme.colors.green[300].accent};
      ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
    }

    span.name {
      display: flex;
      flex-direction: row;
      width: 100%;

      span.title {
        overflow: hidden;
        max-width: 265px;
        @media ${(props) => props.theme.device.md} {
          max-width: 165px;
        }
      }

      svg {
        margin-top: 2px;
        margin-right: 4px;
        min-width: 16px;
      }
    }
  }
`;

export const Content = styled.div`
  max-height: 240px;
  overflow: auto;
  position: relative;
  margin: 0 -16px 0 0;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  border-bottom: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  padding: 0 16px 16px;
  margin-left: -16px;
  margin-right: -16px;
`;

interface WrapperProps {
	left: number;
	top: number;
}

export const Selector = styled.div<WrapperProps>`
  position: fixed;
  width: 360px;
  z-index: 401;
  margin-top: 15px;
  left: calc((100% - 360px) / 2);
  top: calc(100vh / 4);

  background: ${(props) => props.theme.colors.neutrals.primary[900]};
  border: 1px solid
    ${(props) => props.theme.colors.neutrals.secondary.gray.stroke};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 16px;

  @media ${(props) => props.theme.device.md} {
    left: ${(props) =>
		props.left < 260 ? "0px" : `calc(${props.left}px - 260px)`};
    top: ${(props) => props.top}px;
    width: 260px;
    min-width: 260px;
  }
`;

export const Overlay = styled.div`
  background: transparent:
  cursor: default;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const Wrapper = styled.div`
  align-items: start;
  justify-content: center;
  overflow: hidden;
  padding-top: 180px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  opacity: 1;
  z-index: 410;
`;
