import { useEffect, useState } from "react";

/**
 * This is a workaround for the react-beautiful-dnd library.
 * Once it's updated for better compatibility with React 18 it shouldn't be necessary anymore.
 */
export const useStrictDroppable = (loading: boolean) => {
	const [enabled, setEnabled] = useState(false);

	useEffect(() => {
		let animation: any;

		if (!loading) {
			animation = requestAnimationFrame(() => setEnabled(true));
		}

		return () => {
			cancelAnimationFrame(animation);
			setEnabled(false);
		};
	}, [loading]);

	return [enabled];
};
