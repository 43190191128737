import Button, { ButtonType } from "@components/Button/Button";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { LayoutContext } from "@lib/context/layout/LayoutContext";
import { useSessionContext } from "@lib/context/session";
import { Track } from "@models/track";
import { useTranslation } from "next-i18next";
import { useContext } from "react";
import { Tooltip } from "../Tooltip";
import { Control } from "./AddToPlaylist.style";

interface Props {
	useTooltip?: boolean;
	disabled?: boolean;
	tracks?: Track[];
	releaseId?: number;
	chartId?: number;
	children?: JSX.Element | string;
	buttonType?: ButtonType;
	[extra: string]: any;
}

/**
 *
 * @param useTooltip - [OPTIONAL] If true, a tooltip will be displayed on hover
 * @param disabled - [OPTIONAL] If true, button & tooltip will be disabled
 * @param tracks - [OPTIONAL] An array of tracks to add to the playlist
 * @param releaseId - [OPTIONAL] The id of the release to add to the playlist
 * @param chartId - [OPTIONAL] The id of the chart to add to the playlist
 * @param children - [OPTIONAL] If a child is passed, it will be rendered instead of the default icon
 * @param buttonType - [OPTIONAL] If a buttonType is passed, a Beatport button will be rendered instead of a default button
 * @param extra - [OPTIONAL] This is a catch-all for any other props that might be passed to the component
 */
const AddToPlaylist: React.FC<Props> = ({
	useTooltip = true,
	disabled = false,
	tracks,
	releaseId,
	chartId,
	children,
	buttonType,
	...extra
}) => {
	const { authenticateLink, showAddToPlaylist } = useContext(LayoutContext);
	const { getIsSessionValid } = useSessionContext();
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });
	const { t } = useTranslation("translation");

	const getIdAndType = () => {
		if (tracks && tracks.length > 0) {
			return { type: "track", id: 0, tracks: tracks };
		}
		if (releaseId) {
			return { type: "release", id: releaseId };
		}
		if (chartId) {
			return { type: "chart", id: chartId };
		}
	};

	const handleOnClick = () => {
		if (!isLoggedInSessionValid) {
			authenticateLink("", () => {
				const resource = getIdAndType();
				if (resource && resource.type) {
					showAddToPlaylist(resource);
				}
			});
		} else {
			const resource = getIdAndType();
			if (resource && resource.type) {
				showAddToPlaylist(resource);
			}
		}
	};

	const control = () => {
		const ControlModule = buttonType ? Button : Control;
		const controlChildren =
			children ?
				children :
					<SpriteIcon id="playlist-add" width="14" height="14" />;
		const controlProps: any = {
			onClick: handleOnClick,
			...extra,
		};

		if (buttonType) {
			controlProps.type = buttonType;
		}

		return (
			<ControlModule {...controlProps} disabled={disabled} data-testid="add-to-playlist-button">
				{controlChildren}
			</ControlModule>
		);
	};

	return useTooltip && !disabled ?
			(
				<Tooltip text={t("Actions.AddToPlaylist")}>{control()}</Tooltip>
			) :
			(
				control()
			);
};

export default AddToPlaylist;
