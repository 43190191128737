import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import { cls } from "@lib/css";
import toast, { useToaster } from "react-hot-toast/headless";
import { Item, Wrapper } from "./Notifications.style";
import { theme } from "@styles/theme";

export interface NotificationType {
	type: "success" | "error" | "neutral";
	message: string;
	duration?: number;
}

export const DEFAULT_TOAST_DURATION = 2000;

export const postMessage = (notification: NotificationType) => {
	const duration = notification.duration ?
		notification.duration :
		DEFAULT_TOAST_DURATION;
	switch (notification.type) {
		case "success":
			toast.success(notification.message, {
				duration: duration,
			});
			break;
		case "error":
			toast.error(notification.message, { duration: duration });
			break;
		case "neutral":
			toast.custom(notification.message, { duration, className: notification.type });
			break;
	}
};

const Notifications = () => {
	const { toasts, handlers } = useToaster();
	const { startPause, endPause } = handlers;

	return (
		<Wrapper onMouseEnter={startPause} onMouseLeave={endPause}>
			{toasts.map((item) => {
				return (
					<Item
						key={item.id}
						style={{
							transition: "all 0.5s ease-out",
							opacity: item.visible ? 1 : 0,
						}}
						{...item.ariaProps}
					>
						<span className={cls("icon", item.type, item.className)}>
							{(item.type === "success" || item.className === "neutral") && (
								<SpriteIcon id="check" width="18" height="19" fill={theme.colors.black} />
							)}
							{item.type === "error" && <SpriteIcon id="stop" />}
						</span>
						<span className="msg">{item.message?.toString()}</span>
						<span
							className="close"
							onClick={() => {
								toast.dismiss(item.id);
							}}
						>
							<SpriteIcon id="close-x" width="19" height="18" />
						</span>
					</Item>
				);
			})}
		</Wrapper>
	);
};

export default Notifications;
