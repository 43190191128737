import { Track } from "@models/track";

export type CollectionState = {
	playlist_queue: Track[];
	download_queue: Track[];
};

export interface CollectionActions {
	clearPlaylistQueue(): void;
	clearDownloadQueue(): void;
	setPlaylistQueue(tracks: Track[]): void;
	setDownloadQueue(tracks: Track[]): void;
	addTracksToPlaylist(tracks: Track[]): void;
	addTrackToDownload(track: Track, accessToken: string): void;
}

export const CLEAR_PLAYLIST_QUEUE = "@COLLECTIONS/CLEAR_PLAYLIST_QUEUE";
export const CLEAR_DOWNLOAD_QUEUE = "@COLLECTIONS/CLEAR_DOWNLOAD_QUEUE";
export const SET_PLAYLIST_QUEUE = "@COLLECTIONS/SET_PLAYLIST_QUEUE";
export const SET_DOWNLOAD_QUEUE = "@COLLECTIONS/SET_DOWNLOAD_QUEUE";
export const ADD_TRACKS_TO_PLAYLIST = "@COLLECTIONS/ADD_TRACK_TO_PLAYLIST";
export const ADD_TRACKS_TO_DOWNLOAD = "@COLLECTIONS/ADD_TRACK_TO_DOWNLOAD";

export type ClearPlaylistQueueAction = {
	type: typeof CLEAR_PLAYLIST_QUEUE;
};
export type ClearDownloadQueueAction = {
	type: typeof CLEAR_DOWNLOAD_QUEUE;
};

export type SetPlaylistQueueAction = {
	type: typeof SET_PLAYLIST_QUEUE;
	payload: {
		tracks: Track[];
	};
};
export type SetDownloadQueueAction = {
	type: typeof SET_DOWNLOAD_QUEUE;
	payload: {
		tracks: Track[];
	};
};

export type AddTracksToPlaylistAction = {
	type: typeof ADD_TRACKS_TO_PLAYLIST;
	payload: {
		tracks: Track[];
	};
};

export type AddTracksToDownloadAction = {
	type: typeof ADD_TRACKS_TO_DOWNLOAD;
	payload: {
		track: Track;
	};
};

export type ActionTypes =
	| ClearPlaylistQueueAction
	| ClearDownloadQueueAction
	| SetPlaylistQueueAction
	| SetDownloadQueueAction
	| AddTracksToPlaylistAction
	| AddTracksToDownloadAction;
