import { ALLOWED_VIEW_TYPES } from "../components/headings/HeadingWithBreadcrumb";

export const LOGGED_IN_SESSION_STORAGE_KEY = "BP-user-logged-in";

export interface BaseEvent {
	event?: string;
	id?: string;
}

export interface LoginEvent extends BaseEvent {
	personId: number;
	isLoggedIn: boolean;
	subscription: string | null;
}
export interface ProductEvent extends BaseEvent {
	name: string | null;
	position: number | null;
	label: string | null;
	list: string | null;
	artists: string | null;
	remixers: string | null;
	genres: string | null;
	subGenres: string | null;
	preOrder: boolean | null;
	imageUrl?: string | null;
	tracks?: TrackEvent[] | null;
}

export interface FollowEvent extends BaseEvent {
	name: string;
	type: ALLOWED_VIEW_TYPES;
}

export interface ProductListingEvent extends ProductEvent {}

export interface ProductArtistEvent
	extends Pick<ProductEvent, "event" | "id" | "name" | "list" | "imageUrl"> {}

export interface ProductLabelEvent
	extends Pick<ProductEvent, "event" | "id" | "name" | "list" | "imageUrl"> {}

export interface ProductDetailEvent extends Pick<ProductEvent, "id" | "name" | "list" | "artists" | "remixers" | "genres" | "subGenres" | "preOrder" | "imageUrl" | "tracks"> {}

export interface ProductSubscribeEvent extends Pick<BaseEvent, "event"> {
	ecommerce: {
		currencyCode: string;
		add: {
			actionField: {
				list: string;
			};
			products: {
				id: string;
				name: string;
				price: number;
				brand: string;
				category: string;
				variant: string;
				quantity: number;
			};
		};
	};
}

export interface ProductImpressionEvent extends BaseEvent {
	ecommerce: {
		currencyCode: string;
		impressions: Array<
			| (ProductEvent & {
				type: string;
				category: string;
				variant: string;
				price: number | null;
			})
			| null
		>;
	};
}

export interface ProductClickEvent extends BaseEvent {
	ecommerce: {
		currencyCode: string;
		click: {
			actionField: {
				list: string;
			};
			products: Array<
				ProductEvent & {
					type: string;
					category: string;
					variant: string;
					price: number | null;
				}
			>;
		};
	};
}

export interface ProductPlayEvent extends Omit<ProductEvent, "position" | "list"> {}

export interface ProductQueueEvent extends BaseEvent {
	products: Array<Omit<ProductEvent, "event" | "id" | "list" | "position">>;
}

export interface ProductAddToPlaylistEvent extends BaseEvent {
	products: Array<Omit<ProductEvent, "event" | "id" | "list" | "position">>;
}

export interface CouponSuccessEvent extends BaseEvent {
	list: string;
	coupon: string;
}

export interface ProductDetailViewEvent extends Pick<BaseEvent, "event"> {
	ecommerce: {
		currencyCode: string;
		detail: {
			products: Array<ProductDetailEvent & {
				type: string;
				category: string;
				variant: string;
				price?: number;
				creative: string;
			}>;
		};
	};
}

export interface CartEvent extends BaseEvent {
	ecommerce: {
		currency: string;
		add: {
			actionField: {
				list: string;
			};
			products: {
				type: string;
				id: string;
				name: string;
				position: number;
				label: string;
				category: string;
				variant: string;
				price: number;
				artist: string;
				genre: string;
				quantity: number;
				list: string;
			}[];
		};
	};
}

export interface CheckoutEvent extends Pick<ProductEvent, "event"> {
	ecommerce: {
		currency: string;
		checkout: {
			actionField: {
				step: number;
			};
			products: {
				id: string;
				name: string;
				price: number;
				brand: string;
				category: string;
				quantity: number;
				variant: string;
				type?: string;
				position?: number;
				list?: string;
			}[];
		};
	};
}

export interface PurchaseEvent extends Pick<ProductEvent, "event"> {
	ecommerce: {
		currency: string;
		purchase: {
			actionField: {
				id: string;
				revenue?: number | null;
				tax?: number | null;
				coupon?: string | null;
			};
			products: {
				id: string;
				name: string;
				artists?: string;
				remixers?: string;
				price: number;
				label?: string;
				category: string;
				variant: string;
				preOrder?: boolean;
				genres?: string;
				subGenres?: string;
			}[];
		};
	};
}

export interface SocialShareEvent extends BaseEvent {
	shareDestination: "facebook" | "twitter" | "clipboard";
	type: string;
}
